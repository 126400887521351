.section {
  position: relative;
  width: 100%;

  overflow: hidden;

  & .container {
    display: flex;
    flex-direction: column;
    gap: var(--section-gap);
  }

  &_padding {
    &_top {
      padding-top: var(--section-padding);
    }

    &_bottom {
      padding-bottom: var(--section-padding);
    }
    
    &_bottom_small {
      padding-bottom: var(--section-gap);
    }
  }
  
  &_background {
    background-color: var(--section-background-color);
  }
  
  &_text-light {
    --section-text-color-primary: var(--text-light-primary);
    --section-text-color-secondary: var(--text-light-secondary);
    --section-text-color-tertiary: var(--text-light-tertiary);
  }

  &_line {
    --section-line-width: auto;
    
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: var(--container-secondary-offset);
      left: var(--container-secondary-offset);

      width: var(--section-line-width);
      height: 1px;

      background-color: var(--stroke-dark-secondary);

      @include mediaBigDesktop {
        height: big(1);
      }
    }
  }
  
  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: var(--grid-column-gap);
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaLaptop {
      gap: rem(25);
    }

    @include mediaTablet {
      gap: rem(10);
    }
  }

  &__title {
    color: var(--section-text-color-primary);
  }

  &__name {
    color: var(--section-text-color-tertiary);
    font-weight: 500;
  }

  &__navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    flex-shrink: 0;
    gap: rem(30);

    @include mediaBigDesktop {
      gap: big(30);
    }

    @include mediaMobile {
      display: none;
    }
  }

  &__swiper-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaTablet {
      display: none;
    }

    & .button {
      @include mediaLaptop {
        @include mediumButtonIcon {}
      }
    }
  }

  &__mobile {
    width: 100%;
    display: none;

    @include mediaMobile {
      display: flex;
    }

    & .button {
      width: 100%;
    }
  }
}
