.to-top {
  position: fixed;
  right: rem(20);
  bottom: rem(-100);
  
  transition: bottom var(--animation-timing-medium) var(--cubic-bezier);
  z-index: 999;

  @include mediaMobile {
    width: rem(31);
    height: rem(31);
  
    & .button {
      &__icon {
        width: rem(16);
        height: rem(16);
      }
    }
  }
  
  &_visible {
    bottom: rem(40);
  }
}