.js-nav-overflow-detect {
  opacity: 0 !important;
  overflow-x: auto;

  & .nav__link,
  & .header__button {
    &::before {
      content: none;
    }
  }
  
  & .header-cart__count {
    display: none !important;
  }

  & .header-call__phone {
    white-space: nowrap;
  }
}