blockquote {
  margin: 0;

  padding: rem(30);
  display: flex;
  flex-direction: column;
  gap: rem(15);

  color: var(--text-dark-secondary);
  font: var(--font-body-M);

  background-color: var(--bg-light);
  border-radius: var(--radius-secondary);

  @include mediaBigDesktop {
    padding: big(30);
    gap: big(15);
  }

  @include mediaLaptop {
    padding: rem(20);
  }

  &::before {
    content: "";

    width: rem(30);
    height: rem(30);
    display: flex;

    background-color: var(--accent-light-primary);

    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cpath d='M10.5 9L12 6H9C5.685 6 3 10.185 3 13.5V24H13.5V13.5H7.5C7.5 9 10.5 9 10.5 9ZM21 13.5C21 9 24 9 24 9L25.5 6H22.5C19.185 6 16.5 10.185 16.5 13.5V24H27V13.5H21Z' fill='%2391B6B2'/%3E%3C/svg%3E");
    mask-size: contain;
    mask-repeat: no-repeat;

    @include mediaBigDesktop {
      width: big(30);
      height: big(30);
    }
  }
}
