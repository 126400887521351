@mixin mediumRadio {
  & .radio {
    &__item {
      width: rem(24);
      height: rem(24);

      @include mediaBigDesktop {
        width: big(24);
        height: big(24);
      }

      &::before {
        width: rem(8);
        height: rem(8);

        @include mediaBigDesktop {
          width: big(8);
          height: big(8);
        }
      }
    }
  }

  @content;
}

@mixin smallRadio {
  & .radio {
    &__item {
      width: rem(20);
      height: rem(20);

      @include mediaBigDesktop {
        width: big(20);
        height: big(20);
      }

      &::before {
        width: rem(8);
        height: rem(8);

        @include mediaBigDesktop {
          width: big(8);
          height: big(8);
        }
      }
    }
  }

  @content;
}
