.checked-list {
  margin: 0;

  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(15);

  list-style: none;

  @include mediaBigDesktop {
    gap: big(15);
  }

  @include mediaTablet {
    gap: rem(10);
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(15);

    color: var(--text-dark-secondary);
    font: var(--font-body-M);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaTablet {
      gap: rem(12);
    }

    &::before {
      content: "";

      width: rem(24);
      height: rem(32);
      display: flex;
      flex-shrink: 0;

      background-color: var(--accent-light-primary);
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='32' viewBox='0 0 24 32' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 23C13.1819 23 14.3522 22.7672 15.4442 22.3149C16.5361 21.8626 17.5282 21.1997 18.364 20.364C19.1997 19.5282 19.8626 18.5361 20.3149 17.4442C20.7672 16.3522 21 15.1819 21 14C21 12.8181 20.7672 11.6478 20.3149 10.5558C19.8626 9.46392 19.1997 8.47177 18.364 7.63604C17.5282 6.80031 16.5361 6.13738 15.4442 5.68508C14.3522 5.23279 13.1819 5 12 5C9.61305 5 7.32387 5.94821 5.63604 7.63604C3.94821 9.32387 3 11.6131 3 14C3 16.3869 3.94821 18.6761 5.63604 20.364C7.32387 22.0518 9.61305 23 12 23ZM11.768 17.64L16.768 11.64L15.232 10.36L10.932 15.519L8.707 13.293L7.293 14.707L10.293 17.707L11.067 18.481L11.768 17.64Z' fill='%2391B6B2'/%3E%3C/svg%3E");;
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat;

      @include mediaBigDesktop {
        width: big(24);
        height: big(32);
      }

      @include mediaLaptop {
        height: rem(24);

        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21.5C13.1819 21.5 14.3522 21.2672 15.4442 20.8149C16.5361 20.3626 17.5282 19.6997 18.364 18.864C19.1997 18.0282 19.8626 17.0361 20.3149 15.9442C20.7672 14.8522 21 13.6819 21 12.5C21 11.3181 20.7672 10.1478 20.3149 9.05585C19.8626 7.96392 19.1997 6.97177 18.364 6.13604C17.5282 5.30031 16.5361 4.63738 15.4442 4.18508C14.3522 3.73279 13.1819 3.5 12 3.5C9.61305 3.5 7.32387 4.44821 5.63604 6.13604C3.94821 7.82387 3 10.1131 3 12.5C3 14.8869 3.94821 17.1761 5.63604 18.864C7.32387 20.5518 9.61305 21.5 12 21.5ZM11.768 16.14L16.768 10.14L15.232 8.86L10.932 14.019L8.707 11.793L7.293 13.207L10.293 16.207L11.067 16.981L11.768 16.14Z' fill='%2391B6B2'/%3E%3C/svg%3E");
      }

      @include mediaTablet {
        width: rem(20);
        height: rem(27);

        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='27' viewBox='0 0 20 27' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20C10.9849 20 11.9602 19.806 12.8701 19.4291C13.7801 19.0522 14.6069 18.4997 15.3033 17.8033C15.9997 17.1069 16.5522 16.2801 16.9291 15.3701C17.306 14.4602 17.5 13.4849 17.5 12.5C17.5 11.5151 17.306 10.5398 16.9291 9.62987C16.5522 8.71993 15.9997 7.89314 15.3033 7.1967C14.6069 6.50026 13.7801 5.94781 12.8701 5.5709C11.9602 5.19399 10.9849 5 10 5C8.01088 5 6.10322 5.79018 4.6967 7.1967C3.29018 8.60322 2.5 10.5109 2.5 12.5C2.5 14.4891 3.29018 16.3968 4.6967 17.8033C6.10322 19.2098 8.01088 20 10 20ZM9.80667 15.5333L13.9733 10.5333L12.6933 9.46667L9.11 13.7658L7.25583 11.9108L6.0775 13.0892L8.5775 15.5892L9.2225 16.2342L9.80667 15.5333Z' fill='%2391B6B2'/%3E%3C/svg%3E");
      }
    }
  }
}
