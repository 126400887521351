@mixin mediumNumberInput {
  padding: 0 rem(15);
  height: rem(44);
  gap: rem(10);

  @include mediaBigDesktop {
    padding: 0 big(15);
    height: big(44);
    gap: big(10);
  }

  & .number-input {
    &__button {
      &::before {
        @include pseudo(5) {}
      }
    }

    &__input {
      height: rem(21);

      font-size: rem(14);
      letter-spacing: rem(1.4);

      @include mediaBigDesktop {
        height: big(21);

        font-size: big(14);
        letter-spacing: big(1.4);
      }
    }

    &__icon {
      width: rem(20);
      height: rem(20);
    }
  }

  @content;
}
