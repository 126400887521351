.button-product {
  padding: rem(13);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  
  text-decoration: none;
  background-color: var(--accent-light-primary);
  border: solid 1px var(--accent-light-primary);
  border-radius: var(--radius-infinity);
  backdrop-filter: blur(rem(5));

  transition: padding var(--animation-timing) var(--cubic-bezier),
    background-color var(--animation-timing) var(--cubic-bezier),
    gap var(--animation-timing) var(--cubic-bezier),
    border-color var(--animation-timing) var(--cubic-bezier);

  user-select: none;
  cursor: pointer;

  @include mediaBigDesktop {
    padding: big(14);

    backdrop-filter: blur(big(5));

    border-width: big(1);
  }

  @include mediaLaptop {
    padding: rem(10.5) rem(12);
  }

  &:disabled {
    background-color: var(--bg-disable);
    border-color: var(--bg-disable);

    pointer-events: none;

    & .button-product {
      &__icon {
        fill: var(--text-disable);
      }

      &__text {
        color: var(--text-disable);
      }
    }
  }

  @include hover {
    padding-left: rem(24);
    padding-right: rem(24);

    background-color: var(--accent-light-secondary);
    border-color: var(--accent-light-secondary);

    @include mediaBigDesktop {
      padding-left: big(24);
      padding-right: big(24);
    }

    @include mediaLaptop {
      padding-left: rem(19);
      padding-right: rem(19);
    }

    & .button-product {
      &__text {
        width: rem(84);

        @include mediaBigDesktop {
          width: big(84);
        }
      }

      &__icon {
        width: 0;

        opacity: 0;
      }
    }
  }
  
  &_link {
    @include hover {
      & .button-product {
        &__text {
          width: rem(70);

          @include mediaBigDesktop {
            width: big(70);
          }
        }
      }
    }
  }

  &_quick {
    background-color: var(--bg-white);

    border-color: var(--accent-dark-primary);

    @include hover {
      background-color: var(--bg-white);
      border-color: var(--accent-dark-primary);

      & .button-product {
        &__text {
          width: rem(131);

          @include mediaBigDesktop {
            width: big(131);
          }
        }
      }
    }

    &:disabled {
      background-color: var(--bg-white);
      border-color: var(--text-disable);
  
      pointer-events: none;
  
      & .button-product {
        &__icon {
          fill: var(--text-disable);
        }
  
        &__text {
          color: var(--text-disable);
        }
      }
    }

    & .button-product {
      &__icon {
        fill: var(--accent-dark-primary);
      }

      &__text {
        color: var(--accent-dark-primary);
      }
    }
  }

  &__icon {
    width: rem(22);
    height: rem(22);

    order: 2;

    fill: var(--text-light-primary);

    transition: width var(--animation-timing) var(--cubic-bezier),
      opacity var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(22);
      height: big(22);
    }

    @include mediaLaptop {
      width: rem(18);
      height: rem(18);
    }
  }

  &__text {
    width: 0;
    order: 1;

    color: var(--bg-light);
    font-size: rem(14);
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: rem(0.7);
    text-transform: uppercase;
    white-space: nowrap;
    text-align: end;

    overflow: hidden;

    transition: width var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      font-size: big(14);
      letter-spacing: big(0.7);
    }
  }
}
