.rating {
  position: relative;

  width: rem(94);
  height: rem(18);

  overflow: hidden;

  @include mediaBigDesktop {
    width: big(94);
    height: big(18);
  }

  &__enable,
  &__disable {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;

    user-select: none;
  }

  &__enable {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: var(--rating-percent);
    max-width: 100%;

    fill: var(--other-stars);
    object-fit: fill;

    transition: width var(--animation-timing) var(--cubic-bezier);
  }

  &__disable {
    fill: var(--elements-gray-secondary);
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    & .text {
      color: var(--text-dark-tertiary);
    }

    &_review {
      & .rating {
        cursor: pointer;
      }
    }

    & .button {
      color: var(--accent-dark-primary);
      
      pointer-events: none;
    }
  }
}
