@mixin mediumCheckbox {
  & .checkbox {
    &__item {
      width: rem(24);
      height: rem(24);

      @include mediaBigDesktop {
        width: big(24);
        height: big(24);
      }

      &::before {
        width: rem(20);
        height: rem(20);

        @include mediaBigDesktop {
          width: big(20);
          height: big(20);
        }
      }
    }
  }

  @content;
}

@mixin smallCheckbox {
  & .checkbox {
    &__item {
      width: rem(20);
      height: rem(20);

      @include mediaBigDesktop {
        width: big(20);
        height: big(20);
      }

      &::before {
        width: rem(16);
        height: rem(16);

        @include mediaBigDesktop {
          width: big(16);
          height: big(16);
        }
      }
    }
  }

  @content;
}
