.modal-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(30);

  @include mediaBigDesktop {
    gap: big(30);
  }

  @include mediaTablet {
    gap: rem(25);
  }
  
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(10);
    
    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(40);

    @include mediaBigDesktop {
      gap: big(40);
    }

    @include mediaLaptop {
      gap: rem(30);
    }

    @include mediaTablet {
      gap: rem(25);
    }

    @include mediaMobile {
      gap: rem(20);
    }
  }

  &__submit {
    width: fit-content;
    display: flex;
    flex-direction: column;

    @include mediaMobile {
      width: 100%;
    }

    & .button {
      width: fit-content;

      @include mediaMobile {
        width: 100%;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(30);

    @include mediaBigDesktop {
      gap: big(30);
    }

    @include mediaLaptop {
      gap: rem(20);
    }

    @include mediaTablet {
      gap: rem(15);
    }
  }

  &__policy {
    color: var(--text-dark-primary);
    font: var(--font-body-XS);

    & a {
      color: var(--accent-light-primary);
      text-decoration: none;

      transition: color var(--animation-timing) var(--cubic-bezier);

      @include hover {
        color: var(--accent-light-secondary);
      }
    }
  }

  &__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaTablet {
      gap: rem(10);
    }

    & .text-input {
      width: 100%;

      &__input {
        width: 100%;
      }

      & textarea {
        &.text-input__input {
          height: rem(100);

          @include mediaBigDesktop {
            height: big(100);
          }


        }
      }
    }
  }

  &__rating {
    gap: rem(15);
    align-items: flex-start;

    @include mediaBigDesktop {
      gap: big(15);
    }

    & .text {
      color: var(--text-dark-tertiary);
      font: var(--font-body-S);
    }

    & .rating {
      width: rem(124);
      height: rem(24);

      cursor: pointer;

      @include mediaBigDesktop {
        width: big(124);
        height: big(24);
      }

      @include mediaLaptop {
        width: rem(114);
        height: rem(22);
      }
    }
  }
}
