.tab {
  text-decoration: none;
    
  &.active {
    .tab__content {
      font-weight: 500;
      // text-shadow: 0.1px 0.1px 0.1px var(--text-dark-secondary),
      //   0 0.1px 0.1px var(--text-dark-secondary),
      //   -0.1px 0 0.1px var(--text-dark-secondary),
      //   0 -0.1px 0.1px var(--text-dark-secondary);

      &::before {
        width: 100%;
      }
    }
  }
  
  &__content {
    position: relative;

    padding: 0 rem(30) rem(29);
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: rem(10);

    color: var(--text-dark-primary);
    font: var(--font-caption-M);
    text-transform: uppercase;
    white-space: nowrap;

    border-bottom: solid rem(1) var(--stroke-dark-secondary);

    cursor: pointer;

    @include mediaBigDesktop {
      padding: 0 big(30) big(28);
      gap: big(10);

      border-width: big(1);
    }

    @include mediaLaptop {
      padding: 0 rem(25) rem(24);
    }

    @include mediaTablet {
      padding: 0 rem(18) rem(14);
    }

    @include mediaMobile {
      padding: 0 rem(10) rem(11);
    }

    &::before {
      content: "";
      position: absolute;
      bottom: rem(-1);
      left: 0;
      right: 0;
      margin: auto;

      width: 0%;
      height: rem(2);

      background-color: var(--accent-light-primary);

      transition: width var(--animation-timing) var(--cubic-bezier);

      @include mediaBigDesktop {
        bottom: big(-1);
        height: big(2);
      }
    }
  }

  @include hover {
    & .tab {
      &__content {
        &::before {
          width: 100%;
        }
      }
    }
  }

  &__icon {
    width: rem(20);
    height: rem(20);

    fill: var(--text-dark-primary);

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }

    @include mediaLaptop {
      width: rem(16);
      height: rem(16);
    }

    @include mediaTablet {
      width: rem(14);
      height: rem(14);
    }
  }

  &__input {
    &:checked {
      &~.tab {
        &__content {
          font-weight: 500;
          // text-shadow: 0.1px 0.1px 0.1px var(--text-dark-secondary),
          //   0 0.1px 0.1px var(--text-dark-secondary),
          //   -0.1px 0 0.1px var(--text-dark-secondary),
          //   0 -0.1px 0.1px var(--text-dark-secondary);

          &::before {
            width: 100%;
          }
        }
      }
    }
  }
}
