@import '../../mixins';

.modal-window {
  margin-left: auto;

  padding: 0;
  width: 100%;
  max-width: rem(690);
  height: 100%;

  border-radius: var(--radius-common) 0 0 var(--radius-common);
  background-color: var(--bg-white);

  @include mediaBigDesktop {
    max-width: big(690)
  }

  @include mediaLaptop {
    max-width: rem(507);
  }

  @include mediaTablet {
    max-width: rem(374);
  }

  @include mediaMobile {
    max-width: unset;
  }

  &_small {
    max-width: rem(490);

    @include mediaBigDesktop {
      max-width: big(490);
    }

    @include mediaLaptop {
      max-width: rem(400);
    }

    @include mediaTablet {
      max-width: unset;
    }
  }

  &__container {
    padding: rem(110) var(--container-offset);
    width: 100%;
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(50);

    overflow: auto;

    @include scrollbarDelete;

    @include mediaBigDesktop {
      padding: big(110) var(--container-offset);
      gap: big(50);
    }

    @include mediaLaptop {
      gap: rem(40)
    }

    @include mediaTablet {
      padding: rem(70) var(--container-offset);
      gap: rem(30)
    }

    @include mediaMobile {
      padding: rem(60) var(--container-offset);
      gap: rem(20)
    }
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaTablet {
      gap: rem(10);
    }
  }

  &__title {
    color: var(--text-dark-primary)
  }

  &__subtitle {
    color: var(--text-dark-primary);
    font: var(--font-body-S);
  }

  & .contacts-info {
    background-color: transparent;

    &__list {
      display: flex;
      flex-direction: column;
      gap: rem(30);

      @include mediaBigDesktop {
        gap: big(30);
      }

      @include mediaLaptop {
        gap: rem(25);
      }

      @include mediaTablet {
        gap: rem(20);
      }
    }

    &__row {
      display: flex;
      flex-direction: column;
    }
  }
}

@import "modal-form";
