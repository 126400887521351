.social-list {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: rem(10);

  @include mediaBigDesktop {
    gap: big(10);
  }

  @include mediaMobile {
    gap: rem(8);
  }

  &__item {
    @include mediumButtonIcon {}

    @include mediaLaptop {
      border-color: transparent !important;
    }

    @include mediaMobile {
      width: rem(34);
      height: rem(34);
    }

    &.light.stroke {
      & .button__icon {
        fill: var(--accent-dark-primary);
      }
    }

    & .button {
      &__icon {
        @include mediaMobile {
          width: rem(15.5);
          height: rem(15.5);
        }
      }
    }
  }
}
