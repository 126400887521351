.text-input {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: rem(5);

  @include mediaBigDesktop {
    gap: big(5);
  }

  &__subtitle {
    color: var(--text-dark-secondary);
  }

  &__input {
    padding: rem(12) rem(18);

    color: var(--text-dark-primary);
    font: var(--font-body-XS);

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark-secondary);
    border-radius: var(--radius-common);

    transition: border-color var(--animation-timing) var(--cubic-bezier),
      background-color var(--animation-timing) var(--cubic-bezier),
      color var(--animation-timing) var(--cubic-bezier);

    outline: none;
    resize: none;

    @include mediaBigDesktop {
      padding: big(12) big(18);

      border-width: big(1);
    }

    @include mediaLaptop {
      padding: rem(10.5) rem(18);
    }

    @include mediaTablet {
      padding: rem(12) rem(18);
    }

    @include hover {
      border-color: var(--accent-light-primary);
    }

    &:focus {
      border-color: var(--accent-light-primary);
    }

    &:disabled {
      color: var(--text-disable);

      background-color: var(--bg-disable);
      border-color: var(--stroke-dark-secondary);

      &::placeholder {
        color: var(--text-disable);
      }
    }

    &::placeholder {
      color: var(--text-dark-tertiary);
    }
  }

  &_datetime {
    position: relative;

    width: 100%;
    height: rem(50);

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark-secondary);
    border-radius: var(--radius-common);

    transition: border-color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      height: big(50);
    }

    @include mediaLaptop {
      height: rem(44);
    }

    @include hover {
      border-color: var(--accent-light-primary);
    }

    & .text-input {
      &__input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        width: 100%;
        height: 100%;

        opacity: 0;

        cursor: pointer;

        &::-webkit-calendar-picker-indicator {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: auto;
          height: auto;
          color: transparent;
          background: 0 0;
          cursor: pointer
        }

        &::-webkit-clear-button,
        &::-webkit-inner-spin-button {
          z-index: -10
        }
      }

      &__placeholder,
      &__current-text {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;

        padding: 0 0 0 rem(20);
        width: 100%;
        height: fit-content;
        display: block;

        color: var(--text-dark-tertiary);
        font: var(--font-body-XS);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        transition: opacity var(--animation-timing) var(--cubic-bezier);

        @include mediaBigDesktop {
          padding: 0 0 0 big(20);
        }
      }

      &__current-text {
        color: var(--text-dark-primary);

        opacity: 0;
      }
    }

    &.value {
      & .text-input {
        &__placeholder {
          opacity: 0;
        }

        &__current-text {
          opacity: 1;
        }
      }
    }
  }
}

.common-input {
  &__icon {
    width: rem(12);
    height: rem(12);
    flex-shrink: 0;
    fill: var(--system-states-error);

    user-select: none;

    @include mediaBigDesktop {
      width: big(12);
      height: big(12);
    }
  }

  &__error {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    margin-top: rem(-10);
    padding: rem(10);

    width: 100%;
    display: flex;
    flex-direction: row;
    gap: rem(10);
    align-items: center;
    justify-content: start;

    background-color: var(--bg-white);
    box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);

    transition: margin-top var(--animation-timing) var(--cubic-bezier),
      opacity var(--animation-timing) var(--cubic-bezier),
      visibility var(--animation-timing) var(--cubic-bezier);

    pointer-events: none;
    opacity: 1;
    visibility: hidden;

    @include mediaBigDesktop {
      padding: big(10);

      gap: big(10);
    }
  }

  &_error {
    & .common-input {
      &__error {
        margin-top: 0;

        opacity: 1 !important;
        visibility: visible;

        pointer-events: all;
      }

    }

    &>.text-input__input {
      border-color: var(--system-states-error);
    }
  }

  &__text {
    font-size: rem(12);

    @include mediaBigDesktop {
      font-size: big(12);
    }
  }
}
