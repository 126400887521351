.number-input {
  padding: 0 rem(20);
  height: rem(50);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: rem(15);

  border: solid 0;
  border-radius: var(--radius-common);
  background-color: var(--bg-light-extra);

  @include mediaBigDesktop {
    padding: 0 big(20);

    gap: big(15);
  }

  &_medium {
    @include mediumNumberInput
  }

  &__button {
    position: relative;

    width: fit-content;
    height: fit-content;
    display: flex;

    overflow: visible;

    &::before {
      @include pseudo(10) {}
    }

    @include hover {
      & .number-input {
        &__icon {
          fill: var(--accent-light-primary);
        }
      }
    }
  }

  &__icon {
    width: rem(24);
    height: rem(24);

    fill: var(--text-dark-primary);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(24);
      height: big(24);
    }
  }

  &__input {
    padding: 0;
    margin: 0;

    width: rem(25);
    height: rem(27);

    color: var(--text-dark-primary);
    font-size: rem(18);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: rem(1.8);
    text-transform: uppercase;
    text-align: center;

    outline: none;
    background-color: var(--bg-light-extra);

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(25);
      height: big(27);

      font-size: big(18);
      letter-spacing: big(1.8);
    }
  }

  &.disabled {
    opacity: 1;

    & .number-input {
      &__input {
        color: var(--text-disable);
      }

      &__icon {
        fill: var(--text-disable);
      }
    }
  }
}
