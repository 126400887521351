.important-information {
  padding: rem(40);
  padding-left: rem(37);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(30);

  background-color: var(--bg-light);
  border-left: solid 3px var(--accent-light-primary);
  border-radius: var(--radius-common);

  @include mediaBigDesktop {
    padding: big(40);
    padding-left: big(37);
    gap: big(37);

    border-left-width: big(3);
  }

  @include mediaLaptop {
    padding: rem(30);
    padding-left: rem(27);
    gap: rem(25);
  }

  @include mediaMobile {
    padding: rem(15);
    padding-left: rem(13);
    flex-direction: column;
    gap: rem(15);

    border-left-width: rem(2);
  }

  &__text {
    color: var(--text-dark-primary);
  }

  &__icon {
    width: rem(50);
    height: rem(50);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    background-color: var(--bg-white);
    border-radius: var(--radius-infinity);
    overflow: hidden;

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      width: big(50);
      height: big(50);
    }

    @include mediaMobile {
      width: rem(40);
      height: rem(40);
    }

    &::before {
      content: "";

      width: rem(24);
      height: rem(24);

      background-color: var(--accent-light-primary);
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 4.00098C3 3.73576 3.10536 3.48141 3.29289 3.29387C3.48043 3.10633 3.73478 3.00098 4 3.00098H20C20.2652 3.00098 20.5196 3.10633 20.7071 3.29387C20.8946 3.48141 21 3.73576 21 4.00098V18.001C21 18.7966 20.6839 19.5597 20.1213 20.1223C19.5587 20.6849 18.7956 21.001 18 21.001H6C5.20435 21.001 4.44129 20.6849 3.87868 20.1223C3.31607 19.5597 3 18.7966 3 18.001V4.00098ZM12 13C11.7348 13 11.4804 12.8946 11.2929 12.7071C11.1054 12.5195 11 12.2652 11 12V7.99998C11 7.73476 11.1054 7.48041 11.2929 7.29287C11.4804 7.10533 11.7348 6.99998 12 6.99998C12.2652 6.99998 12.5196 7.10533 12.7071 7.29287C12.8946 7.48041 13 7.73476 13 7.99998V12C13 12.2652 12.8946 12.5195 12.7071 12.7071C12.5196 12.8946 12.2652 13 12 13ZM10.5 16C10.5 15.6022 10.658 15.2206 10.9393 14.9393C11.2206 14.658 11.6022 14.5 12 14.5H12.01C12.4078 14.5 12.7894 14.658 13.0707 14.9393C13.352 15.2206 13.51 15.6022 13.51 16V16.01C13.51 16.4078 13.352 16.7893 13.0707 17.0706C12.7894 17.3519 12.4078 17.51 12.01 17.51H12C11.6022 17.51 11.2206 17.3519 10.9393 17.0706C10.658 16.7893 10.5 16.4078 10.5 16.01V16Z' fill='%2365A4A8'/%3E%3C/svg%3E");
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat;

      @include mediaBigDesktop {
        width: big(24);
        height: big(24);
      }

      @include mediaMobile {
        width: rem(18);
        height: rem(18);
      }
    }
  }
}
