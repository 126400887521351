@font-face {
  font-family: 'Jost';
  src: url("../fonts/Jost/Regular/Jost-Regular.woff2") format("woff2"), url("../fonts/Jost/Regular/Jost-Regular.woff") format("woff"), url("../fonts/Jost/Regular/Jost-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Jost';
  src: url("../fonts/Jost/Medium/Jost-Medium.woff2") format("woff2"), url("../fonts/Jost/Medium/Jost-Medium.woff") format("woff"), url("../fonts/Jost/Medium/Jost-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Jost';
  src: url("../fonts/Jost/SemiBold/Jost-SemiBold.woff2") format("woff2"), url("../fonts/Jost/SemiBold/Jost-SemiBold.woff") format("woff"), url("../fonts/Jost/SemiBold/Jost-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Jost';
  src: url("../fonts/Jost/Bold/Jost-Bold.woff2") format("woff2"), url("../fonts/Jost/Bold/Jost-Bold.woff") format("woff"), url("../fonts/Jost/Bold/Jost-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

:root {
  --content-width: 150rem;
  --container-offset: 5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --content-secondary-width: 131.25rem;
  --container-secondary-offset: 14.375rem;
  --container-secondary-width: calc(var(--content-secondary-width) + (var(--container-secondary-offset) * 2));
  --content-thirty-width: 60rem;
  --container-thirty-offset: 30rem;
  --container-thirty-width: calc(var(--content-thirty-width) + (var(--container-thirty-offset) * 2));
  --font-primary: "Jost", sans-serif;
  --font-h1: 400 3.5rem/125% var(--font-primary);
  --font-h2: 400 2.875rem/130% var(--font-primary);
  --font-h3: 500 2rem/140% var(--font-primary);
  --font-h4: 500 1.625rem/140% var(--font-primary);
  --font-h5: 500 1.25rem/150% var(--font-primary);
  --font-body-L: 400 1.5rem/150% var(--font-primary);
  --font-body-M: 400 1.25rem/150% var(--font-primary);
  --font-body-S: 400 1.25rem/150% var(--font-primary);
  --font-body-XS: 400 1rem/150% var(--font-primary);
  --font-caption-L: 400 1.375rem/150% var(--font-primary);
  --font-caption-M: 400 1.125rem/150% var(--font-primary);
  --font-caption-S: 400 1rem/150% var(--font-primary);
  --font-input-S: 400 0.875rem/150% var(--font-primary);
  --font-h1-card: 500 2rem/140% var(--font-primary);
  --font-card-title-L: 500 1.625rem/140% var(--font-primary);
  --font-card-title-M: 500 1.25rem/150% var(--font-primary);
  --font-cta-title: 400 2.875rem/130% var(--font-primary);
  --font-photo: 400 1rem/150% var(--font-primary);
  --font-price: 500 2rem/140% var(--font-primary);
  --article-width: 67.125rem;
  --shadow-large: 0px 0px 1px 0px rgba(0, 0, 0, 0.04),
	0px 0px 8px 0px rgba(0, 0, 0, 0.04),
	0px 10px 24px 0px rgba(0, 0, 0, 0.04),
	0px 15px 32px 0px rgba(0, 0, 0, 0.04);
  --shadow-small: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
	0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  --cubic-bezier: cubic-bezier(.25, .1, .25, 1);
  --animation-timing-small: 0.2s;
  --animation-timing: 0.5s;
  --animation-timing-medium: 0.7s;
  --animation-timing-long: 1s;
  --section-padding: 6.875rem;
  --section-gap: 3.75rem;
  --radius-common: 0rem;
  --radius-secondary: 0.25rem;
  --radius-infinity: 100rem;
  --grid-column-gap: 2.5rem;
  --grid-row-gap: 2.5rem; }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --content-width: 93.75vw;
      --container-offset: 3.125vw; } }
  @media screen and (max-width: 106.25rem) {
    :root {
      --container-offset: 3.75rem; } }
  @media screen and (max-width: 85rem) {
    :root {
      --container-offset: 2.8125rem; } }
  @media screen and (max-width: 39.375rem) {
    :root {
      --container-offset: 1.25rem; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --content-secondary-width: 82.03125vw;
      --container-secondary-offset: 8.98438vw; } }
  @media screen and (max-width: 106.25rem) {
    :root {
      --container-secondary-offset: 10.1875rem; } }
  @media screen and (max-width: 85rem) {
    :root {
      --container-secondary-offset: 2.8125rem; } }
  @media screen and (max-width: 39.375rem) {
    :root {
      --container-secondary-offset: 1.25rem; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --content-thirty-width: 37.5vw;
      --container-thirty-offset: 18.75vw; } }
  @media screen and (max-width: 106.25rem) {
    :root {
      --container-thirty-offset: 22.1875rem; } }
  @media screen and (max-width: 85rem) {
    :root {
      --container-thirty-offset: 2.8125rem; } }
  @media screen and (max-width: 39.375rem) {
    :root {
      --container-thirty-offset: 1.25rem; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --font-h1: 400 2.1875vw/125% var(--font-primary);
      --font-h2: 400 1.79687vw/130% var(--font-primary);
      --font-h3: 500 1.25vw/140% var(--font-primary);
      --font-h4: 500 1.01562vw/140% var(--font-primary);
      --font-h5: 500 0.78125vw/150% var(--font-primary);
      --font-body-L: 400 0.9375vw/150% var(--font-primary);
      --font-body-M: 400 0.78125vw/150% var(--font-primary);
      --font-body-S: 400 0.78125vw/150% var(--font-primary);
      --font-body-XS: 400 0.625vw/150% var(--font-primary);
      --font-caption-L: 400 0.85938vw/150% var(--font-primary);
      --font-caption-M: 400 0.70312vw/150% var(--font-primary);
      --font-caption-S: 400 0.625vw/150% var(--font-primary);
      --font-input-S: 400 0.54688vw/150% var(--font-primary);
      --font-h1-card: 500 1.25vw/140% var(--font-primary);
      --font-card-title-L: 500 1.01562vw/140% var(--font-primary);
      --font-card-title-M: 500 0.78125vw/150% var(--font-primary);
      --font-cta-title: 400 1.79687vw/130% var(--font-primary);
      --font-photo: 400 0.625vw/150% var(--font-primary);
      --font-price: 500 1.25vw/140% var(--font-primary); } }
  @media screen and (max-width: 106.25rem) {
    :root {
      --font-h1: 400 2.875rem/130% var(--font-primary);
      --font-h2: 400 2.25rem/138% var(--font-primary);
      --font-h3: 500 1.75rem/140% var(--font-primary);
      --font-h4: 500 1.375rem/150% var(--font-primary);
      --font-h5: 500 1.125rem/140% var(--font-primary);
      --font-body-L: 400 1.25rem/150% var(--font-primary);
      --font-body-M: 400 1.125rem/150% var(--font-primary);
      --font-body-S: 400 1rem/150% var(--font-primary);
      --font-body-XS: 400 0.875rem/150% var(--font-primary);
      --font-caption-L: 400 1.125rem/150% var(--font-primary);
      --font-caption-M: 400 1rem/150% var(--font-primary);
      --font-caption-S: 400 0.875rem/150% var(--font-primary);
      --font-card-title-L: 500 1.375rem/150% var(--font-primary);
      --font-card-title-M: 500 1.125rem/140% var(--font-primary);
      --font-cta-title: 400 2.25rem/130% var(--font-primary);
      --font-price: 500 1.75rem/140% var(--font-primary); } }
  @media screen and (max-width: 85rem) {
    :root {
      --font-h1: 400 2.25rem/139% var(--font-primary);
      --font-h2: 400 1.75rem/143% var(--font-primary);
      --font-h3: 500 1.5rem/140% var(--font-primary);
      --font-h4: 500 1.25rem/150% var(--font-primary);
      --font-h5: 500 1rem/140% var(--font-primary);
      --font-body-L: 400 1.125rem/150% var(--font-primary);
      --font-body-M: 400 1rem/150% var(--font-primary);
      --font-body-S: 400 0.875rem/150% var(--font-primary);
      --font-body-XS: 400 0.75rem/150% var(--font-primary);
      --font-caption-L: 500 1rem/150% var(--font-primary);
      --font-caption-M: 400 0.875rem/150% var(--font-primary);
      --font-caption-S: 400 0.75rem/150% var(--font-primary);
      --font-input-S: 400 0.75rem/150% var(--font-primary);
      --font-card-title-L: 500 1.25rem/150% var(--font-primary);
      --font-card-title-M: 500 1rem/140% var(--font-primary);
      --font-cta-title: 400 1.5rem/130% var(--font-primary);
      --font-photo: 400 0.875rem/150% var(--font-primary);
      --font-price: 500 1.5rem/140% var(--font-primary); } }
  @media screen and (max-width: 39.375rem) {
    :root {
      --font-h1: 400 1.75rem/129% var(--font-primary);
      --font-h2: 400 1.5rem/142% var(--font-primary);
      --font-h3: 500 1.25rem/140% var(--font-primary);
      --font-h4: 500 1.125rem/150% var(--font-primary);
      --font-card-title-L: 500 1.125rem/150% var(--font-primary);
      --font-photo: 400 0.75rem/150% var(--font-primary);
      --font-price: 500 1.25rem/140% var(--font-primary); } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --article-width: 41.95312vw; } }
  @media screen and (max-width: 106.25rem) {
    :root {
      --article-width: 51.25rem; } }
  @media screen and (min-width: 137.5625rem) {
    :root {
      --section-padding: 6.875rem;
      --section-gap: 5rem; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --section-padding: 4.29688vw;
      --section-gap: 3.125vw; } }
  @media screen and (max-width: 106.25rem) {
    :root {
      --section-padding: 5.625rem;
      --section-gap: 3.125rem; } }
  @media screen and (max-width: 85rem) {
    :root {
      --section-padding: 3.75rem;
      --section-gap: 2.5rem; } }
  @media screen and (max-width: 39.375rem) {
    :root {
      --section-padding: 3.125rem;
      --section-gap: 1.875rem; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --radius-common: 0vw;
      --radius-secondary: 0.15625vw;
      --radius-infinity: 62.5vw; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --grid-column-gap: 1.5625vw;
      --grid-row-gap: 1.5625vw; } }
  @media screen and (max-width: 85rem) {
    :root {
      --grid-column-gap: 1.25rem;
      --grid-row-gap: 1.875rem; } }
  @media screen and (max-width: 39.375rem) {
    :root {
      --grid-column-gap: 0.625rem;
      --grid-row-gap: 1.5rem; } }

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; }

.page {
  height: 100%;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  color: var(--text-dark-primary); }
  @media screen and (min-width: 160.0625rem) {
    .page {
      scroll-padding: 4.21875vw; } }
  @media screen and (max-width: 106.25rem) {
    .page {
      scroll-padding: 4.0625rem; } }
  @media screen and (max-width: 39.375rem) {
    .page {
      scroll-padding: 3.5rem; } }

.main {
  height: 100%;
  display: flex;
  flex-direction: column; }

::selection {
  background: var(--accent-light-primary);
  color: var(--text-light-primary); }

::-moz-selection {
  background: var(--accent-light-primary);
  color: var(--text-light-primary); }

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 1rem;
  background-color: var(--bg-white); }
  @media screen and (min-width: 160.0625rem) {
    .page__body {
      font-size: 0.625vw; } }
  .page__body::-webkit-scrollbar {
    width: 0.8125rem; }
    @media screen and (min-width: 160.0625rem) {
      .page__body::-webkit-scrollbar {
        width: 0.50781vw; } }
  .page__body::-webkit-scrollbar-track {
    background: var(--bg-white); }
  .page__body::-webkit-scrollbar-thumb {
    background-color: var(--stroke-dark-secondary);
    border-radius: 0;
    border: 4px solid var(--bg-white);
    cursor: pointer; }
    @media screen and (min-width: 160.0625rem) {
      .page__body::-webkit-scrollbar-thumb {
        border-radius: 39.02344vw;
        border-width: 0.15625vw; } }
    @media (any-hover: hover) {
      .page__body::-webkit-scrollbar-thumb:hover {
        background-color: var(--accent-light-primary); } }

[data-pseudo]:after {
  content: attr(data-pseudo); }

.disable-scroll {
  width: 100%;
  height: 100vh;
  overflow: hidden; }

.wrapper {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: column; }
  .wrapper__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }

.footer {
  margin-top: auto; }

.input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: var(--bg-white); }
  .input::-webkit-search-decoration, .input::-webkit-search-cancel-button, .input::-webkit-search-results-button, .input::-webkit-search-results-decoration {
    display: none; }
  .input::-webkit-outer-spin-button, .input::-webkit-inner-spin-button {
    -webkit-appearance: none; }
  .input[type=number] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield; }

.visually-hidden {
  position: absolute;
  overflow: hidden;
  visibility: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0); }

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width); }
  .container .container-secondary {
    padding: 0 calc(var(--container-secondary-offset) - var(--container-offset)); }
  .container .container-thirty {
    padding: 0 calc(var(--container-thirty-offset) - var(--container-offset)); }

.container-secondary {
  margin: 0 auto;
  padding: 0 var(--container-secondary-offset);
  width: 100%;
  max-width: var(--container-secondary-width); }

.container-thirty {
  margin: 0 auto;
  padding: 0 var(--container-thirty-offset);
  width: 100%;
  max-width: var(--container-thirty-width); }

.reset-styles {
  padding: 0;
  margin: 0; }

.disabled {
  opacity: 0.5;
  pointer-events: none;
  transform: opacity var(--animation-timing) var(--cubic-bezier); }

.elite-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  cursor: pointer;
  transition: opacity var(--animation-timing-medium) var(--cubic-bezier) var(--animation-timing), visibility var(--animation-timing-medium) var(--cubic-bezier) var(--animation-timing); }
  .elite-backdrop_show {
    opacity: 0.1;
    visibility: visible;
    pointer-events: all; }

.swiper-slide {
  height: auto;
  display: flex;
  align-items: stretch; }

.swiper__pagination {
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.9375rem; }
  @media screen and (min-width: 160.0625rem) {
    .swiper__pagination {
      gap: 0.58594vw; } }
  @media screen and (max-width: 85rem) {
    .swiper__pagination {
      gap: 0.625rem; } }

.swiper__bullet {
  position: relative;
  width: 0.5rem;
  height: 0.5rem;
  display: block;
  background-color: var(--text-dark-tertiary);
  border-radius: var(--radius-infinity);
  transition: background-color var(--animation-timing) var(--cubic-bezier);
  cursor: pointer; }
  @media screen and (min-width: 160.0625rem) {
    .swiper__bullet {
      width: 0.3125vw;
      height: 0.3125vw; } }
  @media (any-hover: hover) {
    .swiper__bullet:hover {
      background-color: var(--text-dark-secondary); } }
  .swiper__bullet::before {
    content: "";
    position: absolute;
    top: -0.46875rem;
    left: -0.46875rem;
    width: calc(100% + 0.46875rem * 2);
    height: calc(100% + 0.46875rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .swiper__bullet::before {
        top: -0.29297vw;
        left: -0.29297vw;
        width: calc(100% + 0.29297vw * 2);
        height: calc(100% + 0.29297vw * 2); } }
    @media screen and (max-width: 85rem) {
      .swiper__bullet::before {
        content: "";
        position: absolute;
        top: -0.3125rem;
        left: -0.3125rem;
        width: calc(100% + 0.3125rem * 2);
        height: calc(100% + 0.3125rem * 2);
        background-color: transparent;
        opacity: 0; } }
  @media screen and (max-width: 85rem) and (min-width: 160.0625rem) {
    .swiper__bullet::before {
      top: -0.19531vw;
      left: -0.19531vw;
      width: calc(100% + 0.19531vw * 2);
      height: calc(100% + 0.19531vw * 2); } }
    @media screen and (max-width: 39.375rem) {
      .swiper__bullet::before {
        content: "";
        position: absolute;
        top: -0.15625rem;
        left: -0.15625rem;
        width: calc(100% + 0.15625rem * 2);
        height: calc(100% + 0.15625rem * 2);
        background-color: transparent;
        opacity: 0; } }
  @media screen and (max-width: 39.375rem) and (min-width: 160.0625rem) {
    .swiper__bullet::before {
      top: -0.09766vw;
      left: -0.09766vw;
      width: calc(100% + 0.09766vw * 2);
      height: calc(100% + 0.09766vw * 2); } }
  .swiper__bullet_active {
    background-color: var(--text-dark-primary) !important; }

.pagination-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3.75rem; }
  @media screen and (min-width: 160.0625rem) {
    .pagination-wrapper {
      gap: 2.34375vw; } }
  @media screen and (max-width: 85rem) {
    .pagination-wrapper {
      gap: 2.5rem; } }
  .pagination-wrapper__pagination {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .pagination-wrapper__pagination .pagination {
      width: 100%; }
      @media screen and (max-width: 85rem) {
        .pagination-wrapper__pagination .pagination {
          max-width: 26.1875rem; } }

.article {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.25rem;
  font: var(--font-body-M); }
  @media screen and (min-width: 160.0625rem) {
    .article {
      gap: 0.78125vw; } }
  @media screen and (max-width: 85rem) {
    .article {
      gap: 0.9375rem; } }
  @media screen and (max-width: 39.375rem) {
    .article {
      gap: 0.625rem; } }
  .article ul,
  .article ol,
  .article p,
  .article span,
  .article h1,
  .article h2,
  .article h3,
  .article h4,
  .article h5,
  .article h6 {
    margin: 0;
    padding: 0; }
  .article img {
    max-width: 100%;
    max-height: 85vh;
    object-fit: cover; }
  .article p,
  .article span {
    color: var(--section-text-color-secondary); }
  .article p {
    width: 100%; }
  .article a {
    color: var(--accent-light-primary);
    text-decoration: none;
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media (any-hover: hover) {
      .article a:hover {
        color: var(--accent-light-secondary); } }

.text {
  padding: 0;
  margin: 0; }
  .text_body_large {
    font: var(--font-body-L); }
  .text_body_medium {
    font: var(--font-body-M); }
  .text_body_small {
    font: var(--font-body-S); }
  .text_body_xs {
    font: var(--font-body-XS); }
  .text_caption_large {
    font: var(--font-caption-L);
    text-transform: uppercase;
    letter-spacing: 0.1375rem; }
    @media screen and (min-width: 160.0625rem) {
      .text_caption_large {
        letter-spacing: 0.08594vw; } }
    @media screen and (max-width: 106.25rem) {
      .text_caption_large {
        letter-spacing: 0.1125rem; } }
    @media screen and (max-width: 85rem) {
      .text_caption_large {
        letter-spacing: 0.1rem; } }
  .text_caption_medium {
    font: var(--font-caption-M);
    text-transform: uppercase;
    letter-spacing: 0.1125rem; }
    @media screen and (min-width: 160.0625rem) {
      .text_caption_medium {
        letter-spacing: 0.07031vw; } }
    @media screen and (max-width: 106.25rem) {
      .text_caption_medium {
        letter-spacing: 0.1rem; } }
    @media screen and (max-width: 85rem) {
      .text_caption_medium {
        letter-spacing: 0.0875rem; } }
  .text_caption_small {
    font: var(--font-caption-S);
    text-transform: uppercase;
    letter-spacing: 0.1rem; }
    @media screen and (min-width: 160.0625rem) {
      .text_caption_small {
        letter-spacing: 0.0625vw; } }
    @media screen and (max-width: 106.25rem) {
      .text_caption_small {
        letter-spacing: 0.0875rem; } }
    @media screen and (max-width: 85rem) {
      .text_caption_small {
        letter-spacing: 0.075rem; } }
  .text_semibold {
    font-weight: 500; }
  .text_card_large {
    font: var(--font-card-title-L);
    text-transform: uppercase;
    letter-spacing: 0.04875rem; }
    @media screen and (min-width: 160.0625rem) {
      .text_card_large {
        letter-spacing: 0.03047vw; } }
    @media screen and (max-width: 106.25rem) {
      .text_card_large {
        letter-spacing: 0.04125rem; } }
    @media screen and (max-width: 85rem) {
      .text_card_large {
        letter-spacing: 0.0375rem; } }
    @media screen and (max-width: 39.375rem) {
      .text_card_large {
        letter-spacing: 0.03375rem; } }
  .text_card_medium {
    font: var(--font-card-title-M); }
  .text_photo {
    color: var(--text-dark-tertiary);
    font: var(--font-photo);
    font-style: italic;
    letter-spacing: 0.01rem; }
    @media screen and (min-width: 160.0625rem) {
      .text_photo {
        letter-spacing: 0.00625vw; } }
    @media screen and (max-width: 85rem) {
      .text_photo {
        letter-spacing: 0.00875rem; } }
    @media screen and (max-width: 39.375rem) {
      .text_photo {
        letter-spacing: 0.0075rem; } }

.h1,
.article h1 {
  margin: 0;
  padding: 0;
  font: var(--font-h1);
  letter-spacing: 0.105rem;
  text-transform: uppercase; }
  @media screen and (min-width: 160.0625rem) {
    .h1,
    .article h1 {
      letter-spacing: 0.06562vw; } }
  @media screen and (max-width: 106.25rem) {
    .h1,
    .article h1 {
      letter-spacing: 0.08625rem; } }
  @media screen and (max-width: 85rem) {
    .h1,
    .article h1 {
      letter-spacing: 0.0675rem; } }
  @media screen and (max-width: 39.375rem) {
    .h1,
    .article h1 {
      letter-spacing: 0.0525rem; } }

.h1-card {
  margin: 0;
  padding: 0;
  font: var(--font-h1-card); }

.h2,
.article h2 {
  margin: 0;
  padding: 0;
  font: var(--font-h2);
  letter-spacing: 0.08625rem;
  text-transform: uppercase; }
  @media screen and (min-width: 160.0625rem) {
    .h2,
    .article h2 {
      letter-spacing: 0.05391vw; } }
  @media screen and (max-width: 106.25rem) {
    .h2,
    .article h2 {
      letter-spacing: 0.0675rem; } }
  @media screen and (max-width: 85rem) {
    .h2,
    .article h2 {
      letter-spacing: 0.0525rem; } }
  @media screen and (max-width: 39.375rem) {
    .h2,
    .article h2 {
      letter-spacing: 0.045rem; } }

.h3,
.article h3 {
  margin: 0;
  padding: 0;
  font: var(--font-h3); }

.h4,
.article h4 {
  margin: 0;
  padding: 0;
  font: var(--font-h4);
  letter-spacing: 0.04875rem;
  text-transform: uppercase; }
  @media screen and (min-width: 160.0625rem) {
    .h4,
    .article h4 {
      letter-spacing: 0.03047vw; } }
  @media screen and (max-width: 106.25rem) {
    .h4,
    .article h4 {
      letter-spacing: 0.04125rem; } }
  @media screen and (max-width: 85rem) {
    .h4,
    .article h4 {
      letter-spacing: 0.0375rem; } }
  @media screen and (max-width: 39.375rem) {
    .h4,
    .article h4 {
      letter-spacing: 0.03375rem; } }

.h5,
.article h5,
.article h6 {
  margin: 0;
  padding: 0;
  font: var(--font-h5); }

.cta-title {
  margin: 0;
  padding: 0;
  font: var(--font-cta-title);
  text-transform: uppercase;
  letter-spacing: 1.38px; }
  @media screen and (min-width: 160.0625rem) {
    .cta-title {
      letter-spacing: 0.05391vw; } }
  @media screen and (max-width: 106.25rem) {
    .cta-title {
      letter-spacing: 1.08px; } }
  @media screen and (max-width: 85rem) {
    .cta-title {
      letter-spacing: 0.84px; } }
  @media screen and (max-width: 39.375rem) {
    .cta-title {
      letter-spacing: 0.72px; } }

.accent-color {
  color: var(--accent-light-primary); }

.button {
  position: relative;
  padding: 0;
  margin: 0;
  width: fit-content;
  font-family: var(--font-primary);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.04375rem;
  text-decoration: none;
  text-transform: uppercase;
  background: transparent;
  border: none;
  border-radius: var(--radius-common);
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent; }
  @media screen and (min-width: 160.0625rem) {
    .button {
      font-size: 0.54688vw;
      letter-spacing: 0.02734vw; } }
  @media screen and (max-width: 85rem) {
    .button {
      font-size: 0.75rem;
      letter-spacing: 0.0375rem; } }
  .button:disabled {
    color: var(--text-disable);
    background-color: var(--bg-disable);
    pointer-events: none; }
    .button:disabled .button__icon {
      fill: var(--text-disable); }
  .button_primary {
    padding: 0 1.5625rem;
    min-height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-light-primary);
    background-color: var(--accent-light-primary); }
    @media screen and (min-width: 160.0625rem) {
      .button_primary {
        padding: 0 0.97656vw;
        min-height: 1.95312vw; } }
    @media screen and (max-width: 106.25rem) {
      .button_primary {
        min-height: 2.75rem; } }
    @media screen and (max-width: 39.375rem) {
      .button_primary {
        padding: 0 1.25rem; } }
    @media (any-hover: hover) {
      .button_primary:hover::before {
        top: unset;
        bottom: 0;
        height: 100%; } }
    .button_primary::before {
      content: "";
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 0%;
      background-color: var(--accent-light-secondary);
      border-radius: 0;
      transition: height var(--animation-timing-medium) var(--cubic-bezier); }
  .button_secondary {
    padding: 0 1.4375rem;
    min-height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--accent-light-primary);
    background: transparent;
    border: solid 1px var(--accent-light-primary);
    transition: background-color var(--animation-timing) var(--cubic-bezier), color var(--animation-timing) var(--cubic-bezier), border-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .button_secondary {
        padding: 0 0.89844vw;
        min-height: 1.95312vw; } }
    @media screen and (max-width: 106.25rem) {
      .button_secondary {
        min-height: 2.75rem; } }
    @media screen and (max-width: 39.375rem) {
      .button_secondary {
        padding: 0 1.125rem; } }
    @media (any-hover: hover) {
      .button_secondary:hover {
        color: var(--text-light-primary);
        border-color: var(--accent-light-secondary); }
        .button_secondary:hover::before {
          top: unset;
          bottom: 0;
          height: 100%; }
        .button_secondary:hover .button__icon {
          fill: var(--bg-white); } }
    .button_secondary .button__icon {
      fill: var(--accent-light-primary); }
    .button_secondary::before {
      content: "";
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 0%;
      background-color: var(--accent-light-secondary);
      border-radius: 0;
      transition: height var(--animation-timing-medium) var(--cubic-bezier); }
    .button_secondary:disabled {
      background-color: transparent;
      border: solid 1px transparent; }
      @media screen and (min-width: 160.0625rem) {
        .button_secondary:disabled {
          border-width: 0.03906vw; } }
  .button_tertiary {
    position: relative;
    display: flex;
    gap: 0.625rem;
    color: var(--accent-light-primary);
    transition: color var(--animation-timing) var(--cubic-bezier);
    overflow: hidden; }
    @media screen and (min-width: 160.0625rem) {
      .button_tertiary {
        gap: 0.39062vw; } }
    .button_tertiary .button__content {
      gap: 0.625rem;
      pointer-events: none; }
      @media screen and (min-width: 160.0625rem) {
        .button_tertiary .button__content {
          gap: 0.39062vw; } }
    .button_tertiary .button__icon {
      position: relative;
      fill: var(--accent-light-primary); }
    @media (any-hover: hover) {
      .button_tertiary:hover {
        color: var(--accent-light-secondary); }
        .button_tertiary:hover .button__icon {
          fill: var(--accent-light-secondary); } }
    .button_tertiary:disabled {
      color: var(--text-disable);
      background-color: transparent; }
    .button_tertiary::before {
      content: "";
      position: absolute;
      top: -0.9375rem;
      left: -0.9375rem;
      width: calc(100% + 0.9375rem * 2);
      height: calc(100% + 0.9375rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .button_tertiary::before {
          top: -0.58594vw;
          left: -0.58594vw;
          width: calc(100% + 0.58594vw * 2);
          height: calc(100% + 0.58594vw * 2); } }
  .button_icon {
    width: 3.125rem;
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-light-primary);
    border-radius: var(--radius-infinity);
    border: solid 0;
    background-color: var(--stroke-light-secondary);
    backdrop-filter: blur(1.25rem);
    transition: color var(--animation-timing) var(--cubic-bezier), border-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .button_icon {
        width: 1.95312vw;
        height: 1.95312vw;
        backdrop-filter: blur(0.78125vw); } }
    @media (any-hover: hover) {
      .button_icon:hover {
        color: var(--accent-light-primary); }
        .button_icon:hover .button__icon {
          fill: var(--accent-light-primary); }
        .button_icon:hover .button__content {
          color: var(--accent-light-primary);
          background-color: var(--stroke-light-secondary); } }
    .button_icon:disabled {
      background-color: var(--stroke-light-secondary); }
      .button_icon:disabled .button__icon {
        fill: var(--text-disable); }
    .button_icon .button__icon {
      position: relative;
      z-index: 1;
      fill: var(--text-light-primary); }
    .button_icon .button__content {
      pointer-events: none; }
    .button_icon .button__icon {
      width: 1.5rem;
      height: 1.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .button_icon .button__icon {
          width: 0.9375vw;
          height: 0.9375vw; } }
    .button_icon.medium {
      width: 2.75rem;
      height: 2.75rem; }
      @media screen and (min-width: 160.0625rem) {
        .button_icon.medium {
          width: 1.71875vw;
          height: 1.71875vw; } }
      .button_icon.medium .button__icon {
        width: 1.25rem;
        height: 1.25rem; }
        @media screen and (min-width: 160.0625rem) {
          .button_icon.medium .button__icon {
            width: 0.78125vw;
            height: 0.78125vw; } }
    .button_icon.animation {
      --before-top: 0;
      --before-right: 0;
      --before-bottom: 0;
      --before-left: 0;
      --before-size: 0;
      --before-scale: 0; }
      @media (any-hover: hover) {
        .button_icon.animation:hover {
          color: var(--text-light-primary); }
          .button_icon.animation:hover .button__icon {
            fill: var(--elements-white); }
          .button_icon.animation:hover .button__content {
            color: var(--text-light-primary); } }
      .button_icon.animation::after {
        content: "";
        position: absolute;
        z-index: 0;
        top: var(--before-top);
        left: var(--before-left);
        width: var(--before-size);
        height: var(--before-size);
        background-color: var(--accent-light-primary);
        border-radius: 100rem;
        transform: translate(-50%, -50%); }
    .button_icon.light {
      color: var(--text-dark-primary);
      background-color: var(--stroke-dark-secondary); }
      @media (any-hover: hover) {
        .button_icon.light:hover {
          color: var(--accent-light-primary); }
          .button_icon.light:hover .button__icon {
            fill: var(--accent-light-primary); }
          .button_icon.light:hover .button__content {
            color: var(--accent-light-primary);
            background-color: var(--stroke-dark-secondary); } }
      .button_icon.light:disabled .button__icon {
        fill: var(--text-disable); }
      @media (any-hover: hover) {
        .button_icon.light.animation:hover {
          color: var(--accent-light-primary); }
          .button_icon.light.animation:hover .button__icon {
            fill: var(--elements-white); }
          .button_icon.light.animation:hover .button__content {
            color: var(--text-light-primary); } }
      .button_icon.light.animation::after {
        content: "";
        position: absolute;
        z-index: 0;
        top: var(--before-top);
        left: var(--before-left);
        width: var(--before-size);
        height: var(--before-size);
        background-color: var(--accent-light-primary);
        border-radius: 100rem;
        transform: translate(-50%, -50%); }
      .button_icon.light .button__icon {
        position: relative;
        z-index: 1;
        fill: var(--text-dark-primary); }
      .button_icon.light .button__content {
        color: var(--text-dark-primary); }
    .button_icon.stroke {
      background-color: transparent;
      border: solid 1px var(--stroke-light-secondary); }
      .button_icon.stroke.light {
        background-color: var(--bg-white);
        border: solid 1px var(--stroke-dark-secondary); }
    .button_icon-right .button__icon {
      order: -1; }
  .button_description {
    padding: 0.875rem 1.25rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.9375rem;
    background-color: var(--bg-white);
    border: solid 1px var(--accent-light-primary);
    border-radius: var(--radius-common);
    transition: background-color var(--animation-timing-long) var(--cubic-bezier), border-color var(--animation-timing-long) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .button_description {
        padding: 0.58594vw 0.78125vw;
        gap: 0.58594vw;
        border-width: 0.03906vw; } }
    .button_description .button__icon {
      z-index: 1; }
    .button_description .button__name {
      z-index: 1; }
    .button_description .button__description {
      z-index: 1; }
    @media (any-hover: hover) {
      .button_description:hover .button__icon {
        fill: var(--text-light-primary); }
      .button_description:hover .button__name {
        color: var(--text-light-primary); }
      .button_description:hover .button__description {
        color: var(--text-light-secondary); } }
    .button_description .button__column {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0;
      order: 1; }
    .button_description .button__name {
      color: var(--accent-light-primary);
      font: 600 0.75rem/150% var(--font-primary);
      letter-spacing: 0.6px;
      text-transform: uppercase;
      text-align: start;
      transition: color var(--animation-timing) var(--cubic-bezier); }
      @media screen and (min-width: 160.0625rem) {
        .button_description .button__name {
          font: 600 0.46875vw/150% var(--font-primary); } }
    .button_description .button__description {
      color: var(--text-dark-secondary);
      font: var(--font-body-XS);
      text-align: start;
      text-transform: none;
      transition: color var(--animation-timing) var(--cubic-bezier); }
    .button_description .button__icon {
      width: 1.25rem;
      height: 1.25rem;
      fill: var(--accent-light-primary);
      transition: fill var(--animation-timing) var(--cubic-bezier); }
      @media screen and (min-width: 160.0625rem) {
        .button_description .button__icon {
          width: 0.78125vw;
          height: 0.78125vw; } }
  .button__icon {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    order: 2;
    fill: var(--bg-white);
    transition: fill var(--animation-timing) var(--cubic-bezier);
    pointer-events: none; }
    @media screen and (min-width: 160.0625rem) {
      .button__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
    @media screen and (max-width: 106.25rem) {
      .button__icon {
        width: 1.125rem;
        height: 1.125rem; } }
    @media screen and (max-width: 85rem) {
      .button__icon {
        width: 1rem;
        height: 1rem; } }
  .button__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    white-space: nowrap; }
    @media screen and (min-width: 160.0625rem) {
      .button__content {
        gap: 0.39062vw; } }

.button-product {
  padding: 0.8125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  text-decoration: none;
  background-color: var(--accent-light-primary);
  border: solid 1px var(--accent-light-primary);
  border-radius: var(--radius-infinity);
  backdrop-filter: blur(0.3125rem);
  transition: padding var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier), gap var(--animation-timing) var(--cubic-bezier), border-color var(--animation-timing) var(--cubic-bezier);
  user-select: none;
  cursor: pointer; }
  @media screen and (min-width: 160.0625rem) {
    .button-product {
      padding: 0.54688vw;
      backdrop-filter: blur(0.19531vw);
      border-width: 0.03906vw; } }
  @media screen and (max-width: 106.25rem) {
    .button-product {
      padding: 0.65625rem 0.75rem; } }
  .button-product:disabled {
    background-color: var(--bg-disable);
    border-color: var(--bg-disable);
    pointer-events: none; }
    .button-product:disabled .button-product__icon {
      fill: var(--text-disable); }
    .button-product:disabled .button-product__text {
      color: var(--text-disable); }
  @media (any-hover: hover) {
    .button-product:hover {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      background-color: var(--accent-light-secondary);
      border-color: var(--accent-light-secondary); } }
  @media screen and (any-hover: hover) and (min-width: 160.0625rem) {
    .button-product:hover {
      padding-left: 0.9375vw;
      padding-right: 0.9375vw; } }
  @media screen and (any-hover: hover) and (max-width: 106.25rem) {
    .button-product:hover {
      padding-left: 1.1875rem;
      padding-right: 1.1875rem; } }
  @media (any-hover: hover) {
      .button-product:hover .button-product__text {
        width: 5.25rem; } }
    @media screen and (any-hover: hover) and (min-width: 160.0625rem) {
      .button-product:hover .button-product__text {
        width: 3.28125vw; } }
  @media (any-hover: hover) {
      .button-product:hover .button-product__icon {
        width: 0;
        opacity: 0; } }
  @media (any-hover: hover) {
    .button-product_link:hover .button-product__text {
      width: 4.375rem; } }
  @media screen and (any-hover: hover) and (min-width: 160.0625rem) {
    .button-product_link:hover .button-product__text {
      width: 2.73438vw; } }
  .button-product_quick {
    background-color: var(--bg-white);
    border-color: var(--accent-dark-primary); }
    @media (any-hover: hover) {
      .button-product_quick:hover {
        background-color: var(--bg-white);
        border-color: var(--accent-dark-primary); }
        .button-product_quick:hover .button-product__text {
          width: 8.1875rem; } }
    @media screen and (any-hover: hover) and (min-width: 160.0625rem) {
      .button-product_quick:hover .button-product__text {
        width: 5.11719vw; } }
    .button-product_quick:disabled {
      background-color: var(--bg-white);
      border-color: var(--text-disable);
      pointer-events: none; }
      .button-product_quick:disabled .button-product__icon {
        fill: var(--text-disable); }
      .button-product_quick:disabled .button-product__text {
        color: var(--text-disable); }
    .button-product_quick .button-product__icon {
      fill: var(--accent-dark-primary); }
    .button-product_quick .button-product__text {
      color: var(--accent-dark-primary); }
  .button-product__icon {
    width: 1.375rem;
    height: 1.375rem;
    order: 2;
    fill: var(--text-light-primary);
    transition: width var(--animation-timing) var(--cubic-bezier), opacity var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .button-product__icon {
        width: 0.85938vw;
        height: 0.85938vw; } }
    @media screen and (max-width: 106.25rem) {
      .button-product__icon {
        width: 1.125rem;
        height: 1.125rem; } }
  .button-product__text {
    width: 0;
    order: 1;
    color: var(--bg-light);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.04375rem;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: end;
    overflow: hidden;
    transition: width var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .button-product__text {
        font-size: 0.54688vw;
        letter-spacing: 0.02734vw; } }

.number-input {
  padding: 0 1.25rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.9375rem;
  border: solid 0;
  border-radius: var(--radius-common);
  background-color: var(--bg-light-extra); }
  @media screen and (min-width: 160.0625rem) {
    .number-input {
      padding: 0 0.78125vw;
      gap: 0.58594vw; } }
  .number-input_medium {
    padding: 0 0.9375rem;
    height: 2.75rem;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .number-input_medium {
        padding: 0 0.58594vw;
        height: 1.71875vw;
        gap: 0.39062vw; } }
    .number-input_medium .number-input__button::before {
      content: "";
      position: absolute;
      top: -0.3125rem;
      left: -0.3125rem;
      width: calc(100% + 0.3125rem * 2);
      height: calc(100% + 0.3125rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .number-input_medium .number-input__button::before {
          top: -0.19531vw;
          left: -0.19531vw;
          width: calc(100% + 0.19531vw * 2);
          height: calc(100% + 0.19531vw * 2); } }
    .number-input_medium .number-input__input {
      height: 1.3125rem;
      font-size: 0.875rem;
      letter-spacing: 0.0875rem; }
      @media screen and (min-width: 160.0625rem) {
        .number-input_medium .number-input__input {
          height: 0.82031vw;
          font-size: 0.54688vw;
          letter-spacing: 0.05469vw; } }
    .number-input_medium .number-input__icon {
      width: 1.25rem;
      height: 1.25rem; }
  .number-input__button {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    overflow: visible; }
    .number-input__button::before {
      content: "";
      position: absolute;
      top: -0.625rem;
      left: -0.625rem;
      width: calc(100% + 0.625rem * 2);
      height: calc(100% + 0.625rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .number-input__button::before {
          top: -0.39062vw;
          left: -0.39062vw;
          width: calc(100% + 0.39062vw * 2);
          height: calc(100% + 0.39062vw * 2); } }
    @media (any-hover: hover) {
      .number-input__button:hover .number-input__icon {
        fill: var(--accent-light-primary); } }
  .number-input__icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--text-dark-primary);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .number-input__icon {
        width: 0.9375vw;
        height: 0.9375vw; } }
  .number-input__input {
    padding: 0;
    margin: 0;
    width: 1.5625rem;
    height: 1.6875rem;
    color: var(--text-dark-primary);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.1125rem;
    text-transform: uppercase;
    text-align: center;
    outline: none;
    background-color: var(--bg-light-extra);
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .number-input__input {
        width: 0.97656vw;
        height: 1.05469vw;
        font-size: 0.70312vw;
        letter-spacing: 0.07031vw; } }
  .number-input.disabled {
    opacity: 1; }
    .number-input.disabled .number-input__input {
      color: var(--text-disable); }
    .number-input.disabled .number-input__icon {
      fill: var(--text-disable); }

.file-input {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.9375rem;
  cursor: pointer;
  user-select: none; }
  @media (any-hover: hover) {
    .file-input:hover .file-input__icon {
      fill: var(--text-dark-primary); } }
  @media screen and (min-width: 160.0625rem) {
    .file-input {
      gap: 0.58594vw; } }
  .file-input__button {
    padding: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-white);
    border: solid 0;
    border-radius: var(--radius-common); }
    @media screen and (min-width: 160.0625rem) {
      .file-input__button {
        padding: 0.46875vw; } }
  .file-input__icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: var(--text-dark-tertiary);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .file-input__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
  .file-input__wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start; }
  .file-input__name {
    color: var(--text-dark-primary);
    font-size: 0.875rem;
    font-weight: 500;
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .file-input__name {
        font-size: 0.54688vw; } }
    @media screen and (max-width: 106.25rem) {
      .file-input__name {
        font-size: 0.75rem; } }
  .file-input__type {
    color: var(--text-dark-secondary);
    font-size: 0.75rem;
    text-transform: uppercase; }
    @media screen and (min-width: 160.0625rem) {
      .file-input__type {
        font-size: 0.46875vw; } }
  .file-input__input:focus ~ .file-input__button > .file-input__icon {
    fill: var(--accent-light-primary); }
  .file-input__input:disabled ~ .file-input__button {
    border-color: var(--stroke-dark-primary); }
    .file-input__input:disabled ~ .file-input__button > .file-input__icon {
      fill: var(--text-disable); }
  .file-input__input:disabled ~ .file-input__wrapper > .file-input__name, .file-input__input:disabled ~ .file-input__wrapper > .file-input__type {
    color: var(--text-disable); }

.file-input-images {
  display: flex;
  gap: 1.25rem;
  overflow: auto; }
  .file-input-images::-webkit-scrollbar {
    height: 0.8125rem; }
    @media screen and (min-width: 160.0625rem) {
      .file-input-images::-webkit-scrollbar {
        height: 0.50781vw; } }
  .file-input-images::-webkit-scrollbar-track {
    background-color: var(--bg-white); }
  .file-input-images::-webkit-scrollbar-thumb {
    background-color: var(--stroke-dark-secondary);
    border-radius: 0;
    border: 4px solid var(--bg-white); }
    @media screen and (min-width: 160.0625rem) {
      .file-input-images::-webkit-scrollbar-thumb {
        border-radius: 39.02344vw;
        border-width: 0.15625vw; } }
    @media (any-hover: hover) {
      .file-input-images::-webkit-scrollbar-thumb:hover {
        background-color: var(--accent-light-primary); } }
  @media screen and (min-width: 160.0625rem) {
    .file-input-images {
      gap: 0.78125vw; } }
  .file-input-images .zoom-image {
    flex-shrink: 0; }

.text-input {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem; }
  @media screen and (min-width: 160.0625rem) {
    .text-input {
      gap: 0.19531vw; } }
  .text-input__subtitle {
    color: var(--text-dark-secondary); }
  .text-input__input {
    padding: 0.75rem 1.125rem;
    color: var(--text-dark-primary);
    font: var(--font-body-XS);
    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark-secondary);
    border-radius: var(--radius-common);
    transition: border-color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier), color var(--animation-timing) var(--cubic-bezier);
    outline: none;
    resize: none; }
    @media screen and (min-width: 160.0625rem) {
      .text-input__input {
        padding: 0.46875vw 0.70312vw;
        border-width: 0.03906vw; } }
    @media screen and (max-width: 106.25rem) {
      .text-input__input {
        padding: 0.65625rem 1.125rem; } }
    @media screen and (max-width: 85rem) {
      .text-input__input {
        padding: 0.75rem 1.125rem; } }
    @media (any-hover: hover) {
      .text-input__input:hover {
        border-color: var(--accent-light-primary); } }
    .text-input__input:focus {
      border-color: var(--accent-light-primary); }
    .text-input__input:disabled {
      color: var(--text-disable);
      background-color: var(--bg-disable);
      border-color: var(--stroke-dark-secondary); }
      .text-input__input:disabled::placeholder {
        color: var(--text-disable); }
    .text-input__input::placeholder {
      color: var(--text-dark-tertiary); }
  .text-input_datetime {
    position: relative;
    width: 100%;
    height: 3.125rem;
    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark-secondary);
    border-radius: var(--radius-common);
    transition: border-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .text-input_datetime {
        height: 1.95312vw; } }
    @media screen and (max-width: 106.25rem) {
      .text-input_datetime {
        height: 2.75rem; } }
    @media (any-hover: hover) {
      .text-input_datetime:hover {
        border-color: var(--accent-light-primary); } }
    .text-input_datetime .text-input__input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer; }
      .text-input_datetime .text-input__input::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        color: transparent;
        background: 0 0;
        cursor: pointer; }
      .text-input_datetime .text-input__input::-webkit-clear-button, .text-input_datetime .text-input__input::-webkit-inner-spin-button {
        z-index: -10; }
    .text-input_datetime .text-input__placeholder, .text-input_datetime .text-input__current-text {
      position: absolute;
      z-index: 0;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      padding: 0 0 0 1.25rem;
      width: 100%;
      height: fit-content;
      display: block;
      color: var(--text-dark-tertiary);
      font: var(--font-body-XS);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      transition: opacity var(--animation-timing) var(--cubic-bezier); }
      @media screen and (min-width: 160.0625rem) {
        .text-input_datetime .text-input__placeholder, .text-input_datetime .text-input__current-text {
          padding: 0 0 0 0.78125vw; } }
    .text-input_datetime .text-input__current-text {
      color: var(--text-dark-primary);
      opacity: 0; }
    .text-input_datetime.value .text-input__placeholder {
      opacity: 0; }
    .text-input_datetime.value .text-input__current-text {
      opacity: 1; }

.common-input__icon {
  width: 0.75rem;
  height: 0.75rem;
  flex-shrink: 0;
  fill: var(--system-states-error);
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .common-input__icon {
      width: 0.46875vw;
      height: 0.46875vw; } }

.common-input__error {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  margin-top: -0.625rem;
  padding: 0.625rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
  justify-content: start;
  background-color: var(--bg-white);
  box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
  transition: margin-top var(--animation-timing) var(--cubic-bezier), opacity var(--animation-timing) var(--cubic-bezier), visibility var(--animation-timing) var(--cubic-bezier);
  pointer-events: none;
  opacity: 1;
  visibility: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .common-input__error {
      padding: 0.39062vw;
      gap: 0.39062vw; } }

.common-input_error .common-input__error {
  margin-top: 0;
  opacity: 1 !important;
  visibility: visible;
  pointer-events: all; }

.common-input_error > .text-input__input {
  border-color: var(--system-states-error); }

.common-input__text {
  font-size: 0.75rem; }
  @media screen and (min-width: 160.0625rem) {
    .common-input__text {
      font-size: 0.46875vw; } }

.select-input {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem; }
  @media screen and (min-width: 160.0625rem) {
    .select-input {
      gap: 0.19531vw; } }
  .select-input_checkbox .select-answer__text {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    font: var(--font-body-XS);
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .select-input_checkbox .select-answer__text {
        gap: 0.39062vw; } }
    @media screen and (max-width: 85rem) {
      .select-input_checkbox .select-answer__text {
        font: var(--font-body-S); } }
    .select-input_checkbox .select-answer__text .select-answer__checkbox {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.25rem;
      height: 1.25rem;
      background-color: var(--bg-white);
      border: solid 1px var(--stroke-dark-secondary);
      border-radius: var(--radius-common);
      transition: background-color var(--animation-timing) var(--cubic-bezier), border-color var(--animation-timing) var(--cubic-bezier); }
      @media screen and (min-width: 160.0625rem) {
        .select-input_checkbox .select-answer__text .select-answer__checkbox {
          width: 0.78125vw;
          height: 0.78125vw;
          border-width: 0.03906vw; } }
      .select-input_checkbox .select-answer__text .select-answer__checkbox:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: flex;
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='white'/%3E%3C/svg%3E");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat; }
        @media screen and (min-width: 160.0625rem) {
          .select-input_checkbox .select-answer__text .select-answer__checkbox:before {
            width: 0.625vw;
            height: 0.625vw; } }
  .select-input_checkbox .select-answer__input:checked ~ .select-answer__text {
    color: var(--accent-light-primary); }
    .select-input_checkbox .select-answer__input:checked ~ .select-answer__text .select-answer__checkbox {
      background-color: var(--accent-light-primary);
      border-color: var(--accent-light-primary); }
  .select-input__subtitle {
    color: var(--text-dark-tertiary); }
  .select-input__checkbox:focus ~ .select-current, .select-input__checkbox:checked ~ .select-current {
    border-color: var(--accent-light-primary); }
    .select-input__checkbox:focus ~ .select-current .select-current__icon, .select-input__checkbox:checked ~ .select-current .select-current__icon {
      transform: rotate(90deg); }
  .select-input__checkbox:focus ~ .select-answer, .select-input__checkbox:checked ~ .select-answer {
    margin-top: 0.625rem;
    opacity: 1 !important;
    visibility: visible; }
    @media screen and (min-width: 160.0625rem) {
      .select-input__checkbox:focus ~ .select-answer, .select-input__checkbox:checked ~ .select-answer {
        margin-top: 0.39062vw; } }
  .select-input__checkbox:disabled ~ .select-current {
    background-color: var(--bg-disable);
    border-color: var(--stroke-dark-secondary); }
    .select-input__checkbox:disabled ~ .select-current .select-current__text {
      color: var(--text-disable); }
    .select-input__checkbox:disabled ~ .select-current .select-current__icon {
      fill: var(--elements-gray); }
  .select-input.common-input_error > .select-input__current {
    border-color: var(--system-states-error); }

.select-current {
  padding: 0.75rem 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.9375rem;
  background-color: var(--bg-white);
  border: solid 1px var(--stroke-dark-secondary);
  border-radius: var(--radius-common);
  transition: border-color var(--animation-timing) var(--cubic-bezier);
  cursor: pointer; }
  @media screen and (min-width: 160.0625rem) {
    .select-current {
      padding: 0.46875vw 0.78125vw;
      border-width: 0.03906vw; } }
  @media screen and (max-width: 106.25rem) {
    .select-current {
      padding: 0.65625rem 1.125rem; } }
  @media screen and (max-width: 85rem) {
    .select-current {
      padding: 0.75rem 1.125rem; } }
  @media (any-hover: hover) {
    .select-current:hover {
      border-color: var(--accent-light-primary); } }
  .select-current__icon {
    width: 1.125rem;
    height: 1.125rem;
    flex-shrink: 0;
    fill: var(--accent-dark-primary);
    transform: rotate(-90deg);
    user-select: none;
    transition: transform var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .select-current__icon {
        width: 0.70312vw;
        height: 0.70312vw; } }
  .select-current__text {
    width: 100%;
    max-width: 100%;
    display: block;
    color: var(--text-dark-primary);
    font: var(--font-body-XS);
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: color var(--animation-timing) var(--cubic-bezier);
    overflow: hidden;
    user-select: none; }
    .select-current__text.placeholder {
      color: var(--text-dark-tertiary); }

.select-answer {
  position: absolute;
  z-index: 4;
  top: 100%;
  left: 0;
  padding: 0.9375rem;
  margin-top: -0.625rem;
  width: 100%;
  height: auto;
  max-height: 20.75rem;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-white);
  box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
  border-radius: var(--radius-common);
  overflow: auto;
  transition: margin-top var(--animation-timing) var(--cubic-bezier), opacity var(--animation-timing) var(--cubic-bezier), visibility var(--animation-timing) var(--cubic-bezier);
  opacity: 0;
  visibility: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .select-answer {
      margin-top: -0.39062vw;
      padding: 0.58594vw;
      max-height: 12.96875vw; } }
  @media screen and (max-width: 85rem) {
    .select-answer {
      padding: 0.625rem; } }
  .select-answer::-webkit-scrollbar {
    width: 0.8125rem; }
    @media screen and (min-width: 160.0625rem) {
      .select-answer::-webkit-scrollbar {
        width: 0.50781vw; } }
  .select-answer::-webkit-scrollbar-track {
    background: var(--bg-white); }
  .select-answer::-webkit-scrollbar-thumb {
    background-color: var(--stroke-dark-secondary);
    border-radius: 0;
    border: 4px solid var(--bg-white);
    cursor: pointer; }
    @media screen and (min-width: 160.0625rem) {
      .select-answer::-webkit-scrollbar-thumb {
        border-radius: 39.02344vw;
        border-width: 0.15625vw; } }
    @media (any-hover: hover) {
      .select-answer::-webkit-scrollbar-thumb:hover {
        background-color: var(--accent-light-primary); } }
  .select-answer__item {
    padding: 0.625rem 0.9375rem;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    color: var(--text-dark-primary);
    text-align: start;
    font-size: 1rem;
    line-height: 1.5;
    text-decoration: none;
    background-color: var(--bg-white);
    border: none;
    border-radius: var(--radius-common);
    transition: background-color var(--animation-timing) var(--cubic-bezier), color var(--animation-timing) var(--cubic-bezier);
    cursor: pointer; }
    @media screen and (min-width: 160.0625rem) {
      .select-answer__item {
        padding: 0.39062vw 0.58594vw;
        font-size: 0.625vw; } }
    @media screen and (max-width: 106.25rem) {
      .select-answer__item {
        padding: 0.71875rem 0.9375rem;
        font-size: 0.875rem; } }
    @media (any-hover: hover) {
      .select-answer__item:hover {
        background-color: var(--bg-light-extra); } }
    .select-answer__item:focus, .select-answer__item:active {
      color: var(--accent-light-primary);
      background-color: var(--bg-white); }
  .select-answer__text {
    white-space: nowrap; }

.tag {
  margin: 0;
  padding: 0.25rem 0.625rem;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-light-primary);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.0875rem;
  text-transform: uppercase;
  background: none;
  background-color: var(--background-color);
  border: none;
  border-radius: var(--radius-primary);
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .tag {
      padding: 0.25rem 0.625rem;
      font-size: 0.54688vw;
      letter-spacing: 0.05469vw; } }
  .tag.medium {
    font-size: 0.75rem;
    letter-spacing: 0.075rem; }
    @media screen and (min-width: 160.0625rem) {
      .tag.medium {
        font-size: 0.46875vw;
        letter-spacing: 0.04688vw; } }
  .tag.small {
    padding: 0.25rem 0.5rem;
    font-size: 0.625rem;
    letter-spacing: 0.0625rem; }
    @media screen and (min-width: 160.0625rem) {
      .tag.small {
        padding: 0.15625vw 0.3125vw;
        font-size: 0.39062vw;
        letter-spacing: 0.03906vw; } }
  .tag.xs {
    padding: 0.125rem 0.25rem;
    font-size: 0.4375rem;
    letter-spacing: 0.04375rem; }
    @media screen and (min-width: 160.0625rem) {
      .tag.xs {
        padding: 0.07812vw 0.15625vw;
        font-size: 0.27344vw;
        letter-spacing: 0.02734vw; } }

.tab {
  text-decoration: none; }
  .tab.active .tab__content {
    font-weight: 500; }
    .tab.active .tab__content::before {
      width: 100%; }
  .tab__content {
    position: relative;
    padding: 0 1.875rem 1.8125rem;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    color: var(--text-dark-primary);
    font: var(--font-caption-M);
    text-transform: uppercase;
    white-space: nowrap;
    border-bottom: solid 0.0625rem var(--stroke-dark-secondary);
    cursor: pointer; }
    @media screen and (min-width: 160.0625rem) {
      .tab__content {
        padding: 0 1.17188vw 1.09375vw;
        gap: 0.39062vw;
        border-width: 0.03906vw; } }
    @media screen and (max-width: 106.25rem) {
      .tab__content {
        padding: 0 1.5625rem 1.5rem; } }
    @media screen and (max-width: 85rem) {
      .tab__content {
        padding: 0 1.125rem 0.875rem; } }
    @media screen and (max-width: 39.375rem) {
      .tab__content {
        padding: 0 0.625rem 0.6875rem; } }
    .tab__content::before {
      content: "";
      position: absolute;
      bottom: -0.0625rem;
      left: 0;
      right: 0;
      margin: auto;
      width: 0%;
      height: 0.125rem;
      background-color: var(--accent-light-primary);
      transition: width var(--animation-timing) var(--cubic-bezier); }
      @media screen and (min-width: 160.0625rem) {
        .tab__content::before {
          bottom: -0.03906vw;
          height: 0.07812vw; } }
  @media (any-hover: hover) {
    .tab:hover .tab__content::before {
      width: 100%; } }
  .tab__icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: var(--text-dark-primary); }
    @media screen and (min-width: 160.0625rem) {
      .tab__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
    @media screen and (max-width: 106.25rem) {
      .tab__icon {
        width: 1rem;
        height: 1rem; } }
    @media screen and (max-width: 85rem) {
      .tab__icon {
        width: 0.875rem;
        height: 0.875rem; } }
  .tab__input:checked ~ .tab__content {
    font-weight: 500; }
    .tab__input:checked ~ .tab__content::before {
      width: 100%; }

ul.list,
ol.list {
  font: var(--font-body-S); }

ul.list,
ol.list,
.article ul,
.article ol {
  margin: 0;
  padding: 0;
  list-style: none;
  color: var(--text-dark-secondary);
  display: flex;
  flex-direction: column;
  gap: 0.625rem; }
  @media screen and (min-width: 160.0625rem) {
    ul.list,
    ol.list,
    .article ul,
    .article ol {
      gap: 0.39062vw; } }
  @media screen and (max-width: 85rem) {
    ul.list,
    ol.list,
    .article ul,
    .article ol {
      gap: 0.3125rem; } }
  ul.list li,
  ol.list li,
  .article ul li,
  .article ol li {
    padding-left: 0.9375rem;
    display: block; }
    @media screen and (min-width: 160.0625rem) {
      ul.list li,
      ol.list li,
      .article ul li,
      .article ol li {
        padding-left: 0.58594vw; } }
    @media screen and (max-width: 106.25rem) {
      ul.list li,
      ol.list li,
      .article ul li,
      .article ol li {
        padding-left: 0.625rem; } }
    ul.list li::before,
    ol.list li::before,
    .article ul li::before,
    .article ol li::before {
      margin-right: 0.9375rem; }
      @media screen and (min-width: 160.0625rem) {
        ul.list li::before,
        ol.list li::before,
        .article ul li::before,
        .article ol li::before {
          margin-right: 0.58594vw; } }
      @media screen and (max-width: 106.25rem) {
        ul.list li::before,
        ol.list li::before,
        .article ul li::before,
        .article ol li::before {
          margin-right: 0.625rem; } }
    ul.list li b,
    ul.list li strong,
    ol.list li b,
    ol.list li strong,
    .article ul li b,
    .article ul li strong,
    .article ol li b,
    .article ol li strong {
      color: var(--text-dark-primary);
      font-weight: 600; }
  ul.list ol,
  ul.list ul,
  ol.list ol,
  ol.list ul,
  .article ul ol,
  .article ul ul,
  .article ol ol,
  .article ol ul {
    padding: 0; }

ul.list li,
.article ul li {
  padding-left: 2.1875rem;
  text-indent: -1.25rem; }
  @media screen and (min-width: 160.0625rem) {
    ul.list li,
    .article ul li {
      padding-left: 1.36719vw;
      text-indent: -0.78125vw; } }
  @media screen and (max-width: 106.25rem) {
    ul.list li,
    .article ul li {
      padding-left: 1.5625rem;
      text-indent: -0.9375rem; } }
  ul.list li::before,
  .article ul li::before {
    content: "";
    position: relative;
    bottom: 0.25rem;
    width: 0.3125rem;
    height: 0.3125rem;
    display: inline-block;
    background-color: var(--accent-light-primary);
    border-radius: 62.4375rem; }
    @media screen and (min-width: 160.0625rem) {
      ul.list li::before,
      .article ul li::before {
        bottom: 0.15625vw;
        width: 0.19531vw;
        height: 0.19531vw;
        border-radius: 39.02344vw; } }
    @media screen and (max-width: 106.25rem) {
      ul.list li::before,
      .article ul li::before {
        bottom: 0.1875rem; } }
    @media screen and (max-width: 39.375rem) {
      ul.list li::before,
      .article ul li::before {
        bottom: 0.125rem; } }

ol.list,
.article ol {
  list-style-type: none;
  counter-reset: num; }
  ol.list li,
  .article ol li {
    padding-left: 2.125rem;
    text-indent: -1.875rem; }
    @media screen and (min-width: 160.0625rem) {
      ol.list li,
      .article ol li {
        padding-left: 1.32812vw;
        text-indent: -1.17188vw; } }
    @media screen and (max-width: 106.25rem) {
      ol.list li,
      .article ol li {
        padding-left: 1.5625rem;
        text-indent: -1.25rem; } }
    ol.list li::before,
    .article ol li::before {
      margin-right: 0.3125rem;
      width: 1.5625rem;
      height: auto;
      display: inline-block;
      color: var(--accent-light-primary);
      text-indent: 0;
      content: counters(num, ".") ".";
      counter-increment: num; }
      @media screen and (min-width: 160.0625rem) {
        ol.list li::before,
        .article ol li::before {
          margin-right: 0.19531vw;
          width: 0.97656vw; } }
      @media screen and (max-width: 85rem) {
        ol.list li::before,
        .article ol li::before {
          width: 0.9375rem; } }
  ol.list ol,
  .article ol ol {
    margin: 0;
    margin-top: 0.625rem;
    padding: 0;
    list-style-type: none;
    counter-reset: num;
    list-style: none;
    color: var(--text-dark-secondary);
    display: flex;
    flex-direction: column;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      ol.list ol,
      .article ol ol {
        margin-top: 0.39062vw;
        gap: 0.39062vw; } }
    @media screen and (max-width: 85rem) {
      ol.list ol,
      .article ol ol {
        margin-top: 0.3125rem;
        gap: 0.3125rem; } }
    ol.list ol li::before,
    .article ol ol li::before {
      content: counters(num, ".") ".";
      counter-increment: num;
      width: auto; }

.pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: 3.125rem; }
  @media screen and (min-width: 160.0625rem) {
    .pagination {
      gap: 1.95312vw; } }
  @media screen and (max-width: 85rem) {
    .pagination {
      gap: 3.4375rem; } }
  @media screen and (max-width: 39.375rem) {
    .pagination {
      gap: 0.3125rem; } }
  .pagination__list {
    margin: 0;
    padding: 0;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    list-style: none; }
    @media screen and (min-width: 160.0625rem) {
      .pagination__list {
        gap: 2.5rem; } }
    @media screen and (max-width: 39.375rem) {
      .pagination__list {
        width: 100%;
        gap: 1.5rem; } }
    .pagination__list .button {
      position: relative;
      width: fit-content;
      height: fit-content;
      overflow: visible; }
      @media screen and (max-width: 106.25rem) {
        .pagination__list .button {
          width: fit-content !important;
          height: fit-content !important; } }
      .pagination__list .button::before {
        content: "";
        position: absolute;
        top: -1.25rem;
        left: -1.25rem;
        width: calc(100% + 1.25rem * 2);
        height: calc(100% + 1.25rem * 2);
        background-color: transparent;
        opacity: 0; }
        @media screen and (min-width: 160.0625rem) {
          .pagination__list .button::before {
            top: -0.78125vw;
            left: -0.78125vw;
            width: calc(100% + 0.78125vw * 2);
            height: calc(100% + 0.78125vw * 2); } }
        @media screen and (max-width: 39.375rem) {
          .pagination__list .button::before {
            content: "";
            position: absolute;
            top: -0.75rem;
            left: -0.75rem;
            width: calc(100% + 0.75rem * 2);
            height: calc(100% + 0.75rem * 2);
            background-color: transparent;
            opacity: 0; } }
  @media screen and (max-width: 39.375rem) and (min-width: 160.0625rem) {
    .pagination__list .button::before {
      top: -0.46875vw;
      left: -0.46875vw;
      width: calc(100% + 0.46875vw * 2);
      height: calc(100% + 0.46875vw * 2); } }
      .pagination__list .button__content {
        color: var(--text-dark-secondary);
        font: var(--font-caption-S); }
  .pagination__item_active .button__content {
    font-weight: 500; }
  .pagination__item.dot .button {
    pointer-events: none; }
  .pagination__button.button {
    flex-shrink: 0;
    background-color: transparent; }
    @media screen and (max-width: 106.25rem) {
      .pagination__button.button {
        width: 2.75rem;
        height: 2.75rem; } }
  @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
    .pagination__button.button {
      width: 1.71875vw;
      height: 1.71875vw; } }
    @media screen and (max-width: 106.25rem) {
        .pagination__button.button .button__icon {
          width: 1.25rem;
          height: 1.25rem; } }
    @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
      .pagination__button.button .button__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }

.rating {
  position: relative;
  width: 5.875rem;
  height: 1.125rem;
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .rating {
      width: 3.67188vw;
      height: 0.70312vw; } }
  .rating__enable, .rating__disable {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    user-select: none; }
  .rating__enable {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: var(--rating-percent);
    max-width: 100%;
    fill: var(--other-stars);
    object-fit: fill;
    transition: width var(--animation-timing) var(--cubic-bezier); }
  .rating__disable {
    fill: var(--elements-gray-secondary); }
  .rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .rating-wrapper {
        gap: 0.39062vw; } }
    .rating-wrapper .text {
      color: var(--text-dark-tertiary); }
    .rating-wrapper_review .rating {
      cursor: pointer; }
    .rating-wrapper .button {
      color: var(--accent-dark-primary);
      pointer-events: none; }

.availability {
  --availability-status-color: var(--system-states-success);
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4375rem;
  color: var(--availability-status-color);
  white-space: nowrap;
  border-radius: var(--radius-common);
  user-select: none;
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .availability {
      gap: 0.27344vw; } }
  .availability_out-stock {
    --availability-status-color: var(--system-states-error); }
  .availability_to-order {
    --availability-status-color: var(--system-states-validation); }
  .availability__icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: var(--availability-status-color); }
    @media screen and (min-width: 160.0625rem) {
      .availability__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }

blockquote {
  margin: 0;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  color: var(--text-dark-secondary);
  font: var(--font-body-M);
  background-color: var(--bg-light);
  border-radius: var(--radius-secondary); }
  @media screen and (min-width: 160.0625rem) {
    blockquote {
      padding: 1.17188vw;
      gap: 0.58594vw; } }
  @media screen and (max-width: 106.25rem) {
    blockquote {
      padding: 1.25rem; } }
  blockquote::before {
    content: "";
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    background-color: var(--accent-light-primary);
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cpath d='M10.5 9L12 6H9C5.685 6 3 10.185 3 13.5V24H13.5V13.5H7.5C7.5 9 10.5 9 10.5 9ZM21 13.5C21 9 24 9 24 9L25.5 6H22.5C19.185 6 16.5 10.185 16.5 13.5V24H27V13.5H21Z' fill='%2391B6B2'/%3E%3C/svg%3E");
    mask-size: contain;
    mask-repeat: no-repeat; }
    @media screen and (min-width: 160.0625rem) {
      blockquote::before {
        width: 1.17188vw;
        height: 1.17188vw; } }

.breadcrumbs {
  padding: 0.8125rem 0;
  border: solid var(--stroke-dark-secondary);
  border-width: 1px 0 1px; }
  @media screen and (min-width: 160.0625rem) {
    .breadcrumbs {
      padding: 0.50781vw 0; } }
  @media screen and (max-width: 106.25rem) {
    .breadcrumbs {
      padding: 0.71875rem 0; } }
  .breadcrumbs__list {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    gap: 0.625rem;
    list-style: none; }
    @media screen and (min-width: 160.0625rem) {
      .breadcrumbs__list {
        gap: 0.39062vw; } }
    @media screen and (max-width: 39.375rem) {
      .breadcrumbs__list {
        gap: 0.3125rem; } }
  .breadcrumbs__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .breadcrumbs__item {
        gap: 0.39062vw; } }
    @media screen and (max-width: 39.375rem) {
      .breadcrumbs__item {
        gap: 0.3125rem; } }
    .breadcrumbs__item::after {
      content: "";
      width: 0.625rem;
      height: 0.0625rem;
      display: block;
      background-color: var(--stroke-dark-secondary); }
      @media screen and (min-width: 160.0625rem) {
        .breadcrumbs__item::after {
          width: 0.39062vw;
          height: 0.03906vw; } }
    .breadcrumbs__item:first-child .breadcrumbs__text {
      color: var(--text-dark-secondary); }
    .breadcrumbs__item:last-child::after {
      content: none; }
    .breadcrumbs__item:last-child .breadcrumbs__link {
      pointer-events: none; }
  .breadcrumbs__link {
    position: relative;
    text-decoration: none; }
    @media (any-hover: hover) {
      .breadcrumbs__link:hover .breadcrumbs__text {
        color: var(--accent-light-primary); } }
    .breadcrumbs__link::before {
      content: "";
      position: absolute;
      top: -0.9375rem;
      left: -0.9375rem;
      width: calc(100% + 0.9375rem * 2);
      height: calc(100% + 0.9375rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .breadcrumbs__link::before {
          top: -0.58594vw;
          left: -0.58594vw;
          width: calc(100% + 0.58594vw * 2);
          height: calc(100% + 0.58594vw * 2); } }
      @media screen and (max-width: 39.375rem) {
        .breadcrumbs__link::before {
          content: "";
          position: absolute;
          top: -0.625rem;
          left: -0.625rem;
          width: calc(100% + 0.625rem * 2);
          height: calc(100% + 0.625rem * 2);
          background-color: transparent;
          opacity: 0; } }
  @media screen and (max-width: 39.375rem) and (min-width: 160.0625rem) {
    .breadcrumbs__link::before {
      top: -0.39062vw;
      left: -0.39062vw;
      width: calc(100% + 0.39062vw * 2);
      height: calc(100% + 0.39062vw * 2); } }
  .breadcrumbs__text {
    color: var(--text-dark-tertiary);
    font: var(--font-body-XS);
    transition: color var(--animation-timing) var(--cubic-bezier); }

.checkbox {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.625rem;
  cursor: pointer;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .checkbox {
      gap: 0.39062vw; } }
  @media (any-hover: hover) {
    .checkbox:hover .checkbox__item {
      border-color: var(--accent-light-primary); } }
  .checkbox::before {
    content: "";
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    width: calc(100% + 0.5rem * 2);
    height: calc(100% + 0.5rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .checkbox::before {
        top: -0.3125vw;
        left: -0.3125vw;
        width: calc(100% + 0.3125vw * 2);
        height: calc(100% + 0.3125vw * 2); } }
  .checkbox__input:checked ~ .checkbox__item {
    background-color: var(--accent-light-primary);
    border-color: var(--accent-light-primary); }
  .checkbox__item {
    position: relative;
    width: 1.875rem;
    height: 1.875rem;
    display: block;
    flex-shrink: 0;
    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark-secondary);
    border-radius: var(--radius-primary);
    transition: border-color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .checkbox__item {
        width: 1.17188vw;
        height: 1.17188vw;
        border-width: 0.03906vw; } }
    .checkbox__item::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M9.09368 18C8.86231 18.0001 8.6332 17.9532 8.41947 17.8619C8.20573 17.7706 8.01157 17.6367 7.84811 17.4679L4 13.5038L5.46839 11.9911L9.09368 15.7277L18.5316 6L20 7.51275L10.3392 17.4679C10.1758 17.6367 9.98162 17.7706 9.76788 17.8619C9.55415 17.9532 9.32504 18.0001 9.09368 18Z' fill='white'/%3E%3C/svg%3E");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; }
      @media screen and (min-width: 160.0625rem) {
        .checkbox__item::before {
          width: 0.9375vw;
          height: 0.9375vw; } }
  .checkbox.medium .checkbox__item {
    width: 1.5rem;
    height: 1.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .checkbox.medium .checkbox__item {
        width: 0.9375vw;
        height: 0.9375vw; } }
    .checkbox.medium .checkbox__item::before {
      width: 1.25rem;
      height: 1.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .checkbox.medium .checkbox__item::before {
          width: 0.78125vw;
          height: 0.78125vw; } }
  .checkbox.small .checkbox__item {
    width: 1.25rem;
    height: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .checkbox.small .checkbox__item {
        width: 0.78125vw;
        height: 0.78125vw; } }
    .checkbox.small .checkbox__item::before {
      width: 1rem;
      height: 1rem; }
      @media screen and (min-width: 160.0625rem) {
        .checkbox.small .checkbox__item::before {
          width: 0.625vw;
          height: 0.625vw; } }
  .checkbox .text a {
    position: relative;
    z-index: 1;
    color: var(--accent-light-primary);
    text-decoration: none;
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media (any-hover: hover) {
      .checkbox .text a:hover {
        color: var(--accent-light-secondary); } }

.radio {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.625rem;
  cursor: pointer;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .radio {
      gap: 0.39062vw; } }
  @media (any-hover: hover) {
    .radio:hover .radio__item {
      border-color: var(--accent-light-primary); } }
  .radio::before {
    content: "";
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    width: calc(100% + 0.5rem * 2);
    height: calc(100% + 0.5rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .radio::before {
        top: -0.3125vw;
        left: -0.3125vw;
        width: calc(100% + 0.3125vw * 2);
        height: calc(100% + 0.3125vw * 2); } }
  .radio__input:checked ~ .radio__item {
    background-color: var(--accent-light-primary);
    border-color: var(--accent-light-primary); }
  .radio__item {
    position: relative;
    width: 1.875rem;
    height: 1.875rem;
    display: block;
    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark-secondary);
    border-radius: 10rem;
    transition: border-color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .radio__item {
        width: 1.17188vw;
        height: 1.17188vw;
        border-width: 0.03906vw;
        border-radius: 6.25vw; } }
    .radio__item::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0.625rem;
      height: 0.625rem;
      background-color: var(--bg-white);
      border-radius: 10rem; }
      @media screen and (min-width: 160.0625rem) {
        .radio__item::before {
          width: 0.39062vw;
          height: 0.39062vw; } }
  .radio.medium .radio__item {
    width: 1.5rem;
    height: 1.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .radio.medium .radio__item {
        width: 0.9375vw;
        height: 0.9375vw; } }
    .radio.medium .radio__item::before {
      width: 0.5rem;
      height: 0.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .radio.medium .radio__item::before {
          width: 0.3125vw;
          height: 0.3125vw; } }
  .radio.small .radio__item {
    width: 1.25rem;
    height: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .radio.small .radio__item {
        width: 0.78125vw;
        height: 0.78125vw; } }
    .radio.small .radio__item::before {
      width: 0.5rem;
      height: 0.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .radio.small .radio__item::before {
          width: 0.3125vw;
          height: 0.3125vw; } }

.price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.9375rem; }
  @media screen and (min-width: 160.0625rem) {
    .price {
      gap: 0.58594vw; } }
  .price__old {
    color: var(--text-dark-tertiary);
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    text-decoration-line: strikethrough;
    white-space: nowrap; }
    @media screen and (min-width: 160.0625rem) {
      .price__old {
        font-size: 0.70312vw; } }
    @media screen and (max-width: 106.25rem) {
      .price__old {
        font-size: 1rem; } }
    @media screen and (max-width: 85rem) {
      .price__old {
        font-size: 0.875rem; } }
  .price__sum {
    color: var(--text-dark-primary);
    font: var(--font-body-L);
    white-space: nowrap; }

.section {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .section .container {
    display: flex;
    flex-direction: column;
    gap: var(--section-gap); }
  .section_padding_top {
    padding-top: var(--section-padding); }
  .section_padding_bottom {
    padding-bottom: var(--section-padding); }
  .section_padding_bottom_small {
    padding-bottom: var(--section-gap); }
  .section_background {
    background-color: var(--section-background-color); }
  .section_text-light {
    --section-text-color-primary: var(--text-light-primary);
    --section-text-color-secondary: var(--text-light-secondary);
    --section-text-color-tertiary: var(--text-light-tertiary); }
  .section_line {
    --section-line-width: auto;
    position: relative; }
    .section_line::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: var(--container-secondary-offset);
      left: var(--container-secondary-offset);
      width: var(--section-line-width);
      height: 1px;
      background-color: var(--stroke-dark-secondary); }
      @media screen and (min-width: 160.0625rem) {
        .section_line::before {
          height: 0.03906vw; } }
  .section__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .section__head {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: var(--grid-column-gap); }
  .section__info {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .section__info {
        gap: 0.78125vw; } }
    @media screen and (max-width: 106.25rem) {
      .section__info {
        gap: 1.5625rem; } }
    @media screen and (max-width: 85rem) {
      .section__info {
        gap: 0.625rem; } }
  .section__title {
    color: var(--section-text-color-primary); }
  .section__name {
    color: var(--section-text-color-tertiary);
    font-weight: 500; }
  .section__navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    flex-shrink: 0;
    gap: 1.875rem; }
    @media screen and (min-width: 160.0625rem) {
      .section__navigation {
        gap: 1.17188vw; } }
    @media screen and (max-width: 39.375rem) {
      .section__navigation {
        display: none; } }
  .section__swiper-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .section__swiper-navigation {
        gap: 0.39062vw; } }
    @media screen and (max-width: 85rem) {
      .section__swiper-navigation {
        display: none; } }
    @media screen and (max-width: 106.25rem) {
      .section__swiper-navigation .button {
        width: 2.75rem;
        height: 2.75rem; } }
  @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
    .section__swiper-navigation .button {
      width: 1.71875vw;
      height: 1.71875vw; } }
    @media screen and (max-width: 106.25rem) {
        .section__swiper-navigation .button .button__icon {
          width: 1.25rem;
          height: 1.25rem; } }
    @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
      .section__swiper-navigation .button .button__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
  .section__mobile {
    width: 100%;
    display: none; }
    @media screen and (max-width: 39.375rem) {
      .section__mobile {
        display: flex; } }
    .section__mobile .button {
      width: 100%; }

.social-list {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 0.625rem; }
  @media screen and (min-width: 160.0625rem) {
    .social-list {
      gap: 0.39062vw; } }
  @media screen and (max-width: 39.375rem) {
    .social-list {
      gap: 0.5rem; } }
  .social-list__item {
    width: 2.75rem;
    height: 2.75rem; }
    @media screen and (min-width: 160.0625rem) {
      .social-list__item {
        width: 1.71875vw;
        height: 1.71875vw; } }
    .social-list__item .button__icon {
      width: 1.25rem;
      height: 1.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .social-list__item .button__icon {
          width: 0.78125vw;
          height: 0.78125vw; } }
    @media screen and (max-width: 106.25rem) {
      .social-list__item {
        border-color: transparent !important; } }
    @media screen and (max-width: 39.375rem) {
      .social-list__item {
        width: 2.125rem;
        height: 2.125rem; } }
    .social-list__item.light.stroke .button__icon {
      fill: var(--accent-dark-primary); }
    @media screen and (max-width: 39.375rem) {
      .social-list__item .button__icon {
        width: 0.96875rem;
        height: 0.96875rem; } }

.short-product {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 0.9375rem;
  border-radius: 0;
  border: solid 1px var(--stroke-dark);
  border-width: 1px 0 1px 0; }
  @media screen and (min-width: 160.0625rem) {
    .short-product {
      margin-bottom: 0.78125vw;
      gap: 0.58594vw;
      border-width: 0.03906vw 0 0.03906vw 0; } }
  @media screen and (max-width: 106.25rem) {
    .short-product {
      margin-bottom: 0.9375rem; } }
  @media screen and (max-width: 85rem) {
    .short-product {
      margin-bottom: 1.25rem;
      flex-direction: column; } }
  @media screen and (max-width: 39.375rem) {
    .short-product {
      margin-bottom: 0.9375rem;
      gap: 0.625rem; } }
  .short-product__image {
    position: relative;
    padding: 0.3125rem;
    width: 9.375rem;
    height: 6.0625rem;
    display: flex;
    flex-shrink: 0;
    border-radius: var(--radius-common);
    overflow: hidden;
    user-select: none;
    pointer-events: none; }
    @media screen and (min-width: 160.0625rem) {
      .short-product__image {
        padding: 0.19531vw;
        width: 5.85938vw;
        height: 3.78906vw; } }
    @media screen and (max-width: 39.375rem) {
      .short-product__image {
        width: 100%;
        height: 18.75rem; } }
    @media screen and (max-width: 31.875rem) {
      .short-product__image {
        height: 15.625rem; } }
    @media screen and (max-width: 23.125rem) {
      .short-product__image {
        height: 12.5rem; } }
    @media screen and (max-width: 21.25rem) {
      .short-product__image {
        height: 10rem; } }
    .short-product__image img {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .short-product__text {
    color: var(--text-dark-primary);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .short-product__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .short-product__content {
        gap: 0.19531vw; } }
    @media screen and (max-width: 106.25rem) {
      .short-product__content {
        gap: 0.6875rem; } }
    @media screen and (max-width: 39.375rem) {
      .short-product__content {
        gap: 0.9375rem; } }
  .short-product__panel {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .short-product__panel {
        gap: 0.78125vw; } }
    @media screen and (max-width: 106.25rem) {
      .short-product__panel {
        align-items: end; } }
    @media screen and (max-width: 39.375rem) {
      .short-product__panel {
        width: 100%;
        justify-content: space-between; } }
    @media screen and (max-width: 106.25rem) {
      .short-product__panel .number-input {
        padding: 0 0.9375rem;
        height: 2.75rem;
        gap: 0.625rem; } }
  @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
    .short-product__panel .number-input {
      padding: 0 0.58594vw;
      height: 1.71875vw;
      gap: 0.39062vw; } }
    @media screen and (max-width: 106.25rem) {
        .short-product__panel .number-input .number-input__button::before {
          content: "";
          position: absolute;
          top: -0.3125rem;
          left: -0.3125rem;
          width: calc(100% + 0.3125rem * 2);
          height: calc(100% + 0.3125rem * 2);
          background-color: transparent;
          opacity: 0; } }
    @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
      .short-product__panel .number-input .number-input__button::before {
        top: -0.19531vw;
        left: -0.19531vw;
        width: calc(100% + 0.19531vw * 2);
        height: calc(100% + 0.19531vw * 2); } }
    @media screen and (max-width: 106.25rem) {
        .short-product__panel .number-input .number-input__input {
          height: 1.3125rem;
          font-size: 0.875rem;
          letter-spacing: 0.0875rem; } }
    @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
      .short-product__panel .number-input .number-input__input {
        height: 0.82031vw;
        font-size: 0.54688vw;
        letter-spacing: 0.05469vw; } }
    @media screen and (max-width: 106.25rem) {
        .short-product__panel .number-input .number-input__icon {
          width: 1.25rem;
          height: 1.25rem; } }

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1.25rem;
  text-decoration: none;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .logo {
      gap: 0.78125vw; } }
  @media screen and (max-width: 106.25rem) {
    .logo {
      gap: 0.9375rem; } }
  .logo__text {
    display: block;
    color: var(--text-dark-secondary);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.35;
    white-space: nowrap;
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .logo__text {
        font-size: 0.54688vw; } }
    @media screen and (max-width: 85rem) {
      .logo__text {
        display: none; } }
  .logo__image {
    width: 10.34375rem;
    height: auto;
    object-fit: contain;
    object-position: center;
    pointer-events: none; }
    @media screen and (min-width: 160.0625rem) {
      .logo__image {
        width: 6.46484vw; } }
    @media screen and (max-width: 106.25rem) {
      .logo__image {
        width: 6.875rem; } }
    @media screen and (max-width: 39.375rem) {
      .logo__image {
        width: 5.625rem; } }

.listing-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20.625rem, 1fr));
  column-gap: var(--grid-column-gap);
  row-gap: var(--grid-row-gap); }
  @media screen and (min-width: 137.5625rem) {
    .listing-grid {
      grid-template-columns: repeat(auto-fill, minmax(22.8125rem, 1fr)); } }
  @media screen and (min-width: 160.0625rem) {
    .listing-grid {
      grid-template-columns: repeat(auto-fill, minmax(14.25781vw, 1fr)); } }
  @media screen and (max-width: 106.25rem) {
    .listing-grid {
      grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr)); } }
  @media screen and (max-width: 85rem) {
    .listing-grid {
      grid-template-columns: repeat(auto-fill, minmax(17.5rem, 1fr)); } }
  @media screen and (max-width: 39.375rem) {
    .listing-grid {
      grid-template-columns: repeat(auto-fill, minmax(16.25rem, 1fr)); } }
  .listing-grid .product-card {
    width: 100%; }

.ya-map, .ya-map-multi {
  width: 100%;
  height: 19.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-dark-secondary);
  background-color: var(--bg-light);
  border-radius: var(--radius-common);
  overflow: hidden;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .ya-map, .ya-map-multi {
      height: 11.95312vw; } }
  @media screen and (max-width: 39.375rem) {
    .ya-map, .ya-map-multi {
      height: 15.625rem; } }
  .ya-map .ymaps-2-1-79-controls__toolbar,
  .ya-map .ymaps-2-1-79-controls-pane,
  .ya-map .ymaps-2-1-79-map-copyrights-promo, .ya-map-multi .ymaps-2-1-79-controls__toolbar,
  .ya-map-multi .ymaps-2-1-79-controls-pane,
  .ya-map-multi .ymaps-2-1-79-map-copyrights-promo {
    display: none; }

.tooltip {
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .tooltip_active .tooltip__text {
    opacity: 1;
    visibility: visible; }
  .tooltip__button {
    position: relative;
    width: 1rem;
    height: 1rem;
    z-index: 1;
    background-color: var(--text-disable);
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM7.2 10.4V12H8.8V10.4H7.2ZM8.8 9.084C9.44294 8.89022 9.99488 8.47196 10.3553 7.90539C10.7158 7.33883 10.8607 6.66164 10.7638 5.99717C10.6669 5.3327 10.3345 4.72515 9.82721 4.28517C9.31993 3.84518 8.6715 3.60203 8 3.6C7.35268 3.59995 6.72533 3.82412 6.22463 4.23439C5.72394 4.64467 5.38081 5.2157 5.2536 5.8504L6.8232 6.1648C6.86774 5.94195 6.97465 5.73632 7.13149 5.57185C7.28833 5.40738 7.48865 5.29083 7.70914 5.23576C7.92963 5.18069 8.16122 5.18937 8.37698 5.26078C8.59273 5.33218 8.78377 5.46339 8.92787 5.63913C9.07196 5.81487 9.1632 6.02791 9.19095 6.25347C9.2187 6.47904 9.18182 6.70784 9.08462 6.91327C8.98741 7.11869 8.83387 7.29229 8.64186 7.41386C8.44985 7.53544 8.22726 7.59999 8 7.6C7.78783 7.6 7.58434 7.68429 7.43431 7.83431C7.28429 7.98434 7.2 8.18783 7.2 8.4V9.6H8.8V9.084Z' fill='%23000000'/%3E%3C/svg%3E");
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    overflow: visible;
    transition: background-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .tooltip__button {
        width: 0.625vw;
        height: 0.625vw; } }
    @media (any-hover: hover) {
      .tooltip__button:hover {
        background-color: var(--accent-light-secondary); } }
    .tooltip__button::before {
      content: "";
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      width: calc(100% + 0.5rem * 2);
      height: calc(100% + 0.5rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .tooltip__button::before {
          top: -0.3125vw;
          left: -0.3125vw;
          width: calc(100% + 0.3125vw * 2);
          height: calc(100% + 0.3125vw * 2); } }
    .tooltip__button_active {
      background-color: var(--accent-light-primary); }
  .tooltip__text {
    position: absolute;
    z-index: 2;
    padding: 0.5rem 0.9375rem;
    width: 25rem;
    max-width: 25rem;
    display: flex;
    color: var(--text-dark-primary);
    font: 400 0.875rem/150% var(--font-primary);
    background-color: var(--bg-light);
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    transition: opacity var(--animation-timing) var(--cubic-bezier), visibility var(--animation-timing) var(--cubic-bezier);
    pointer-events: none;
    user-select: none; }
    @media screen and (min-width: 160.0625rem) {
      .tooltip__text {
        padding: 0.3125vw 0.58594vw;
        font: 400 0.54688vw/150% var(--font-primary); } }
  .tooltip__arrow {
    position: absolute;
    width: 0.5625rem;
    height: 0.5625rem;
    display: block;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='9' viewBox='0 0 9 9' fill='none'%3E%3Cpath d='M0 0L9 9H0V0Z' fill='%23F2F2F2'/%3E%3C/svg%3E");
    mask-size: contain;
    mask-position: 0, 0;
    mask-repeat: no-repeat;
    background-color: var(--bg-light); }
    @media screen and (min-width: 160.0625rem) {
      .tooltip__arrow {
        width: 0.35156vw;
        height: 0.35156vw; } }

.important-information {
  padding: 2.5rem;
  padding-left: 2.3125rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.875rem;
  background-color: var(--bg-light);
  border-left: solid 3px var(--accent-light-primary);
  border-radius: var(--radius-common); }
  @media screen and (min-width: 160.0625rem) {
    .important-information {
      padding: 1.5625vw;
      padding-left: 1.44531vw;
      gap: 1.44531vw;
      border-left-width: 0.11719vw; } }
  @media screen and (max-width: 106.25rem) {
    .important-information {
      padding: 1.875rem;
      padding-left: 1.6875rem;
      gap: 1.5625rem; } }
  @media screen and (max-width: 39.375rem) {
    .important-information {
      padding: 0.9375rem;
      padding-left: 0.8125rem;
      flex-direction: column;
      gap: 0.9375rem;
      border-left-width: 0.125rem; } }
  .important-information__text {
    color: var(--text-dark-primary); }
  .important-information__icon {
    width: 3.125rem;
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: var(--bg-white);
    border-radius: var(--radius-infinity);
    overflow: hidden;
    pointer-events: none;
    user-select: none; }
    @media screen and (min-width: 160.0625rem) {
      .important-information__icon {
        width: 1.95312vw;
        height: 1.95312vw; } }
    @media screen and (max-width: 39.375rem) {
      .important-information__icon {
        width: 2.5rem;
        height: 2.5rem; } }
    .important-information__icon::before {
      content: "";
      width: 1.5rem;
      height: 1.5rem;
      background-color: var(--accent-light-primary);
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 4.00098C3 3.73576 3.10536 3.48141 3.29289 3.29387C3.48043 3.10633 3.73478 3.00098 4 3.00098H20C20.2652 3.00098 20.5196 3.10633 20.7071 3.29387C20.8946 3.48141 21 3.73576 21 4.00098V18.001C21 18.7966 20.6839 19.5597 20.1213 20.1223C19.5587 20.6849 18.7956 21.001 18 21.001H6C5.20435 21.001 4.44129 20.6849 3.87868 20.1223C3.31607 19.5597 3 18.7966 3 18.001V4.00098ZM12 13C11.7348 13 11.4804 12.8946 11.2929 12.7071C11.1054 12.5195 11 12.2652 11 12V7.99998C11 7.73476 11.1054 7.48041 11.2929 7.29287C11.4804 7.10533 11.7348 6.99998 12 6.99998C12.2652 6.99998 12.5196 7.10533 12.7071 7.29287C12.8946 7.48041 13 7.73476 13 7.99998V12C13 12.2652 12.8946 12.5195 12.7071 12.7071C12.5196 12.8946 12.2652 13 12 13ZM10.5 16C10.5 15.6022 10.658 15.2206 10.9393 14.9393C11.2206 14.658 11.6022 14.5 12 14.5H12.01C12.4078 14.5 12.7894 14.658 13.0707 14.9393C13.352 15.2206 13.51 15.6022 13.51 16V16.01C13.51 16.4078 13.352 16.7893 13.0707 17.0706C12.7894 17.3519 12.4078 17.51 12.01 17.51H12C11.6022 17.51 11.2206 17.3519 10.9393 17.0706C10.658 16.7893 10.5 16.4078 10.5 16.01V16Z' fill='%2365A4A8'/%3E%3C/svg%3E");
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat; }
      @media screen and (min-width: 160.0625rem) {
        .important-information__icon::before {
          width: 0.9375vw;
          height: 0.9375vw; } }
      @media screen and (max-width: 39.375rem) {
        .important-information__icon::before {
          width: 1.125rem;
          height: 1.125rem; } }

.checked-list {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.9375rem;
  list-style: none; }
  @media screen and (min-width: 160.0625rem) {
    .checked-list {
      gap: 0.58594vw; } }
  @media screen and (max-width: 85rem) {
    .checked-list {
      gap: 0.625rem; } }
  .checked-list__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.9375rem;
    color: var(--text-dark-secondary);
    font: var(--font-body-M); }
    @media screen and (min-width: 160.0625rem) {
      .checked-list__item {
        gap: 0.58594vw; } }
    @media screen and (max-width: 85rem) {
      .checked-list__item {
        gap: 0.75rem; } }
    .checked-list__item::before {
      content: "";
      width: 1.5rem;
      height: 2rem;
      display: flex;
      flex-shrink: 0;
      background-color: var(--accent-light-primary);
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='32' viewBox='0 0 24 32' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 23C13.1819 23 14.3522 22.7672 15.4442 22.3149C16.5361 21.8626 17.5282 21.1997 18.364 20.364C19.1997 19.5282 19.8626 18.5361 20.3149 17.4442C20.7672 16.3522 21 15.1819 21 14C21 12.8181 20.7672 11.6478 20.3149 10.5558C19.8626 9.46392 19.1997 8.47177 18.364 7.63604C17.5282 6.80031 16.5361 6.13738 15.4442 5.68508C14.3522 5.23279 13.1819 5 12 5C9.61305 5 7.32387 5.94821 5.63604 7.63604C3.94821 9.32387 3 11.6131 3 14C3 16.3869 3.94821 18.6761 5.63604 20.364C7.32387 22.0518 9.61305 23 12 23ZM11.768 17.64L16.768 11.64L15.232 10.36L10.932 15.519L8.707 13.293L7.293 14.707L10.293 17.707L11.067 18.481L11.768 17.64Z' fill='%2391B6B2'/%3E%3C/svg%3E");
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat; }
      @media screen and (min-width: 160.0625rem) {
        .checked-list__item::before {
          width: 0.9375vw;
          height: 1.25vw; } }
      @media screen and (max-width: 106.25rem) {
        .checked-list__item::before {
          height: 1.5rem;
          mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21.5C13.1819 21.5 14.3522 21.2672 15.4442 20.8149C16.5361 20.3626 17.5282 19.6997 18.364 18.864C19.1997 18.0282 19.8626 17.0361 20.3149 15.9442C20.7672 14.8522 21 13.6819 21 12.5C21 11.3181 20.7672 10.1478 20.3149 9.05585C19.8626 7.96392 19.1997 6.97177 18.364 6.13604C17.5282 5.30031 16.5361 4.63738 15.4442 4.18508C14.3522 3.73279 13.1819 3.5 12 3.5C9.61305 3.5 7.32387 4.44821 5.63604 6.13604C3.94821 7.82387 3 10.1131 3 12.5C3 14.8869 3.94821 17.1761 5.63604 18.864C7.32387 20.5518 9.61305 21.5 12 21.5ZM11.768 16.14L16.768 10.14L15.232 8.86L10.932 14.019L8.707 11.793L7.293 13.207L10.293 16.207L11.067 16.981L11.768 16.14Z' fill='%2391B6B2'/%3E%3C/svg%3E"); } }
      @media screen and (max-width: 85rem) {
        .checked-list__item::before {
          width: 1.25rem;
          height: 1.6875rem;
          mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='27' viewBox='0 0 20 27' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20C10.9849 20 11.9602 19.806 12.8701 19.4291C13.7801 19.0522 14.6069 18.4997 15.3033 17.8033C15.9997 17.1069 16.5522 16.2801 16.9291 15.3701C17.306 14.4602 17.5 13.4849 17.5 12.5C17.5 11.5151 17.306 10.5398 16.9291 9.62987C16.5522 8.71993 15.9997 7.89314 15.3033 7.1967C14.6069 6.50026 13.7801 5.94781 12.8701 5.5709C11.9602 5.19399 10.9849 5 10 5C8.01088 5 6.10322 5.79018 4.6967 7.1967C3.29018 8.60322 2.5 10.5109 2.5 12.5C2.5 14.4891 3.29018 16.3968 4.6967 17.8033C6.10322 19.2098 8.01088 20 10 20ZM9.80667 15.5333L13.9733 10.5333L12.6933 9.46667L9.11 13.7658L7.25583 11.9108L6.0775 13.0892L8.5775 15.5892L9.2225 16.2342L9.80667 15.5333Z' fill='%2391B6B2'/%3E%3C/svg%3E"); } }

.fan-c-open {
  animation: fan-c-open var(--animation-timing) var(--cubic-bezier); }

@keyframes fan-c-open {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

.fan-c-close {
  animation: fan-c-close var(--animation-timing) var(--cubic-bezier); }

@keyframes fan-c-close {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }

.to-top {
  position: fixed;
  right: 1.25rem;
  bottom: -6.25rem;
  transition: bottom var(--animation-timing-medium) var(--cubic-bezier);
  z-index: 999; }
  @media screen and (max-width: 39.375rem) {
    .to-top {
      width: 1.9375rem;
      height: 1.9375rem; }
      .to-top .button__icon {
        width: 1rem;
        height: 1rem; } }
  .to-top_visible {
    bottom: 2.5rem; }

.js-nav-overflow-detect {
  opacity: 0 !important;
  overflow-x: auto; }
  .js-nav-overflow-detect .nav__link::before,
  .js-nav-overflow-detect .header__button::before {
    content: none; }
  .js-nav-overflow-detect .header-cart__count {
    display: none !important; }
  .js-nav-overflow-detect .header-call__phone {
    white-space: nowrap; }

.fancybox__container:not(.has-toolbar) .fancybox__slide {
  padding: 0 !important;
  height: 100%;
  overflow: hidden; }
  .fancybox__container:not(.has-toolbar) .fancybox__slide::before, .fancybox__container:not(.has-toolbar) .fancybox__slide::after {
    content: unset; }

.fancybox__container.has-toolbar .fancybox__backdrop {
  --fancybox-bg: rgba(0, 0, 0, 0.50); }

.fancybox__container .fancybox__slide {
  padding: 3.125rem 1.25rem; }
  @media screen and (min-width: 160.0625rem) {
    .fancybox__container .fancybox__slide {
      padding: 1.95312vw 0.78125vw; } }
  @media screen and (max-width: 39.375rem) {
    .fancybox__container .fancybox__slide {
      padding: 0 !important; } }
  @media screen and (max-width: 62.5rem) {
    .fancybox__container .fancybox__slide.has-image {
      padding: 1.25rem !important; } }
  @media screen and (max-width: 39.375rem) {
    .fancybox__container .fancybox__slide.has-image {
      padding: 0.9375rem !important; } }
  .fancybox__container .fancybox__slide.has-image .fancybox__content {
    max-width: 67.125rem; }
    @media screen and (min-width: 160.0625rem) {
      .fancybox__container .fancybox__slide.has-image .fancybox__content {
        max-width: 41.95312vw; } }
    @media screen and (max-width: 106.25rem) {
      .fancybox__container .fancybox__slide.has-image .fancybox__content {
        max-width: 51.25rem; } }
  .fancybox__container .fancybox__slide.has-image img {
    border-radius: var(--radius-common);
    user-select: none; }

.fancybox__container .fancybox__nav {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: calc(var(--container-width));
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  pointer-events: none; }

.fancybox__container .f-button.is-next, .fancybox__container .f-button.is-prev {
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: auto !important;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-light-primary);
  border-radius: var(--radius-infinity);
  border: solid 0;
  background-color: var(--stroke-dark-secondary);
  backdrop-filter: blur(20px);
  transition: color var(--animation-timing) var(--cubic-bezier);
  pointer-events: all;
  transform: translateY(0) !important; }
  @media screen and (min-width: 160.0625rem) {
    .fancybox__container .f-button.is-next, .fancybox__container .f-button.is-prev {
      width: 1.95312vw;
      height: 1.95312vw; } }
  @media screen and (max-width: 62.5rem) {
    .fancybox__container .f-button.is-next, .fancybox__container .f-button.is-prev {
      width: 2.75rem;
      height: 2.75rem; } }
  @media screen and (max-width: 62.5rem) and (min-width: 160.0625rem) {
    .fancybox__container .f-button.is-next, .fancybox__container .f-button.is-prev {
      width: 1.71875vw;
      height: 1.71875vw; } }
  @media screen and (max-width: 62.5rem) {
      .fancybox__container .f-button.is-next .button__icon, .fancybox__container .f-button.is-prev .button__icon {
        width: 1.25rem;
        height: 1.25rem; } }
    @media screen and (max-width: 62.5rem) and (min-width: 160.0625rem) {
      .fancybox__container .f-button.is-next .button__icon, .fancybox__container .f-button.is-prev .button__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
  @media screen and (max-width: 39.375rem) {
    .fancybox__container .f-button.is-next, .fancybox__container .f-button.is-prev {
      width: 2.125rem;
      height: 2.125rem; } }
  @media (any-hover: hover) {
    .fancybox__container .f-button.is-next:hover, .fancybox__container .f-button.is-prev:hover {
      color: var(--accent-light-primary); }
      .fancybox__container .f-button.is-next:hover::after, .fancybox__container .f-button.is-prev:hover::after {
        background-color: var(--accent-light-primary); } }
  .fancybox__container .f-button.is-next:disabled, .fancybox__container .f-button.is-prev:disabled {
    opacity: 0; }
  .fancybox__container .f-button.is-next svg, .fancybox__container .f-button.is-prev svg {
    display: none; }
  .fancybox__container .f-button.is-next::before, .fancybox__container .f-button.is-prev::before {
    content: "";
    position: absolute;
    top: -0.375rem;
    left: -0.375rem;
    width: calc(100% + 0.375rem * 2);
    height: calc(100% + 0.375rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .fancybox__container .f-button.is-next::before, .fancybox__container .f-button.is-prev::before {
        top: -0.23438vw;
        left: -0.23438vw;
        width: calc(100% + 0.23438vw * 2);
        height: calc(100% + 0.23438vw * 2); } }
  .fancybox__container .f-button.is-next::after, .fancybox__container .f-button.is-prev::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--text-dark-primary);
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M9.11719 10.0924L13.2422 14.2174L12.0639 15.3957L6.76052 10.0924L12.0639 4.78906L13.2422 5.9674L9.11719 10.0924Z' fill='%23222222'/%3E%3C/svg%3E");
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    transition: background-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .fancybox__container .f-button.is-next::after, .fancybox__container .f-button.is-prev::after {
        width: 0.9375vw;
        height: 0.9375vw; } }
    @media screen and (max-width: 39.375rem) {
      .fancybox__container .f-button.is-next::after, .fancybox__container .f-button.is-prev::after {
        width: 1.125rem;
        height: 1.125rem; } }

.fancybox__container .f-button.is-next {
  margin-right: 0 !important;
  transform: scale(-1, 1) !important; }
  @media screen and (max-width: 62.5rem) {
    .fancybox__container .f-button.is-next {
      margin-right: 1.25rem !important; } }
  @media screen and (max-width: 39.375rem) {
    .fancybox__container .f-button.is-next {
      margin-right: 0.625rem !important; } }

.fancybox__container .f-button.is-prev {
  margin-left: 0 !important; }
  @media screen and (max-width: 62.5rem) {
    .fancybox__container .f-button.is-prev {
      margin-left: 1.25rem !important; } }
  @media screen and (max-width: 39.375rem) {
    .fancybox__container .f-button.is-prev {
      margin-left: 0.625rem !important; } }

.fancybox__container.has-toolbar {
  --fancybox-bg: rgba(0, 0, 0, 0.85); }
  .fancybox__container.has-toolbar .fancybox__toolbar {
    top: 3.125rem; }
    @media screen and (min-width: 160.0625rem) {
      .fancybox__container.has-toolbar .fancybox__toolbar {
        top: 1.95312vw; } }
    @media screen and (max-width: 106.25rem) {
      .fancybox__container.has-toolbar .fancybox__toolbar {
        top: 2.5rem; } }
    @media screen and (max-width: 62.5rem) {
      .fancybox__container.has-toolbar .fancybox__toolbar {
        top: 1.25rem; } }
    .fancybox__container.has-toolbar .fancybox__toolbar .is-right {
      margin-right: 3.125rem; }
      @media screen and (min-width: 160.0625rem) {
        .fancybox__container.has-toolbar .fancybox__toolbar .is-right {
          margin-right: 3.125rem; } }
      @media screen and (max-width: 106.25rem) {
        .fancybox__container.has-toolbar .fancybox__toolbar .is-right {
          margin-right: 2.5rem; } }
      @media screen and (max-width: 62.5rem) {
        .fancybox__container.has-toolbar .fancybox__toolbar .is-right {
          margin-right: 1.25rem; } }
      .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button {
        width: 1.5rem;
        height: 1.5rem; }
        .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close] {
          background: transparent; }
          @media (any-hover: hover) {
            .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close]:hover::after {
              background-color: var(--accent-light-primary); } }
          .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close] svg {
            display: none; }
          .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close]::before {
            content: "";
            position: absolute;
            top: -0.625rem;
            left: -0.625rem;
            width: calc(100% + 0.625rem * 2);
            height: calc(100% + 0.625rem * 2);
            background-color: transparent;
            opacity: 0; }
            @media screen and (min-width: 160.0625rem) {
              .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close]::before {
                top: -0.39062vw;
                left: -0.39062vw;
                width: calc(100% + 0.39062vw * 2);
                height: calc(100% + 0.39062vw * 2); } }
          .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close]::after {
            content: "";
            width: 100%;
            height: 100%;
            background-color: var(--text-dark-primary);
            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4144 10.0002L16.4144 5.00015L15.0002 3.58594L10.0002 8.58594L5.00015 3.58594L3.58594 5.00015L8.58594 10.0002L3.58594 15.0002L5.00015 16.4144L10.0002 11.4144L15.0002 16.4144L16.4144 15.0002L11.4144 10.0002Z' fill='white'/%3E%3C/svg%3E");
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            transition: background-color var(--animation-timing) var(--cubic-bezier); }

.fancybox__content .f-button.is-close-btn {
  top: 2.625rem !important;
  right: var(--container-offset) !important;
  width: 1.5rem;
  height: 1.5rem;
  background: none;
  opacity: 1; }
  .fancybox__content .f-button.is-close-btn:focus-visible {
    outline: none;
    box-shadow: none; }
  @media screen and (min-width: 160.0625rem) {
    .fancybox__content .f-button.is-close-btn {
      top: 1.64062vw !important;
      width: 0.9375vw;
      height: 0.9375vw; } }
  @media screen and (max-width: 106.25rem) {
    .fancybox__content .f-button.is-close-btn {
      top: 2.4375rem !important;
      width: 1.125rem;
      height: 1.125rem; } }
  @media screen and (max-width: 106.25rem) {
    .fancybox__content .f-button.is-close-btn {
      top: 1.4375rem !important; } }
  @media screen and (max-width: 39.375rem) {
    .fancybox__content .f-button.is-close-btn {
      top: 1.25rem !important;
      width: 1rem;
      height: 1rem; } }
  @media (any-hover: hover) {
    .fancybox__content .f-button.is-close-btn:hover::before, .fancybox__content .f-button.is-close-btn:hover::after {
      background-color: var(--accent-light-primary);
      transform: rotate(0deg); } }
  .fancybox__content .f-button.is-close-btn svg {
    display: none; }
  .fancybox__content .f-button.is-close-btn::before, .fancybox__content .f-button.is-close-btn::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1.5rem;
    height: 0.125rem;
    background-color: var(--elements-dark);
    transition: background-color var(--animation-timing) var(--cubic-bezier), transform var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .fancybox__content .f-button.is-close-btn::before, .fancybox__content .f-button.is-close-btn::after {
        width: 0.9375vw;
        height: 0.07812vw; } }
    @media screen and (max-width: 106.25rem) {
      .fancybox__content .f-button.is-close-btn::before, .fancybox__content .f-button.is-close-btn::after {
        width: 1.125rem; } }
    @media screen and (max-width: 39.375rem) {
      .fancybox__content .f-button.is-close-btn::before, .fancybox__content .f-button.is-close-btn::after {
        width: 1rem; } }
  .fancybox__content .f-button.is-close-btn::before {
    transform: rotate(45deg); }
  .fancybox__content .f-button.is-close-btn::after {
    transform: rotate(-45deg); }

.fancybox__backdrop {
  --fancybox-bg: rgba(0, 0, 0, 0.20); }

@keyframes fancyboxShow {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

.modal-window {
  margin-left: auto;
  padding: 0;
  width: 100%;
  max-width: 43.125rem;
  height: 100%;
  border-radius: var(--radius-common) 0 0 var(--radius-common);
  background-color: var(--bg-white); }
  @media screen and (min-width: 160.0625rem) {
    .modal-window {
      max-width: 26.95312vw; } }
  @media screen and (max-width: 106.25rem) {
    .modal-window {
      max-width: 31.6875rem; } }
  @media screen and (max-width: 85rem) {
    .modal-window {
      max-width: 23.375rem; } }
  @media screen and (max-width: 39.375rem) {
    .modal-window {
      max-width: unset; } }
  .modal-window_small {
    max-width: 30.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-window_small {
        max-width: 19.14062vw; } }
    @media screen and (max-width: 106.25rem) {
      .modal-window_small {
        max-width: 25rem; } }
    @media screen and (max-width: 85rem) {
      .modal-window_small {
        max-width: unset; } }
  .modal-window__container {
    padding: 6.875rem var(--container-offset);
    width: 100%;
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3.125rem;
    overflow: auto;
    scrollbar-width: none; }
    .modal-window__container::-webkit-scrollbar {
      display: none; }
    .modal-window__container::-webkit-scrollbar-track {
      display: none; }
    .modal-window__container::-webkit-scrollbar-thumb {
      display: none; }
    @media screen and (min-width: 160.0625rem) {
      .modal-window__container {
        padding: 4.29688vw var(--container-offset);
        gap: 1.95312vw; } }
    @media screen and (max-width: 106.25rem) {
      .modal-window__container {
        gap: 2.5rem; } }
    @media screen and (max-width: 85rem) {
      .modal-window__container {
        padding: 4.375rem var(--container-offset);
        gap: 1.875rem; } }
    @media screen and (max-width: 39.375rem) {
      .modal-window__container {
        padding: 3.75rem var(--container-offset);
        gap: 1.25rem; } }
  .modal-window__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.9375rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-window__head {
        gap: 0.58594vw; } }
    @media screen and (max-width: 85rem) {
      .modal-window__head {
        gap: 0.625rem; } }
  .modal-window__title {
    color: var(--text-dark-primary); }
  .modal-window__subtitle {
    color: var(--text-dark-primary);
    font: var(--font-body-S); }
  .modal-window .contacts-info {
    background-color: transparent; }
    .modal-window .contacts-info__list {
      display: flex;
      flex-direction: column;
      gap: 1.875rem; }
      @media screen and (min-width: 160.0625rem) {
        .modal-window .contacts-info__list {
          gap: 1.17188vw; } }
      @media screen and (max-width: 106.25rem) {
        .modal-window .contacts-info__list {
          gap: 1.5625rem; } }
      @media screen and (max-width: 85rem) {
        .modal-window .contacts-info__list {
          gap: 1.25rem; } }
    .modal-window .contacts-info__row {
      display: flex;
      flex-direction: column; }

.modal-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.875rem; }
  @media screen and (min-width: 160.0625rem) {
    .modal-form {
      gap: 1.17188vw; } }
  @media screen and (max-width: 85rem) {
    .modal-form {
      gap: 1.5625rem; } }
  .modal-form__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-form__list {
        gap: 0.39062vw; } }
  .modal-form__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-form__wrapper {
        gap: 1.5625vw; } }
    @media screen and (max-width: 106.25rem) {
      .modal-form__wrapper {
        gap: 1.875rem; } }
    @media screen and (max-width: 85rem) {
      .modal-form__wrapper {
        gap: 1.5625rem; } }
    @media screen and (max-width: 39.375rem) {
      .modal-form__wrapper {
        gap: 1.25rem; } }
  .modal-form__submit {
    width: fit-content;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 39.375rem) {
      .modal-form__submit {
        width: 100%; } }
    .modal-form__submit .button {
      width: fit-content; }
      @media screen and (max-width: 39.375rem) {
        .modal-form__submit .button {
          width: 100%; } }
  .modal-form__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.875rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-form__content {
        gap: 1.17188vw; } }
    @media screen and (max-width: 106.25rem) {
      .modal-form__content {
        gap: 1.25rem; } }
    @media screen and (max-width: 85rem) {
      .modal-form__content {
        gap: 0.9375rem; } }
  .modal-form__policy {
    color: var(--text-dark-primary);
    font: var(--font-body-XS); }
    .modal-form__policy a {
      color: var(--accent-light-primary);
      text-decoration: none;
      transition: color var(--animation-timing) var(--cubic-bezier); }
      @media (any-hover: hover) {
        .modal-form__policy a:hover {
          color: var(--accent-light-secondary); } }
  .modal-form__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-form__input {
        gap: 0.78125vw; } }
    @media screen and (max-width: 85rem) {
      .modal-form__input {
        gap: 0.625rem; } }
    .modal-form__input .text-input {
      width: 100%; }
      .modal-form__input .text-input__input {
        width: 100%; }
      .modal-form__input .text-input textarea.text-input__input {
        height: 6.25rem; }
        @media screen and (min-width: 160.0625rem) {
          .modal-form__input .text-input textarea.text-input__input {
            height: 3.90625vw; } }
  .modal-form__rating {
    gap: 0.9375rem;
    align-items: flex-start; }
    @media screen and (min-width: 160.0625rem) {
      .modal-form__rating {
        gap: 0.58594vw; } }
    .modal-form__rating .text {
      color: var(--text-dark-tertiary);
      font: var(--font-body-S); }
    .modal-form__rating .rating {
      width: 7.75rem;
      height: 1.5rem;
      cursor: pointer; }
      @media screen and (min-width: 160.0625rem) {
        .modal-form__rating .rating {
          width: 4.84375vw;
          height: 0.9375vw; } }
      @media screen and (max-width: 106.25rem) {
        .modal-form__rating .rating {
          width: 7.125rem;
          height: 1.375rem; } }

.popup-cart {
  --popup-cart-padding: 2.5rem;
  position: absolute;
  top: 100%;
  right: var(--container-offset);
  z-index: 5;
  margin-top: -0.625rem;
  width: 100%;
  max-width: 38.125rem;
  border-radius: 0 0 var(--radius-common) var(--radius-common);
  background: var(--bg-white);
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.06);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--animation-timing) var(--cubic-bezier), visibility var(--animation-timing) var(--cubic-bezier), margin-top var(--animation-timing) var(--cubic-bezier); }
  @media screen and (min-width: 160.0625rem) {
    .popup-cart {
      --popup-cart-padding: 1.5625vw;
      margin-top: -0.39062vw;
      max-width: 23.82812vw;
      box-shadow: 0 0.625vw 0.9375vw 0 rgba(0, 0, 0, 0.06); } }
  @media screen and (max-width: 106.25rem) {
    .popup-cart {
      --popup-cart-padding: 1.875rem;
      max-width: 33.75rem; } }
  @media screen and (max-width: 85rem) {
    .popup-cart {
      --popup-cart-padding: 1.25rem;
      max-width: 31.25rem; } }
  @media screen and (max-width: 39.375rem) {
    .popup-cart {
      top: 2.0625rem;
      right: 0;
      max-width: unset; } }
  .popup-cart_active {
    margin-top: 0;
    opacity: 1;
    visibility: visible; }
  .popup-cart__wrapper {
    width: 100%;
    max-height: calc(100vh - 6.75rem);
    overflow: auto;
    scrollbar-width: none; }
    .popup-cart__wrapper::-webkit-scrollbar {
      display: none; }
    .popup-cart__wrapper::-webkit-scrollbar-track {
      display: none; }
    .popup-cart__wrapper::-webkit-scrollbar-thumb {
      display: none; }
    @media screen and (min-width: 160.0625rem) {
      .popup-cart__wrapper {
        max-height: calc(100vh - 4.21875vw); } }
    @media screen and (max-width: 106.25rem) {
      .popup-cart__wrapper {
        max-height: calc(100vh - 6.125rem); } }
    @media screen and (max-width: 85rem) {
      .popup-cart__wrapper {
        max-height: calc(100vh - 4.0625rem); } }
    @media screen and (max-width: 39.375rem) {
      .popup-cart__wrapper {
        max-height: calc(100vh - 2.0625rem); }
        .popup-cart__wrapper::before {
          content: "";
          position: absolute;
          top: -2.0625rem;
          left: 0;
          width: 100%;
          height: 100vh;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0.2); } }
  .popup-cart__top {
    position: relative;
    z-index: 1;
    padding: var(--popup-cart-padding);
    padding-bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.5625rem;
    background-color: var(--bg-white); }
    @media screen and (min-width: 160.0625rem) {
      .popup-cart__top {
        gap: 0.97656vw; } }
  .popup-cart__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .popup-cart__head {
        gap: 0.39062vw; } }
    @media screen and (max-width: 39.375rem) {
      .popup-cart__head {
        gap: 0.3125rem; } }
  .popup-cart__title {
    color: var(--text-dark-primary); }
  .popup-cart__subtitle {
    color: var(--text-dark-primary);
    font: var(--font-body-M); }
  .popup-cart__list {
    padding-right: 1.25rem;
    width: 100%;
    height: auto;
    max-height: 20.875rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.25rem;
    overflow: auto; }
    .popup-cart__list::-webkit-scrollbar {
      width: 0.8125rem; }
      @media screen and (min-width: 160.0625rem) {
        .popup-cart__list::-webkit-scrollbar {
          width: 0.50781vw; } }
    .popup-cart__list::-webkit-scrollbar-track {
      background: var(--bg-white); }
    .popup-cart__list::-webkit-scrollbar-thumb {
      background-color: var(--stroke-dark-secondary);
      border-radius: 0;
      border: 4px solid var(--bg-white);
      cursor: pointer; }
      @media screen and (min-width: 160.0625rem) {
        .popup-cart__list::-webkit-scrollbar-thumb {
          border-radius: 39.02344vw;
          border-width: 0.15625vw; } }
      @media (any-hover: hover) {
        .popup-cart__list::-webkit-scrollbar-thumb:hover {
          background-color: var(--accent-light-primary); } }
    @media screen and (min-width: 160.0625rem) {
      .popup-cart__list {
        padding-right: 0.78125vw;
        max-height: 13.04688vw;
        gap: 0.78125vw; } }
    @media screen and (max-width: 106.25rem) {
      .popup-cart__list {
        max-height: 21.4375rem; } }
    @media screen and (max-width: 85rem) {
      .popup-cart__list {
        max-height: 19.75rem; } }
    @media screen and (max-width: 39.375rem) {
      .popup-cart__list {
        padding-right: 0.625rem;
        max-height: unset; } }
  .popup-cart__bottom {
    padding: var(--popup-cart-padding);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.25rem;
    border-top: solid 1px var(--stroke-dark-secondary); }
    @media screen and (min-width: 160.0625rem) {
      .popup-cart__bottom {
        gap: 0.78125vw; } }
    @media screen and (max-width: 39.375rem) {
      .popup-cart__bottom {
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 2;
        padding: 0.9375rem var(--popup-cart-padding);
        gap: 0.625rem;
        background-color: var(--bg-white); } }
  .popup-cart__price {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .popup-cart__price {
        gap: 0.39062vw; } }
    @media screen and (max-width: 39.375rem) {
      .popup-cart__price {
        gap: 0; } }
  .popup-cart__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    align-self: stretch;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .popup-cart__row {
        gap: 0.39062vw; } }
    .popup-cart__row:last-child .popup-cart__total {
      font-weight: 500; }
  .popup-cart__text {
    color: var(--text-dark-secondary);
    font: var(--font-body-XS); }
    .popup-cart__text::after {
      content: ":"; }
  .popup-cart__total {
    color: var(--text-dark-primary); }
  .popup-cart__button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .popup-cart__button {
        gap: 0.39062vw; } }
    @media screen and (max-width: 39.375rem) {
      .popup-cart__button {
        flex-direction: column; } }
    .popup-cart__button .button {
      width: 100%;
      flex-grow: 1; }
  .popup-cart__quick-button {
    width: 100%; }
  .popup-cart__close {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 2;
    width: 1.125rem;
    height: 1.125rem; }
    @media screen and (min-width: 160.0625rem) {
      .popup-cart__close {
        top: 0.78125vw;
        right: 0.78125vw;
        width: 0.70312vw;
        height: 0.70312vw; } }
    @media screen and (max-width: 39.375rem) {
      .popup-cart__close {
        width: 1rem;
        height: 1rem; } }
    @media (any-hover: hover) {
      .popup-cart__close:hover .button__icon {
        fill: var(--accent-light-secondary); } }
    .popup-cart__close .button__icon {
      width: 100%;
      height: 100%;
      fill: var(--text-dark-primary); }

.popup-item {
  position: relative;
  padding-bottom: 1.25rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1.25rem;
  border-bottom: solid 1px var(--stroke-dark-secondary); }
  @media screen and (min-width: 160.0625rem) {
    .popup-item {
      padding-bottom: 0.78125vw;
      gap: 0.78125vw;
      border-bottom-width: 0.03906vw; } }
  @media screen and (max-width: 39.375rem) {
    .popup-item {
      padding-bottom: 0.9375rem;
      gap: 0.75rem;
      align-items: start; } }
  @media (any-hover: hover) {
    .popup-item:hover .popup-item__image img,
    .popup-item:hover .popup-item__image picture {
      transform: scale(1.2); } }
  .popup-item:last-child {
    border-bottom: none; }
  .popup-item__image {
    width: 4.5rem;
    height: 4.5rem;
    display: block;
    flex-shrink: 0;
    overflow: hidden;
    pointer-events: none;
    user-select: none; }
    @media screen and (min-width: 160.0625rem) {
      .popup-item__image {
        width: 2.8125vw;
        height: 2.8125vw; } }
    @media screen and (max-width: 39.375rem) {
      .popup-item__image {
        width: 2.75rem;
        height: 2.75rem; } }
    .popup-item__image img,
    .popup-item__image picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: transform var(--animation-timing-long) var(--cubic-bezier); }
  .popup-item__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem; }
    @media screen and (min-width: 160.0625rem) {
      .popup-item__wrapper {
        gap: 0.46875vw; } }
    @media screen and (max-width: 39.375rem) {
      .popup-item__wrapper {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.3125rem; } }
  .popup-item__menu {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .popup-item__menu {
        gap: 0.78125vw; } }
    @media screen and (max-width: 39.375rem) {
      .popup-item__menu {
        gap: 0.625rem; } }
  .popup-item__name {
    width: 100%;
    max-width: 16.5rem;
    display: -webkit-box;
    color: var(--text-dark-primary);
    font: 400 1.25rem/130% var(--font-primary);
    text-decoration: none;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .popup-item__name {
        max-width: 10.3125vw;
        font: 400 0.78125vw/130% var(--font-primary); } }
    @media screen and (max-width: 106.25rem) {
      .popup-item__name {
        max-width: 13.75rem;
        font: var(--font-body-M); } }
    @media screen and (max-width: 39.375rem) {
      .popup-item__name {
        padding-right: 1.625rem;
        -webkit-line-clamp: 2; } }
    @media (any-hover: hover) {
      .popup-item__name:hover {
        color: var(--accent-light-primary); } }
    .popup-item__name::before {
      content: "";
      position: absolute;
      top: -0rem;
      left: -0rem;
      width: calc(100% + 0rem * 2);
      height: calc(100% + 0rem * 2);
      background-color: transparent;
      opacity: 0;
      z-index: 0; }
      @media screen and (min-width: 160.0625rem) {
        .popup-item__name::before {
          top: 0vw;
          left: 0vw;
          width: calc(100% + 0vw * 2);
          height: calc(100% + 0vw * 2); } }
  .popup-item__total, .popup-item__factor {
    flex-shrink: 0;
    color: var(--text-dark-primary);
    font: var(--font-body-XS);
    white-space: nowrap; }
  .popup-item__factor::before {
    content: "X"; }
  .popup-item__remove {
    position: relative;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0; }
    @media screen and (min-width: 160.0625rem) {
      .popup-item__remove {
        width: 0.625vw;
        height: 0.625vw; } }
    @media screen and (max-width: 39.375rem) {
      .popup-item__remove {
        position: absolute;
        top: 0;
        right: 0;
        width: 0.75rem;
        height: 0.75rem; } }
    @media (any-hover: hover) {
      .popup-item__remove:hover .button__icon {
        fill: var(--system-states-error); } }
    .popup-item__remove .button__icon {
      width: 100%;
      height: 100%;
      fill: var(--text-dark-primary); }

.popup-added {
  --popup-added-padding: 2.5rem;
  position: absolute;
  top: 100%;
  right: var(--container-offset);
  margin-top: -0.625rem;
  width: 100%;
  max-width: 38.125rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--animation-timing) var(--cubic-bezier), visibility var(--animation-timing) var(--cubic-bezier), margin-top var(--animation-timing) var(--cubic-bezier); }
  @media screen and (min-width: 160.0625rem) {
    .popup-added {
      --popup-added-padding: 1.5625vw;
      max-width: 23.82812vw; } }
  @media screen and (max-width: 106.25rem) {
    .popup-added {
      --popup-added-padding: 1.25rem;
      max-width: 33.75rem; } }
  @media screen and (max-width: 85rem) {
    .popup-added {
      --popup-added-padding: 1.25rem;
      max-width: 31.25rem; } }
  @media screen and (max-width: 39.375rem) {
    .popup-added {
      margin-top: 0;
      top: 0;
      right: 0;
      max-width: unset;
      height: 100vh;
      justify-content: end; }
      .popup-added::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer; } }
  .popup-added_active {
    margin-top: 0;
    opacity: 1;
    visibility: visible; }
    @media screen and (max-width: 39.375rem) {
      .popup-added_active .popup-added__item {
        transform: translateY(0); } }
  .popup-added__item {
    position: relative;
    z-index: 2;
    padding: var(--popup-added-padding);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.5625rem;
    border-radius: var(--radius-common);
    background-color: var(--bg-white);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06); }
    @media screen and (min-width: 160.0625rem) {
      .popup-added__item {
        gap: 0.97656vw; } }
    @media screen and (max-width: 39.375rem) {
      .popup-added__item {
        gap: 1.25rem;
        transform: translateY(100%);
        transition: transform var(--animation-timing) var(--cubic-bezier); } }
  .popup-added__button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .popup-added__button {
        gap: 0.39062vw; } }
    @media screen and (max-width: 39.375rem) {
      .popup-added__button {
        flex-direction: column; } }
    .popup-added__button .button {
      width: 100%;
      flex-grow: 1; }
  .popup-added__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .popup-added__content {
        gap: 0.39062vw; } }
  .popup-added__description {
    color: var(--text-dark-primary);
    font: var(--font-body-XS); }
  .popup-added__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0; }
  .popup-added__title {
    font: var(--text-dark-primary);
    font: var(--font-body-M); }
  .popup-added__count {
    color: var(--text-dark-tertiary);
    font: var(--font-body-M); }
