.tag {
  margin: 0;

  padding: rem(4) rem(10);
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--text-light-primary);
  font-size: rem(14);
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: rem(1.4);
  text-transform: uppercase;

  background: none;
  background-color: var(--background-color);
  border: none;

  border-radius: var(--radius-primary);

  user-select: none;

  @include mediaBigDesktop {
    padding: rem(4) rem(10);

    font-size: big(14);
    letter-spacing: big(1.4);
  }

  &.medium {
    @include mediumTag {}
  }

  &.small {
    @include smallTag {}
  }

  &.xs {
    @include XSTag {}
  }

}
