.text {
  padding: 0;
  margin: 0;

  &_body {
    &_large {
      font: var(--font-body-L);
    }

    &_medium {
      font: var(--font-body-M);
    }

    &_small {
      font: var(--font-body-S);
    }

    &_xs {
      font: var(--font-body-XS);
    }
  }

  &_caption {
    &_large {
      font: var(--font-caption-L);
      text-transform: uppercase;
      letter-spacing: rem(2.2);

      @include mediaBigDesktop {
        letter-spacing: big(2.2);
      }

      @include mediaLaptop {
        letter-spacing: rem(1.8);
      }

      @include mediaTablet {
        letter-spacing: rem(1.6);
      }
    }

    &_medium {
      font: var(--font-caption-M);
      text-transform: uppercase;
      letter-spacing: rem(1.8);

      @include mediaBigDesktop {
        letter-spacing: big(1.8);
      }

      @include mediaLaptop {
        letter-spacing: rem(1.6);
      }

      @include mediaTablet {
        letter-spacing: rem(1.4);
      }
    }

    &_small {
      font: var(--font-caption-S);
      text-transform: uppercase;
      letter-spacing: rem(1.6);

      @include mediaBigDesktop {
        letter-spacing: big(1.6);
      }

      @include mediaLaptop {
        letter-spacing: rem(1.4);
      }

      @include mediaTablet {
        letter-spacing: rem(1.2);
      }
    }
  }

  &_semibold {
    font-weight: 500;
  }

  &_card {
    &_large {
      font: var(--font-card-title-L);
      text-transform: uppercase;
      letter-spacing: rem(0.78);

      @include mediaBigDesktop {
        letter-spacing: big(0.78);
      }

      @include mediaLaptop {
        letter-spacing: rem(0.66);
      }

      @include mediaTablet {
        letter-spacing: rem(0.6);
      }

      @include mediaMobile {
        letter-spacing: rem(0.54);
      }
    }

    &_medium {
      font: var(--font-card-title-M);
    }
  }

  &_photo {
    color: var(--text-dark-tertiary);
    font: var(--font-photo);
    font-style: italic;
    letter-spacing: rem(0.16);

    @include mediaBigDesktop {
      letter-spacing: big(0.16);
    }

    @include mediaTablet {
      letter-spacing: rem(0.14);
    }

    @include mediaMobile {
      letter-spacing: rem(0.12);
    }
  }
}

.h1,
.article h1 {
  margin: 0;
  padding: 0;

  font: var(--font-h1);
  letter-spacing: rem(1.68);
  text-transform: uppercase;

  @include mediaBigDesktop {
    letter-spacing: big(1.68);
  }

  @include mediaLaptop {
    letter-spacing: rem(1.38);
  }

  @include mediaTablet {
    letter-spacing: rem(1.08);
  }

  @include mediaMobile {
    letter-spacing: rem(0.84);
  }
}

.h1-card {
  margin: 0;

  padding: 0;

  font: var(--font-h1-card);
}

.h2,
.article h2 {
  margin: 0;
  padding: 0;

  font: var(--font-h2);
  letter-spacing: rem(1.38);
  text-transform: uppercase;

  @include mediaBigDesktop {
    letter-spacing: big(1.38);
  }

  @include mediaLaptop {
    letter-spacing: rem(1.08);
  }

  @include mediaTablet {
    letter-spacing: rem(0.84);
  }

  @include mediaMobile {
    letter-spacing: rem(0.72);
  }
}

.h3,
.article h3 {
  margin: 0;
  padding: 0;

  font: var(--font-h3);
}

.h4,
.article h4 {
  margin: 0;
  padding: 0;

  font: var(--font-h4);
  letter-spacing: rem(0.78);
  text-transform: uppercase;

  @include mediaBigDesktop {
    letter-spacing: big(0.78);
  }

  @include mediaLaptop {
    letter-spacing: rem(0.66);
  }

  @include mediaTablet {
    letter-spacing: rem(0.6);
  }

  @include mediaMobile {
    letter-spacing: rem(0.54);
  }
}

.h5,
.article h5,
.article h6 {
  margin: 0;
  padding: 0;

  font: var(--font-h5);
}

.cta-title {
  margin: 0;

  padding: 0;
  
  font: var(--font-cta-title);
  text-transform: uppercase;
  letter-spacing: 1.38px;

  @include mediaBigDesktop {
    letter-spacing: big(1.38);
  }

  @include mediaLaptop {
    letter-spacing: 1.08px;
  }

  @include mediaTablet {
    letter-spacing: 0.84px;
  }

  @include mediaMobile {
    letter-spacing: 0.72px;
  }
}

.accent-color {
  color: var(--accent-light-primary);
}
