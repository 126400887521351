ul.list,
ol.list {
  font: var(--font-body-S);
}

ul.list,
ol.list,
.article ul,
.article ol {
  margin: 0;
  padding: 0;
  list-style: none;

  color: var(--text-dark-secondary);
  
  display: flex;
  flex-direction: column;
  gap: rem(10);
    
  @include mediaBigDesktop {
    gap: big(10);
  }
    
  @include mediaTablet {
    gap: rem(5);
  }

  & li {
    padding-left: rem(15);

    display: block;

    @include mediaBigDesktop {
      padding-left: big(15);
    }

    @include mediaLaptop {
      padding-left: rem(10);
    }

    &::before {
      margin-right: rem(15);

      @include mediaBigDesktop {
        margin-right: big(15);
      }

      @include mediaLaptop {
        margin-right: rem(10);
      }
    }

    & b,
    & strong {
      color: var(--text-dark-primary);
      font-weight: 600;
    }
  }

  & ol,
  & ul {
    padding: 0;
  }
}

ul.list,
.article ul {
  & li {
    padding-left: rem(35);

    text-indent: rem(-20);

    @include mediaBigDesktop {
      padding-left: big(35);

      text-indent: big(-20);
    }

    @include mediaLaptop {
      padding-left: rem(25);

      text-indent: rem(-15);
    }

    &::before {
      content: "";
      position: relative;
      bottom: rem(4);

      width: rem(5);
      height: rem(5);
      display: inline-block;

      background-color: var(--accent-light-primary);
      border-radius: rem(999);

      @include mediaBigDesktop {
        bottom: big(4);

        width: big(5);
        height: big(5);

        border-radius: big(999);
      }

      @include mediaLaptop {
        bottom: rem(3);
      }

      @include mediaMobile {
        bottom: rem(2);
      }
    }
  }
}

ol.list,
.article ol {
  list-style-type: none;
  counter-reset: num;

  & li {
    padding-left: rem(34);

    text-indent: rem(-30);

    @include mediaBigDesktop {
      padding-left: big(34);

      text-indent: big(-30);
    }

    @include mediaLaptop {
      padding-left: rem(25);

      text-indent: rem(-20);
    }

    &::before {
      margin-right: rem(5);

      width: rem(25);
      height: auto;
      display: inline-block;

      color: var(--accent-light-primary);
      text-indent: 0;

      content: counters(num, ".") ".";
      counter-increment: num;

      @include mediaBigDesktop {
        margin-right: big(5);

        width: big(25);
      }

      @include mediaTablet {
        width: rem(15);
      }
    }
  }

  & ol {
    margin: 0;
    margin-top: rem(10);
    padding: 0;
    list-style-type: none;
    counter-reset: num;
    list-style: none;

    color: var(--text-dark-secondary);
  
    display: flex;
    flex-direction: column;
    gap: rem(10);
    
    @include mediaBigDesktop {
      margin-top: big(10);
      gap: big(10);
    }
    
    @include mediaTablet {
      margin-top: rem(5);
      gap: rem(5);
    }

    & li {
      &::before {
        content: counters(num, ".") ".";
        counter-increment: num;
        width: auto;
      }
    }
  }
}
