.price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: rem(15);

  @include mediaBigDesktop {
    gap: big(15);
  }

  &__old {
    color: var(--text-dark-tertiary);
    font-size: rem(18);
    font-weight: 400;
    line-height: 1.5;
    text-decoration-line: strikethrough;
    white-space: nowrap;

    @include mediaBigDesktop {
      font-size: big(18);
    }

    @include mediaLaptop {
      font-size: rem(16);
    }

    @include mediaTablet {
      font-size: rem(14);
    }
  }

  &__sum {
    color: var(--text-dark-primary);
    font: var(--font-body-L);
    white-space: nowrap;
  }
}
