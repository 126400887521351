:root {
	// .container
	--content-width: #{rem(2400)};
	--container-offset: #{rem(80)};
	--container-width: calc(var(--content-width) + (var(--container-offset) * 2));

	@include mediaBigDesktop {
		--content-width: #{big(2400)};
		--container-offset: #{big(80)};
	}

	@include mediaLaptop {
		--container-offset: #{rem(60)};
	}

	@include mediaTablet {
		--container-offset: #{rem(45)};
	}

	@include mediaMobile {
		--container-offset: #{rem(20)};
	}

	// .container-secondary
	--content-secondary-width: #{rem(2100)};
	--container-secondary-offset: #{rem(230)};
	--container-secondary-width: calc(var(--content-secondary-width) + (var(--container-secondary-offset) * 2));

	@include mediaBigDesktop {
		--content-secondary-width: #{big(2100)};
		--container-secondary-offset: #{big(230)};
	}

	@include mediaLaptop {
		--container-secondary-offset: #{rem(163)};
	}

	@include mediaTablet {
		--container-secondary-offset: #{rem(45)};
	}

	@include mediaMobile {
		--container-secondary-offset: #{rem(20)};
	}

	// .container-thirty
	--content-thirty-width: #{rem(960)};
	--container-thirty-offset: #{rem(480)};
	--container-thirty-width: calc(var(--content-thirty-width) + (var(--container-thirty-offset) * 2));

	@include mediaBigDesktop {
		--content-thirty-width: #{big(960)};
		--container-thirty-offset: #{big(480)};
	}

	@include mediaLaptop {
		--container-thirty-offset: #{rem(355)};
	}

	@include mediaTablet {
		--container-thirty-offset: #{rem(45)};
	}

	@include mediaMobile {
		--container-thirty-offset: #{rem(20)};
	}

	// Fonts
	--font-primary: "Jost", sans-serif;
	--font-h1: 400 #{rem(56)}/125% var(--font-primary);
	--font-h2: 400 #{rem(46)}/130% var(--font-primary);
	--font-h3: 500 #{rem(32)}/140% var(--font-primary);
	--font-h4: 500 #{rem(26)}/140% var(--font-primary);
	--font-h5: 500 #{rem(20)}/150% var(--font-primary);
	--font-body-L: 400 #{rem(24)}/150% var(--font-primary);
	--font-body-M: 400 #{rem(20)}/150% var(--font-primary);
	--font-body-S: 400 #{rem(20)}/150% var(--font-primary);
	--font-body-XS: 400 #{rem(16)}/150% var(--font-primary);
	--font-caption-L: 400 #{rem(22)}/150% var(--font-primary);
	--font-caption-M: 400 #{rem(18)}/150% var(--font-primary);
	--font-caption-S: 400 #{rem(16)}/150% var(--font-primary);
	--font-input-S: 400 #{rem(14)}/150% var(--font-primary);

	--font-h1-card: 500 #{rem(32)}/140% var(--font-primary);
	--font-card-title-L: 500 #{rem(26)}/140% var(--font-primary);
	--font-card-title-M: 500 #{rem(20)}/150% var(--font-primary);
	--font-cta-title: 400 #{rem(46)}/130% var(--font-primary);
	--font-photo: 400 #{rem(16)}/150% var(--font-primary);
	--font-price: 500 #{rem(32)}/140% var(--font-primary);

	@include mediaBigDesktop {
		--font-h1: 400 #{big(56)}/125% var(--font-primary);
		--font-h2: 400 #{big(46)}/130% var(--font-primary);
		--font-h3: 500 #{big(32)}/140% var(--font-primary);
		--font-h4: 500 #{big(26)}/140% var(--font-primary);
		--font-h5: 500 #{big(20)}/150% var(--font-primary);
		--font-body-L: 400 #{big(24)}/150% var(--font-primary);
		--font-body-M: 400 #{big(20)}/150% var(--font-primary);
		--font-body-S: 400 #{big(20)}/150% var(--font-primary);
		--font-body-XS: 400 #{big(16)}/150% var(--font-primary);
		--font-caption-L: 400 #{big(22)}/150% var(--font-primary);
		--font-caption-M: 400 #{big(18)}/150% var(--font-primary);
		--font-caption-S: 400 #{big(16)}/150% var(--font-primary);
		--font-input-S: 400 #{big(14)}/150% var(--font-primary);

		--font-h1-card: 500 #{big(32)}/140% var(--font-primary);
		--font-card-title-L: 500 #{big(26)}/140% var(--font-primary);
		--font-card-title-M: 500 #{big(20)}/150% var(--font-primary);
		--font-cta-title: 400 #{big(46)}/130% var(--font-primary);
		--font-photo: 400 #{big(16)}/150% var(--font-primary);
		--font-price: 500 #{big(32)}/140% var(--font-primary);
	}

	@include mediaLaptop {
		--font-h1: 400 #{rem(46)}/130% var(--font-primary);
		--font-h2: 400 #{rem(36)}/138% var(--font-primary);
		--font-h3: 500 #{rem(28)}/140% var(--font-primary);
		--font-h4: 500 #{rem(22)}/150% var(--font-primary);
		--font-h5: 500 #{rem(18)}/140% var(--font-primary);
		--font-body-L: 400 #{rem(20)}/150% var(--font-primary);
		--font-body-M: 400 #{rem(18)}/150% var(--font-primary);
		--font-body-S: 400 #{rem(16)}/150% var(--font-primary);
		--font-body-XS: 400 #{rem(14)}/150% var(--font-primary);
		--font-caption-L: 400 #{rem(18)}/150% var(--font-primary);
		--font-caption-M: 400 #{rem(16)}/150% var(--font-primary);
		--font-caption-S: 400 #{rem(14)}/150% var(--font-primary);

		// --font-h1-card: 500 #{rem(32)}/140% var(--font-primary);
		--font-card-title-L: 500 #{rem(22)}/150% var(--font-primary);
		--font-card-title-M: 500 #{rem(18)}/140% var(--font-primary);
		--font-cta-title: 400 #{rem(36)}/130% var(--font-primary);
		--font-price: 500 #{rem(28)}/140% var(--font-primary);
	}

	@include mediaTablet {
		--font-h1: 400 #{rem(36)}/139% var(--font-primary);
		--font-h2: 400 #{rem(28)}/143% var(--font-primary);
		--font-h3: 500 #{rem(24)}/140% var(--font-primary);
		--font-h4: 500 #{rem(20)}/150% var(--font-primary);
		--font-h5: 500 #{rem(16)}/140% var(--font-primary);
		--font-body-L: 400 #{rem(18)}/150% var(--font-primary);
		--font-body-M: 400 #{rem(16)}/150% var(--font-primary);
		--font-body-S: 400 #{rem(14)}/150% var(--font-primary);
		--font-body-XS: 400 #{rem(12)}/150% var(--font-primary);
		--font-caption-L: 500 #{rem(16)}/150% var(--font-primary);
		--font-caption-M: 400 #{rem(14)}/150% var(--font-primary);
		--font-caption-S: 400 #{rem(12)}/150% var(--font-primary);
		--font-input-S: 400 #{rem(12)}/150% var(--font-primary);

		// --font-h1-card: 500 #{rem(32)}/140% var(--font-primary);
		--font-card-title-L: 500 #{rem(20)}/150% var(--font-primary);
		--font-card-title-M: 500 #{rem(16)}/140% var(--font-primary);
		--font-cta-title: 400 #{rem(24)}/130% var(--font-primary);
		--font-photo: 400 #{rem(14)}/150% var(--font-primary);
		--font-price: 500 #{rem(24)}/140% var(--font-primary);
	}

	@include mediaMobile {
		--font-h1: 400 #{rem(28)}/129% var(--font-primary);
		--font-h2: 400 #{rem(24)}/142% var(--font-primary);
		--font-h3: 500 #{rem(20)}/140% var(--font-primary);
		--font-h4: 500 #{rem(18)}/150% var(--font-primary);

		// --font-h1-card: 500 #{rem(32)}/140% var(--font-primary);
		--font-card-title-L: 500 #{rem(18)}/150% var(--font-primary);
		--font-photo: 400 #{rem(12)}/150% var(--font-primary);
		--font-price: 500 #{rem(20)}/140% var(--font-primary);
	}

	// article
	--article-width: #{rem(1074)};

	@include mediaBigDesktop {
		--article-width: #{big(1074)};
	}

	@include mediaLaptop {
		--article-width: #{rem(820)};
	}

	// shadow
	--shadow-large: 0px 0px 1px 0px rgba(0, 0, 0, 0.04),
	0px 0px 8px 0px rgba(0, 0, 0, 0.04),
	0px 10px 24px 0px rgba(0, 0, 0, 0.04),
	0px 15px 32px 0px rgba(0, 0, 0, 0.04);
	--shadow-small: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
	0px 0px 4px 0px rgba(0, 0, 0, 0.04);

	--cubic-bezier: cubic-bezier(.25, .1, .25, 1);
	--animation-timing-small: 0.2s;
	--animation-timing: 0.5s;
	--animation-timing-medium: 0.7s;
	--animation-timing-long: 1s;

	// section
	--section-padding: #{rem(110)};
	--section-gap: #{rem(60)};

	@include mediaDesktop {
		--section-padding: #{rem(110)};
		--section-gap: #{rem(80)};
	}

	@include mediaBigDesktop {
		--section-padding: #{big(110)};
		--section-gap: #{big(80)};
	}

	@include mediaLaptop {
		--section-padding: #{rem(90)};
		--section-gap: #{rem(50)};
	}

	@include mediaTablet {
		--section-padding: #{rem(60)};
		--section-gap: #{rem(40)};
	}

	@include mediaMobile {
		--section-padding: #{rem(50)};
		--section-gap: #{rem(30)};
	}

	// border-radius
	--radius-common: #{rem(0)};
	--radius-secondary: #{rem(4)};
	--radius-infinity: #{rem(1600)};

	@include mediaBigDesktop {
		--radius-common: #{big(0)};
		--radius-secondary: #{big(4)};
		--radius-infinity: #{big(1600)};
	}

	// gap
	--grid-column-gap: #{rem(40)};
	--grid-row-gap: #{rem(40)};

	@include mediaBigDesktop {
		--grid-column-gap: #{big(40)};
		--grid-row-gap: #{big(40)};
	}

	@include mediaTablet {
		--grid-column-gap: #{rem(20)};
		--grid-row-gap: #{rem(30)};
	}

	@include mediaMobile {
		--grid-column-gap: #{rem(10)};
		--grid-row-gap: #{rem(24)};
	}
}
