.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: rem(20);

  text-decoration: none;

  user-select: none;

  @include mediaBigDesktop {
    gap: big(20);
  }

  @include mediaLaptop {
    gap: rem(15);
  }

  &__text {
    //max-width: rem(145);
    display: block;

    color: var(--text-dark-secondary);
    font-size: rem(14);
    font-weight: 400;
    line-height: 1.35;
    white-space: nowrap;

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      //max-width: big(145);

      font-size: big(14);
    }

    @include mediaTablet {
      display: none;
    }
  }

  &__image {
    width: rem(165.5);
    height: auto;

    object-fit: contain;
    object-position: center;

    pointer-events: none;

    @include mediaBigDesktop {
      width: big(165.5);
    }

    @include mediaLaptop {
      width: rem(110);
    }

    @include mediaMobile {
      width: rem(90);
    }
  }
}
