@import "../../mixins";

.popup-cart {
  --popup-cart-padding: #{rem(40)};

  position: absolute;
  top: 100%;
  right: var(--container-offset);
  z-index: 5;
  margin-top: rem(-10);

  width: 100%;
  max-width: rem(610);

  border-radius: 0 0 var(--radius-common) var(--radius-common);
  background: var(--bg-white);
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.06);
  opacity: 0;
  visibility: hidden;

  transition: opacity var(--animation-timing) var(--cubic-bezier),
    visibility var(--animation-timing) var(--cubic-bezier),
    margin-top var(--animation-timing) var(--cubic-bezier);

  @include mediaBigDesktop {
    --popup-cart-padding: #{big(40)};
    margin-top: big(-10);

    max-width: big(610);

    box-shadow: 0 big(16) big(24) 0 rgba(0, 0, 0, 0.06);
  }

  @include mediaLaptop {
    --popup-cart-padding: #{rem(30)};

    max-width: rem(540);
  }

  @include mediaTablet {
    --popup-cart-padding: #{rem(20)};

    max-width: rem(500);
  }

  @include mediaMobile {
    top: rem(33);
    right: 0;

    max-width: unset;
  }

  &_active {
    margin-top: 0;

    opacity: 1;
    visibility: visible;
  }

  &__wrapper {
    width: 100%;
    max-height: calc(100vh - #{rem(108)});

    overflow: auto;

    @include scrollbarDelete();

    @include mediaBigDesktop {
      max-height: calc(100vh - #{big(108)});
    }

    @include mediaLaptop {
      max-height: calc(100vh - #{rem(98)});
    }

    @include mediaTablet {
      max-height: calc(100vh - #{rem(65)});
    }

    @include mediaMobile {
      max-height: calc(100vh - #{rem(33)});

      &::before {
        content: "";
        position: absolute;
        top: rem(-33);
        left: 0;

        width: 100%;
        height: 100vh;
        z-index: 1;

        background-color: rgba(0, 0, 0, 0.20);
      }
    }
  }

  &__top {
    position: relative;
    z-index: 1;

    padding: var(--popup-cart-padding);
    padding-bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(25);

    background-color: var(--bg-white);

    @include mediaBigDesktop {
      gap: big(25);
    }
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaMobile {
      gap: rem(5);
    }
  }

  &__title {
    color: var(--text-dark-primary);
  }

  &__subtitle {
    color: var(--text-dark-primary);
    font: var(--font-body-M);
  }

  &__list {
    padding-right: rem(20);
    width: 100%;
    height: auto;
    max-height: rem(334);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(20);

    overflow: auto;

    @include scrollbar();

    @include mediaBigDesktop {
      padding-right: big(20);
      max-height: big(334);
      gap: big(20);
    }

    @include mediaLaptop {
      max-height: rem(343);
    }

    @include mediaTablet {
      max-height: rem(316);
    }

    @include mediaMobile {
      padding-right: rem(10);
      max-height: unset;
    }
  }

  &__bottom {
    padding: var(--popup-cart-padding);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(20);

    border-top: solid 1px var(--stroke-dark-secondary);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaMobile {
      position: sticky;
      bottom: 0;
      left: 0;
      z-index: 2;

      padding: rem(15) var(--popup-cart-padding);
      gap: rem(10);

      background-color: var(--bg-white);
    }
  }

  &__price {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaMobile {
      gap: 0;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    align-self: stretch;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    &:last-child {
      & .popup-cart {
        &__total {
          font-weight: 500;
        }
      }
    }
  }

  &__text {
    color: var(--text-dark-secondary);
    font: var(--font-body-XS);

    &::after {
      content: ":";
    }
  }

  &__total {
    color: var(--text-dark-primary);
  }

  &__button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaMobile {
      flex-direction: column;
    }

    & .button {
      width: 100%;
      flex-grow: 1;
    }
  }
  
  &__quick-button {
    width: 100%;
  }

  &__close {
    position: absolute;
    top: rem(20);
    right: rem(20);
    z-index: 2;

    width: rem(18);
    height: rem(18);

    @include mediaBigDesktop {
      top: big(20);
      right: big(20);

      width: big(18);
      height: big(18);
    }

    @include mediaMobile {
      width: rem(16);
      height: rem(16);
    }

    @include hover {
      & .button {
        &__icon {
          fill: var(--accent-light-secondary);
        }
      }
    }

    & .button {
      &__icon {
        width: 100%;
        height: 100%;

        fill: var(--text-dark-primary);
      }
    }
  }
}

@import "popup-item";
