// fancybox
.fan-c-open {
  animation: fan-c-open var(--animation-timing) var(--cubic-bezier);
}

@keyframes fan-c-open {
  0%{
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.fan-c-close {
  animation: fan-c-close var(--animation-timing) var(--cubic-bezier);
}

@keyframes fan-c-close {
  0%{
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
