.listing-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(rem(330), 1fr));
  column-gap: var(--grid-column-gap);
  row-gap: var(--grid-row-gap);

  @include mediaDesktop {
    grid-template-columns: repeat(auto-fill, minmax(rem(365), 1fr));
  }

  @include mediaBigDesktop {
    grid-template-columns: repeat(auto-fill, minmax(big(365), 1fr));
  }

  @include mediaLaptop {
    grid-template-columns: repeat(auto-fill, minmax(rem(300), 1fr));
  }

  @include mediaTablet {
    grid-template-columns: repeat(auto-fill, minmax(rem(280), 1fr));
  }

  @include mediaMobile {
    grid-template-columns: repeat(auto-fill, minmax(rem(260), 1fr));
  }

  & .product-card {
    width: 100%;
  }
}
