.checkbox {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: rem(10);

  cursor: pointer;
  user-select: none;

  @include mediaBigDesktop {
    gap: big(10);
  }

  @include hover {
    & .checkbox {
      &__item {
        border-color: var(--accent-light-primary);
      }
    }
  }

  &::before {
    @include pseudo(8) {}
  }

  &__input {
    &:checked {
      & ~ .checkbox {
        &__item {
          background-color: var(--accent-light-primary);
          border-color: var(--accent-light-primary);
        }
      }
    }
  }

  &__item {
    position: relative;
    width: rem(30);
    height: rem(30);
    display: block;
    flex-shrink: 0;

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark-secondary);
    border-radius: var(--radius-primary);

    transition: border-color var(--animation-timing) var(--cubic-bezier),
      background-color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(30);
      height: big(30);

      border-width: big(1);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;

      width: rem(24);
      height: rem(24);

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M9.09368 18C8.86231 18.0001 8.6332 17.9532 8.41947 17.8619C8.20573 17.7706 8.01157 17.6367 7.84811 17.4679L4 13.5038L5.46839 11.9911L9.09368 15.7277L18.5316 6L20 7.51275L10.3392 17.4679C10.1758 17.6367 9.98162 17.7706 9.76788 17.8619C9.55415 17.9532 9.32504 18.0001 9.09368 18Z' fill='white'/%3E%3C/svg%3E");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @include mediaBigDesktop {
        width: big(24);
        height: big(24);
      }
    }
  }

  &.medium {
    @include mediumCheckbox {}
  }

  &.small {
    @include smallCheckbox {}
  }

  & .text {
    & a {
      position: relative;
      z-index: 1;

      color: var(--accent-light-primary);
      text-decoration: none;

      transition: color var(--animation-timing) var(--cubic-bezier);

      @include hover {
        color: var(--accent-light-secondary);
      }
    }
  }
}
