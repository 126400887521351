.select-input {
  position: relative;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: rem(5);

  @include mediaBigDesktop {
    gap: big(5);
  }

  &_checkbox {
    & .select {
      &-answer {
        &__text {
          display: flex;
          align-items: center;
          gap: rem(10);

          font: var(--font-body-XS);

          transition: color var(--animation-timing) var(--cubic-bezier);

          @include mediaBigDesktop {
            gap: big(10);
          }

          @include mediaTablet {
            font: var(--font-body-S);
          }

          & .select-answer__checkbox {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(20);
            height: rem(20);

            background-color: var(--bg-white);
            border: solid 1px var(--stroke-dark-secondary);
            border-radius: var(--radius-common);

            transition: background-color var(--animation-timing) var(--cubic-bezier),
              border-color var(--animation-timing) var(--cubic-bezier);

            @include mediaBigDesktop {
              width: big(20);
              height: big(20);

              border-width: big(1);
            }

            &:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;

              display: flex;
              flex-shrink: 0;
              width: rem(16);
              height: rem(16);

              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='white'/%3E%3C/svg%3E");
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;

              @include mediaBigDesktop {
                width: big(16);
                height: big(16);
              }
            }
          }
        }

        &__input {
          &:checked {
            &~.select-answer__text {
              color: var(--accent-light-primary);

              & .select-answer__checkbox {
                background-color: var(--accent-light-primary);
                border-color: var(--accent-light-primary);
              }
            }
          }
        }
      }
    }
  }

  &__subtitle {
    color: var(--text-dark-tertiary);
  }

  &__checkbox {
    &:focus,
    &:checked {
      &~.select-current {
        border-color: var(--accent-light-primary);

        & .select-current {
          &__icon {
            transform: rotate(90deg);
          }
        }
      }

      &~.select-answer {
        margin-top: rem(10);

        opacity: 1 !important;
        visibility: visible;

        @include mediaBigDesktop {
          margin-top: big(10);
        }
      }
    }

    &:disabled {
      &~.select-current {
        background-color: var(--bg-disable);
        border-color: var(--stroke-dark-secondary);

        & .select-current {
          &__text {
            color: var(--text-disable);
          }

          &__icon {
            fill: var(--elements-gray);
          }
        }
      }
    }
  }

  &.common-input_error {
    &>.select-input__current {
      border-color: var(--system-states-error);
    }
  }
}

.select-current {
  padding: rem(12) rem(20);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: rem(15);

  background-color: var(--bg-white);
  border: solid 1px var(--stroke-dark-secondary);
  border-radius: var(--radius-common);

  transition: border-color var(--animation-timing) var(--cubic-bezier);

  cursor: pointer;

  @include mediaBigDesktop {
    padding: big(12) big(20);

    border-width: big(1);
  }

  @include mediaLaptop {
    padding: rem(10.5) rem(18);
  }

  @include mediaTablet {
    padding: rem(12) rem(18);
  }

  @include hover {
    border-color: var(--accent-light-primary);
  }

  &__icon {
    width: rem(18);
    height: rem(18);
    flex-shrink: 0;

    fill: var(--accent-dark-primary);

    transform: rotate(-90deg);

    user-select: none;

    transition: transform var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(18);
      height: big(18);
    }
  }

  &__text {
    width: 100%;
    max-width: 100%;
    display: block;

    color: var(--text-dark-primary);
    font: var(--font-body-XS);
    white-space: nowrap;
    text-overflow: ellipsis;

    transition: color var(--animation-timing) var(--cubic-bezier);

    overflow: hidden;
    user-select: none;

    &.placeholder {
      color: var(--text-dark-tertiary);
    }
  }
}

.select-answer {
  position: absolute;
  z-index: 4;
  top: 100%;
  left: 0;
  padding: rem(15);
  margin-top: rem(-10);

  width: 100%;
  height: auto;
  max-height: rem(332);
  display: flex;
  flex-direction: column;

  background-color: var(--bg-white);
  box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
  border-radius: var(--radius-common);
  overflow: auto;

  transition: margin-top var(--animation-timing) var(--cubic-bezier),
    opacity var(--animation-timing) var(--cubic-bezier),
    visibility var(--animation-timing) var(--cubic-bezier);

  opacity: 0;
  visibility: hidden;

  @include mediaBigDesktop {
    margin-top: big(-10);
    padding: big(15);

    max-height: big(332);
  }

  @include mediaTablet {
    padding: rem(10)
  }

  @include scrollbar();
  &__item {
    padding: rem(10) rem(15);
    z-index: 1;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;

    color: var(--text-dark-primary);
    text-align: start;
    font-size: rem(16);
    line-height: 1.5;
    text-decoration: none;

    background-color: var(--bg-white);
    border: none;
    border-radius: var(--radius-common);

    transition: background-color var(--animation-timing) var(--cubic-bezier),
      color var(--animation-timing) var(--cubic-bezier);

    cursor: pointer;

    @include mediaBigDesktop {
      padding: big(10) big(15);

      font-size: big(16);
    }

    @include mediaLaptop {
      padding: rem(11.5) rem(15);

      font-size: rem(14);
    }

    @include hover {
      background-color: var(--bg-light-extra);
    }

    &:focus,
    &:active {
      color: var(--accent-light-primary);

      background-color: var(--bg-white);
    }
  }
  
  &__text {
    white-space: nowrap;
  }
}
