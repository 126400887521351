.article {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(20);

  font: var(--font-body-M);

  @include mediaBigDesktop {
    gap: big(20);
  }

  @include mediaTablet {
    gap: rem(15);
  }

  @include mediaMobile {
    gap: rem(10);
  }

  // Reset all margins and paddings
  ul,
  ol,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;

    padding: 0;
  }
  
  & img {
    max-width: 100%;
    max-height: 85vh;
    
    object-fit: cover;
  }

  & p,
  & span {
    color: var(--section-text-color-secondary);
  }
  
  p {
    width: 100%;
  }

  & a {
    color: var(--accent-light-primary);
    text-decoration: none;

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include hover {
      color: var(--accent-light-secondary);
    }
  }
}
