* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.page {
  height: 100%;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  color: var(--text-dark-primary);

  @include mediaBigDesktop {
    scroll-padding: big(108);
  }

  @include mediaLaptop {
    scroll-padding: rem(65);
  }

  @include mediaMobile {
    scroll-padding: rem(56);
  }
}

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

::selection {
  background: var(--accent-light-primary);
  color: var(--text-light-primary)
}

::-moz-selection {
  background: var(--accent-light-primary);
  color: var(--text-light-primary)
}

.page__body {
  margin: 0;

  min-width: 320px;
  min-height: 100%;

  font-size: #{rem(16)};

  background-color: var(--bg-white);

  @include mediaBigDesktop {
    font-size: big(16);
  }

  @include scrollbar();
}

[data-pseudo] {
  &:after {
    content: attr(data-pseudo);
  }
}

.disable-scroll {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.wrapper {
  position: relative;
  z-index: 1;

  min-height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.footer {
  margin-top: auto;
}

.input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: var(--bg-white);

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type=number] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  visibility: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;

  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);

  & .container-secondary {
    padding: 0 calc(var(--container-secondary-offset) - var(--container-offset));
  }

  & .container-thirty {
    padding: 0 calc(var(--container-thirty-offset) - var(--container-offset));
  }
}

.container-secondary {
  margin: 0 auto;

  padding: 0 var(--container-secondary-offset);
  width: 100%;
  max-width: var(--container-secondary-width);
}

.container-thirty {
  margin: 0 auto;

  padding: 0 var(--container-thirty-offset);
  width: 100%;
  max-width: var(--container-thirty-width);
}

.reset-styles {
  padding: 0;
  margin: 0;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;

  transform: opacity var(--animation-timing) var(--cubic-bezier);
}

.elite-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  width: 100%;
  height: 100%;

  background-color: #000;
  opacity: 0;
  visibility: hidden;

  pointer-events: none;
  cursor: pointer;

  transition: opacity var(--animation-timing-medium) var(--cubic-bezier) var(--animation-timing),
    visibility var(--animation-timing-medium) var(--cubic-bezier) var(--animation-timing);

  &_show {
    opacity: 0.1;
    visibility: visible;

    pointer-events: all;
  }
}

.swiper {
  &-slide {
    height: auto;
    display: flex;
    align-items: stretch;
  }
  
  &__pagination {
    position: relative;
    top: 0;
    left: 0;
    margin: 0;

    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaTablet {
      gap: rem(10);
    }
  }

  &__bullet {
    position: relative;

    width: rem(8);
    height: rem(8);
    display: block;

    background-color: var(--text-dark-tertiary);
    border-radius: var(--radius-infinity);

    transition: background-color var(--animation-timing) var(--cubic-bezier);

    cursor: pointer;

    @include mediaBigDesktop {
      width: big(8);
      height: big(8);
    }

    @include hover {
      background-color: var(--text-dark-secondary);
    }

    &::before {
      @include pseudo(7.5) {}

      @include mediaTablet {
        @include pseudo(5) {}
      }

      @include mediaMobile {
        @include pseudo(2.5) {}
      }
    }

    &_active {
      background-color: var(--text-dark-primary) !important;
    }
  }
}

.pagination-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(60);

  @include mediaBigDesktop {
    gap: big(60);
  }

  @include mediaTablet {
    gap: rem(40);
  }

  &__pagination {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .pagination {
      width: 100%;

      @include mediaTablet {
        max-width: rem(419);
      }
    }
  }
}
