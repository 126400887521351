.popup-item {
  position: relative;

  padding-bottom: rem(20);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: rem(20);

  border-bottom: solid 1px var(--stroke-dark-secondary);

  @include mediaBigDesktop {
    padding-bottom: big(20);
    gap: big(20);

    border-bottom-width: big(1);
  }

  @include mediaMobile {
    padding-bottom: rem(15);
    gap: rem(12);

    align-items: start;
  }

  @include hover {
    & .popup-item {
      &__image {

        & img,
        & picture {
          transform: scale(1.2);
        }
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }

  &__image {
    width: rem(72);
    height: rem(72);
    display: block;
    flex-shrink: 0;

    overflow: hidden;

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      width: big(72);
      height: big(72);
    }

    @include mediaMobile {
      width: rem(44);
      height: rem(44);
    }

    & img,
    & picture {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;

      transition: transform var(--animation-timing-long) var(--cubic-bezier);
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: rem(12);

    @include mediaBigDesktop {
      gap: big(12);
    }

    @include mediaMobile {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(5);
    }
  }

  &__menu {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaMobile {
      gap: rem(10);
    }
  }

  &__name {
    width: 100%;
    max-width: rem(264);
    display: -webkit-box;

    color: var(--text-dark-primary);
    font: 400 rem(20)/130% var(--font-primary);
    text-decoration: none;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    overflow: hidden;

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      max-width: big(264);

      font: 400 big(20)/130% var(--font-primary);
    }

    @include mediaLaptop {
      max-width: rem(220);

      font: var(--font-body-M);
    }

    @include mediaMobile {
      padding-right: rem(26);

      -webkit-line-clamp: 2;
    }

    @include hover {
      color: var(--accent-light-primary);
    }

    &::before {
      @include pseudo(0) {
        z-index: 0;
      }
    }
  }

  &__total,
  &__factor {
    flex-shrink: 0;

    color: var(--text-dark-primary);
    font: var(--font-body-XS);
    white-space: nowrap;
  }

  &__factor {
    &::before {
      content: "X";
    }
  }

  &__remove {
    position: relative;
    z-index: 1;

    width: rem(16);
    height: rem(16);

    flex-shrink: 0;

    @include mediaBigDesktop {
      width: big(16);
      height: big(16);
    }

    @include mediaMobile {
      position: absolute;
      top: 0;
      right: 0;

      width: rem(12);
      height: rem(12);
    }

    @include hover {
      & .button {
        &__icon {
          fill: var(--system-states-error);
        }
      }
    }

    & .button {
      &__icon {
        width: 100%;
        height: 100%;

        fill: var(--text-dark-primary);
      }
    }
  }
}
