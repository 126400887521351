@import "../../mixins";

.popup-added {
  --popup-added-padding: #{rem(40)};

  position: absolute;
  top: 100%;
  right: var(--container-offset);
  margin-top: rem(-10);

  width: 100%;
  max-width: rem(610);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;

  opacity: 0;
  visibility: hidden;

  transition: opacity var(--animation-timing) var(--cubic-bezier),
    visibility var(--animation-timing) var(--cubic-bezier),
    margin-top var(--animation-timing) var(--cubic-bezier);

  @include mediaBigDesktop {
    --popup-added-padding: #{big(40)};

    max-width: big(610);
  }

  @include mediaLaptop {
    --popup-added-padding: #{rem(20)};

    max-width: rem(540);
  }

  @include mediaTablet {
    --popup-added-padding: #{rem(20)};

    max-width: rem(500);
  }

  @include mediaMobile {
    margin-top: 0;

    top: 0;
    right: 0;

    max-width: unset;
    height: 100vh;
    justify-content: end;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100vh;

      background-color: rgba(0, 0, 0, 0.20);

      cursor: pointer;
    }
  }

  &_active {
    margin-top: 0;

    opacity: 1;
    visibility: visible;

    @include mediaMobile {
      & .popup-added {
        &__item {
          transform: translateY(0);
        }
      }
    }
  }

  &__item {
    position: relative;
    z-index: 2;

    padding: var(--popup-added-padding);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(25);

    border-radius: var(--radius-common);
    background-color: var(--bg-white);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06);

    @include mediaBigDesktop {
      gap: big(25);
    }

    @include mediaMobile {
      gap: rem(20);

      transform: translateY(100%);

      transition: transform var(--animation-timing) var(--cubic-bezier);
    }
  }

  &__button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaMobile {
      flex-direction: column;
    }

    & .button {
      width: 100%;
      flex-grow: 1;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__description {
    color: var(--text-dark-primary);
    font: var(--font-body-XS);
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
  }

  &__title {
    font: var(--text-dark-primary);
    font: var(--font-body-M);
  }

  &__count {
    color: var(--text-dark-tertiary);
    font: var(--font-body-M);
  }
}
