.tooltip {
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_active {
    & .tooltip {
      &__text {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__button {
    position: relative;
    width: rem(16);
    height: rem(16);
    z-index: 1;

    background-color: var(--text-disable);
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM7.2 10.4V12H8.8V10.4H7.2ZM8.8 9.084C9.44294 8.89022 9.99488 8.47196 10.3553 7.90539C10.7158 7.33883 10.8607 6.66164 10.7638 5.99717C10.6669 5.3327 10.3345 4.72515 9.82721 4.28517C9.31993 3.84518 8.6715 3.60203 8 3.6C7.35268 3.59995 6.72533 3.82412 6.22463 4.23439C5.72394 4.64467 5.38081 5.2157 5.2536 5.8504L6.8232 6.1648C6.86774 5.94195 6.97465 5.73632 7.13149 5.57185C7.28833 5.40738 7.48865 5.29083 7.70914 5.23576C7.92963 5.18069 8.16122 5.18937 8.37698 5.26078C8.59273 5.33218 8.78377 5.46339 8.92787 5.63913C9.07196 5.81487 9.1632 6.02791 9.19095 6.25347C9.2187 6.47904 9.18182 6.70784 9.08462 6.91327C8.98741 7.11869 8.83387 7.29229 8.64186 7.41386C8.44985 7.53544 8.22726 7.59999 8 7.6C7.78783 7.6 7.58434 7.68429 7.43431 7.83431C7.28429 7.98434 7.2 8.18783 7.2 8.4V9.6H8.8V9.084Z' fill='%23000000'/%3E%3C/svg%3E");
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    overflow: visible;

    transition: background-color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(16);
      height: big(16);
    }

    @include hover {
      background-color: var(--accent-light-secondary);
    }

    &::before {
      @include pseudo(8) {}
    }

    &_active {
      background-color: var(--accent-light-primary);
    }
  }

  &__text {
    position: absolute;
    z-index: 2;

    padding: rem(8) rem(15);
    width: rem(400);
    max-width: rem(400);
    display: flex;

    color: var(--text-dark-primary);
    font: 400 rem(14)/150% var(--font-primary);

    background-color: var(--bg-light);
    opacity: 0;
    visibility: hidden;
    z-index: 3;

    transition: opacity var(--animation-timing) var(--cubic-bezier),
      visibility var(--animation-timing) var(--cubic-bezier);

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      padding: big(8) big(15);

      font: 400 big(14)/150% var(--font-primary);
    }
  }

  &__arrow {
    position: absolute;

    width: rem(9);
    height: rem(9);
    display: block;

    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='9' viewBox='0 0 9 9' fill='none'%3E%3Cpath d='M0 0L9 9H0V0Z' fill='%23F2F2F2'/%3E%3C/svg%3E");
    mask-size: contain;
    mask-position: 0, 0;
    mask-repeat: no-repeat;
    background-color: var(--bg-light);

    @include mediaBigDesktop {
      width: big(9);
      height: big(9);
    }
  }
}
