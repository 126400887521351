.availability {
  --availability-status-color: var(--system-states-success);
  
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: rem(7);

  color: var(--availability-status-color);
  white-space: nowrap;

  border-radius: var(--radius-common);

  user-select: none;
  overflow: hidden;

  @include mediaBigDesktop {
    gap: big(7);
  }

  &_out-stock {
    --availability-status-color: var(--system-states-error);
  }

  &_to-order {
    --availability-status-color: var(--system-states-validation);
  }

  &__icon {
    width: rem(20);
    height: rem(20);

    fill: var(--availability-status-color);

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }
  }
}
