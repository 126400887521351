.button {
  position: relative;
  padding: 0;
  margin: 0;

  width: fit-content;

  font-family: var(--font-primary);
  font-size: rem(14);
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: rem(0.7);
  text-decoration: none;
  text-transform: uppercase;

  background: transparent;
  border: none;
  border-radius: var(--radius-common);

  cursor: pointer;
  user-select: none;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;

  @include mediaBigDesktop {
    font-size: big(14);
    letter-spacing: big(0.7);
  }

  @include mediaTablet {
    font-size: rem(12);
    letter-spacing: rem(0.6);
  }

  &:disabled {
    color: var(--text-disable);

    background-color: var(--bg-disable);

    pointer-events: none;

    & .button__icon {
      fill: var(--text-disable);
    }
  }

  &_primary {
    padding: 0 rem(25);

    min-height: rem(50);
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--text-light-primary);

    background-color: var(--accent-light-primary);

    @include mediaBigDesktop {
      padding: 0 big(25);

      min-height: big(50);
    }

    @include mediaLaptop {
      min-height: rem(44);
    }

    @include mediaMobile {
      padding: 0 rem(20);
    }

    @include hover {
      &::before {
        top: unset;
        bottom: 0;

        height: 100%;
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;

      width: 100%;
      height: 0%;

      background-color: var(--accent-light-secondary);
      border-radius: 0;

      transition: height var(--animation-timing-medium) var(--cubic-bezier);
    }
  }

  &_secondary {
    padding: 0 rem(23);

    min-height: rem(50);
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--accent-light-primary);

    background: transparent;
    border: solid 1px var(--accent-light-primary);

    transition: background-color var(--animation-timing) var(--cubic-bezier),
      color var(--animation-timing) var(--cubic-bezier),
      border-color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      padding: 0 big(23);

      min-height: big(50);
    }

    @include mediaLaptop {
      min-height: rem(44);
    }

    @include mediaMobile {
      padding: 0 rem(18);
    }

    @include hover {
      color: var(--text-light-primary);

      border-color: var(--accent-light-secondary);

      &::before {
        top: unset;
        bottom: 0;

        height: 100%;
      }

      & .button__icon {
        fill: var(--bg-white);
      }
    }

    & .button {
      &__icon {
        fill: var(--accent-light-primary);
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;

      width: 100%;
      height: 0%;

      background-color: var(--accent-light-secondary);
      border-radius: 0;

      transition: height var(--animation-timing-medium) var(--cubic-bezier);
    }

    &:disabled {
      background-color: transparent;
      border: solid 1px transparent;

      @include mediaBigDesktop {
        border-width: big(1);
      }
    }
  }

  &_tertiary {
    position: relative;

    display: flex;
    gap: rem(10);

    color: var(--accent-light-primary);

    transition: color var(--animation-timing) var(--cubic-bezier);

    overflow: hidden;

    @include mediaBigDesktop {
      gap: big(10);
    }

    & .button {
      &__content {
        gap: rem(10);

        pointer-events: none;

        @include mediaBigDesktop {
          gap: big(10);
        }
      }

      &__icon {
        position: relative;
        fill: var(--accent-light-primary);
      }
    }

    @include hover {
      color: var(--accent-light-secondary);

      & .button__icon {
        fill: var(--accent-light-secondary);
      }
    }

    &:disabled {
      color: var(--text-disable);

      background-color: transparent;
    }

    &::before {
      @include pseudo(15) {}
    }
  }

  &_icon {
    width: rem(50);
    height: rem(50);
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--text-light-primary);

    border-radius: var(--radius-infinity);
    border: solid 0;
    background-color: var(--stroke-light-secondary);
    backdrop-filter: blur(#{rem(20)});

    transition: color var(--animation-timing) var(--cubic-bezier),
      border-color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(50);
      height: big(50);

      backdrop-filter: blur(#{big(20)});
    }

    @include hover {
      color: var(--accent-light-primary);

      & .button {
        &__icon {
          fill: var(--accent-light-primary);
        }

        &__content {
          color: var(--accent-light-primary);

          background-color: var(--stroke-light-secondary);
        }
      }
    }

    &:disabled {
      background-color: var(--stroke-light-secondary);

      & .button {
        &__icon {
          fill: var(--text-disable);
        }
      }
    }

    & .button {
      &__icon {
        position: relative;
        z-index: 1;

        fill: var(--text-light-primary);
      }

      &__content {
        pointer-events: none;
      }

      &__icon {
        width: rem(24);
        height: rem(24);

        @include mediaBigDesktop {
          width: big(24);
          height: big(24);
        }
      }
    }

    &.medium {
      @include mediumButtonIcon {}
    }

    &.animation {
      --before-top: 0;
      --before-right: 0;
      --before-bottom: 0;
      --before-left: 0;
      --before-size: 0;
      --before-scale: 0;

      @include hover {
        color: var(--text-light-primary);

        & .button {
          &__icon {
            fill: var(--elements-white);
          }

          &__content {
            color: var(--text-light-primary);
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        z-index: 0;
        top: var(--before-top);
        left: var(--before-left);

        width: var(--before-size);
        height: var(--before-size);
        background-color: var(--accent-light-primary);

        border-radius: rem(1600);
        transform: translate(-50%, -50%);
      }
    }

    &.light {
      color: var(--text-dark-primary);

      background-color: var(--stroke-dark-secondary);

      @include hover {
        color: var(--accent-light-primary);

        & .button {
          &__icon {
            fill: var(--accent-light-primary);
          }

          &__content {
            color: var(--accent-light-primary);

            background-color: var(--stroke-dark-secondary);
          }
        }
      }

      &:disabled {
        & .button {
          &__icon {
            fill: var(--text-disable);
          }
        }
      }

      &.animation {
        @include hover {
          color: var(--accent-light-primary);

          & .button {
            &__icon {
              fill: var(--elements-white);
            }

            &__content {
              color: var(--text-light-primary);
            }
          }
        }

        &::after {
          content: "";
          position: absolute;
          z-index: 0;
          top: var(--before-top);
          left: var(--before-left);

          width: var(--before-size);
          height: var(--before-size);
          background-color: var(--accent-light-primary);

          border-radius: rem(1600);
          transform: translate(-50%, -50%);
        }
      }

      & .button {
        &__icon {
          position: relative;
          z-index: 1;

          fill: var(--text-dark-primary);
        }

        &__content {
          color: var(--text-dark-primary);
        }
      }
    }

    &.stroke {
      background-color: transparent;

      border: solid 1px var(--stroke-light-secondary);

      &.light {
        background-color: var(--bg-white);

        border: solid 1px var(--stroke-dark-secondary);
      }
    }

    &-right {
      & .button {
        &__icon {
          order: -1;
        }
      }
    }
  }

  &_description {
    padding: rem(14) rem(20);
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(15);

    background-color: var(--bg-white);
    border: solid 1px var(--accent-light-primary);
    border-radius: var(--radius-common);

    transition: background-color var(--animation-timing-long) var(--cubic-bezier),
      border-color var(--animation-timing-long) var(--cubic-bezier);

    @include mediaBigDesktop {
      padding: big(15) big(20);
      gap: big(15);

      border-width: big(1);
    }
    
    & .button {
        &__icon {
            z-index: 1;
        }
        
        &__name {
            z-index: 1;
        }
        
        &__description {
            z-index: 1;
        }
    }

    @include hover {

      & .button {
        &__icon {
          fill: var(--text-light-primary);
        }
        &__name {
          color: var(--text-light-primary);
        }
        &__description {
          color: var(--text-light-secondary);
        }
      }
    }

    & .button {
      &__column {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
        order: 1;
      }

      &__name {
        color: var(--accent-light-primary);
        font: 600 rem(12)/150% var(--font-primary);
        letter-spacing: 0.6px;
        text-transform: uppercase;
        text-align: start;

        transition: color var(--animation-timing) var(--cubic-bezier);

        @include mediaBigDesktop {
          font: 600 big(12)/150% var(--font-primary);
        }
      }

      &__description {
        color: var(--text-dark-secondary);
        font: var(--font-body-XS);
        text-align: start;
        text-transform: none;

        transition: color var(--animation-timing) var(--cubic-bezier);
      }

      &__icon {
        width: rem(20);
        height: rem(20);

        fill: var(--accent-light-primary);

        transition: fill var(--animation-timing) var(--cubic-bezier);

        @include mediaBigDesktop {
          width: big(20);
          height: big(20);
        }
      }
    }
  }

  &__icon {
    width: rem(20);
    height: rem(20);
    flex-shrink: 0;
    order: 2;

    fill: var(--bg-white);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    pointer-events: none;

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }

    @include mediaLaptop {
      width: rem(18);
      height: rem(18);
    }

    @include mediaTablet {
      width: rem(16);
      height: rem(16);
    }
  }

  &__content {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: rem(10);

    white-space: nowrap;

    @include mediaBigDesktop {
      gap: big(10);
    }
  }
}
