.pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: rem(50);

  @include mediaBigDesktop {
    gap: big(50);
  }

  @include mediaTablet {
    gap: rem(55);
  }

  @include mediaMobile {
    gap: rem(5);
  }

  &__list {
    margin: 0;

    padding: 0;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: rem(40);

    list-style: none;

    @include mediaBigDesktop {
      gap: rem(40);
    }

    @include mediaMobile {
      width: 100%;
      gap: rem(24);
    }

    & .button {
      position: relative;

      width: fit-content;
      height: fit-content;

      overflow: visible;

      @include mediaLaptop {
        width: fit-content !important;
        height: fit-content !important;
      }


      &::before {
        @include pseudo(20) {}

        @include mediaMobile {
          @include pseudo(12) {}
        }
      }

      &__content {
        color: var(--text-dark-secondary);
        font: var(--font-caption-S);
      }
    }
  }

  &__item {
    &_active {
      & .button {
        &__content {
          font-weight: 500;
        }
      }
    }

    &.dot {
      & .button {
        pointer-events: none;
      }
    }
  }

  &__button.button {
    flex-shrink: 0;

    background-color: transparent;

    @include mediaLaptop {
      @include mediumButtonIcon {}
    }
  }
}
