.ya-map, .ya-map-multi {
  width: 100%;
  height: rem(306);
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--text-dark-secondary);

  background-color: var(--bg-light);
  border-radius: var(--radius-common);
  overflow: hidden;

  user-select: none;

  @include mediaBigDesktop {
    height: big(306);
  }

  @include mediaMobile {
    height: rem(250);
  }

  & .ymaps-2-1-79-controls__toolbar,
  & .ymaps-2-1-79-controls-pane,
  & .ymaps-2-1-79-map-copyrights-promo {
  // & .ymaps-2-1-79-copyrights-pane {
    display: none;
  }
}
