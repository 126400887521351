.radio {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: rem(10);

  cursor: pointer;
  user-select: none;

  @include mediaBigDesktop {
    gap: big(10);
  }

  @include hover {
    & .radio {
      &__item {
        border-color: var(--accent-light-primary);
      }
    }
  }

  &::before {
    @include pseudo(8) {}
  }

  &__input {
    &:checked {
      &~.radio {
        &__item {
          background-color: var(--accent-light-primary);
          border-color: var(--accent-light-primary);
        }
      }
    }
  }

  &__item {
    position: relative;
    width: rem(30);
    height: rem(30);
    display: block;

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark-secondary);
    border-radius: rem(160);

    transition: border-color var(--animation-timing) var(--cubic-bezier),
      background-color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(30);
      height: big(30);

      border-width: big(1);
      border-radius: big(160);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;

      width: rem(10);
      height: rem(10);

      background-color: var(--bg-white);
      border-radius: rem(160);

      @include mediaBigDesktop {
        width: big(10);
        height: big(10);
      }
    }
  }

  &.medium {
    @include mediumRadio {}
  }

  &.small {
    @include smallRadio {}
  }
}
