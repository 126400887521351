@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Regular/Jost-Regular.woff2') format('woff2'),
    url('../fonts/Jost/Regular/Jost-Regular.woff') format('woff'),
    url('../fonts/Jost/Regular/Jost-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Medium/Jost-Medium.woff2') format('woff2'),
    url('../fonts/Jost/Medium/Jost-Medium.woff') format('woff'),
    url('../fonts/Jost/Medium/Jost-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/SemiBold/Jost-SemiBold.woff2') format('woff2'),
    url('../fonts/Jost/SemiBold/Jost-SemiBold.woff') format('woff'),
    url('../fonts/Jost/SemiBold/Jost-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Bold/Jost-Bold.woff2') format('woff2'),
    url('../fonts/Jost/Bold/Jost-Bold.woff') format('woff'),
    url('../fonts/Jost/Bold/Jost-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
